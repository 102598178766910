import { sharitMediaApiV1 } from '..';

type TGetClientsRequest = {
  account_id: string;
};

type TGetClientsResponse = {
  all_limit: string;
  day_limit: string;
  id: number;
  name: string;
};

export const clientsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getClients: build.query<TGetClientsResponse[], TGetClientsRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getClients`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetClientsQuery, useLazyGetClientsQuery } = clientsApi;
