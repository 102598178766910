import { Col, Divider, Drawer, Row } from 'antd';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRole } from '../../shared/helpers';
import { MyInfo } from '../../store/slice';
import { uiDrawerSlice } from '../../store/slice/uiSlice';
import './index.scss';

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="user-profile__info">
    <p className="user-profile__title">{title}:</p>
    {content}
  </div>
);

type ProfileProps = {
  showState: boolean;
  setShowState: React.Dispatch<SetStateAction<boolean>>;
};

export const UserProfile = () => {
  const dispatch = useDispatch();

  const { visibility } = useSelector(uiDrawerSlice.selectors.userDrawerSelector);
  const info = useSelector(MyInfo.selectors.getMyInfo);

  const [showState, setShowState] = useState<boolean>(visibility);

  const onClose = () => {
    dispatch(uiDrawerSlice.actions.visibility(false));

    setShowState(false);
  };

  useEffect(() => {
    setShowState(visibility);
  }, [visibility, setShowState]);

  return (
    <div className="">
      <Drawer width={500} placement="right" onClose={onClose} open={showState}>
        <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
          Профиль пользователя
        </p>
        {/* <p className="site-description-item-profile-p">Personal</p> */}
        <Row>
          <Col span={12}>
            <DescriptionItem title="Фамилия" content={info.surname} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Имя" content={info.name} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Отчество" content={info.middlename} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Email" content={info.email} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Роль" content={getRole(info.role)} />
          </Col>
        </Row>
        <Divider />
        {/* <p className="site-description-item-profile-p">Социальные сети:</p> */}
      </Drawer>
    </div>
  );
};
