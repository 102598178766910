import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { ConfigProvider, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserHeader } from '../../../../features';
import { Logout } from '../../../../features/Logout';
import { useLazyMyInfoQuery } from '../../../../store/query/sharitMediaApiV1/myInfo';
import { Paths } from '../../../lib/constants/paths';
import MenuNew from '../../Menu';
import { credentialsSlice } from '../../../../store/slice/credentialsSlice';
import usePath from '../../../hooks/usePath';
import './index.scss';

const { Header, Sider, Content } = Layout;

export const MainLayout = () => {
  const navigate = useNavigate();
  const path = usePath(1);
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const userId = localStorage.getItem('id');
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  const [getMyInfoTrigger, myData] = useLazyMyInfoQuery();

  useEffect(() => {
    if (!path) {
      navigate(Paths.campaigns);
    }
  }, [path, navigate]);

  useEffect(() => {
    if (userId) {
      getMyInfoTrigger(userId);
    }
  }, [userId, getMyInfoTrigger]);

  useEffect(() => {
    if (accessToken && refreshToken && myData?.data) {
      dispatch(credentialsSlice.actions.setCredentials({ accessToken, refreshToken, user: myData.data }));
      dispatch(credentialsSlice.actions.setIsAuth(true));
    }
  }, [accessToken, refreshToken, myData, dispatch]);

  useEffect(() => {
    if (accessToken) {
      const role = localStorage.getItem('role');
      const id = localStorage.getItem('id');

      if (role && Number(role) === 4 && id) {
        navigate(`/user-service/${id}/profile`);
      }
    } else {
      navigate(Paths.auth);
    }
  }, [navigate, accessToken]);

  return (
    <>
      {accessToken && (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'rgba(238, 146, 110, 1)',
              fontFamily: 'Montserrat, sans-serif',
              colorBgLayout: '#2c2929',
              borderRadiusLG: 4,
              borderRadiusOuter: 4,
              borderRadiusSM: 4,
              borderRadiusXS: 4,
              colorBgContainerDisabled: '#fff',
              colorTextBase: '#141414',
              colorText: '#141414',
              controlItemBgActive: 'rgba(238, 146, 110, 1)',
            },
          }}
        >
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
              <div className="logo" />
              <MenuNew />
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background header-wrap">
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed),
                })}
                <div className="header__right">
                  <UserHeader />
                  <Logout />
                </div>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  margin: '24px 16px',
                  padding: 24,
                  minHeight: 280,
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      )}
    </>
  );
};
