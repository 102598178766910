import { SettingOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';
import { Chars } from '../../../../shared/lib/types';
import { ReportChar } from '../ReportChar';
import { useSelector } from 'react-redux';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import { ForwardsIcon } from '../../../../shared/ui/icons/ForwardsIcon';
import './index.scss';
import { BudgetIcon } from '../../../../shared/ui/icons';
import { AuditionsIcon } from '../../../../shared/ui/icons/AuditionsIcon';

const options = [
  { label: 'Потраченный бюджет', value: 'budgetPlaylist' },
  { label: 'Общие прослушивания', value: 'auditions' },
];

export const ReportCharPlaylists = () => {
  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);
  const { budgetPlaylist, auditions } = allDataReports;

  const [charState, setCharState] = useState<Chars[]>([]);
  const [checksState, setChecksState] = useState<(string | number | boolean)[]>([]);
  const [settingsIconVisible, setSettingsIconVisible] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const chars: Chars[] = [
    {
      id: 'budgetPlaylist',
      title: 'Потраченный бюджет',
      data: budgetPlaylist,
      icon: <BudgetIcon />,
      visible: true,
    },
    {
      id: 'auditions',
      title: 'Общие прослушивания',
      data: auditions,
      icon: <AuditionsIcon />,
      visible: true,
    },
  ];

  useEffect(() => {
    // console.log('checksState', checksState);
  }, [checksState]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    // console.log('checked = ', checkedValues);
    setChecksState(checkedValues);

    const charCheck: (Chars | undefined)[] = checkedValues?.map(elem => chars?.find(el => elem === el?.id));

    if (isChars(charCheck)) {
      setCharState(charCheck);
    }
  };

  const isChars = (arr: (Chars | undefined)[]): arr is Chars[] => {
    return (arr as Chars[])[0].id !== undefined;
  };

  return (
    <div
      className="char-playlist__wrap"
      onMouseOver={() => setSettingsIconVisible(true)}
      onMouseOut={() => setSettingsIconVisible(false)}
    >
      {charState?.length > 0 && charState?.map(el => {
        return <ReportChar title={el?.title} data={el?.data} key={el?.id} icon={el?.icon} />;
      })}

      {settingsIconVisible && <SettingOutlined className="char__settings" onClick={() => setSettingsVisible(true)} />}

      {settingsVisible && (
        <div className="char__check">
          <div className="char__close" onClick={() => setSettingsVisible(false)}>
            &times;
          </div>
          <Checkbox.Group options={options} value={checksState} onChange={onChange} className="check__group" />
        </div>
      )}
    </div>
  );
};
