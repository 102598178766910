import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';

export type TInitialCurrentAds = {
  currentAccount: number | null;
  currentClient: number | null;
  currentCampany: number | null;
  currentAds: number | null;
};

const initialState: TInitialCurrentAds = {
  currentAccount: null,
  currentClient: null,
  currentCampany: null,
  currentAds: null,
};

export const currentAdsSlice = createSlice({
  name: 'ui/currentAds',
  initialState,
  reducers: {
    currentAccount(state, { payload }: PayloadAction<number>) {
      return { ...state, currentAccount: payload };
    },
    currentClient(state, { payload }: PayloadAction<number>) {
      return { ...state, currentClient: payload };
    },
    currentCampany(state, { payload }: PayloadAction<number>) {
      return { ...state, currentCampany: payload };
    },
    currentAds(state, { payload }: PayloadAction<number>) {
      return { ...state, currentAds: payload };
    },
    reset: () => initialState,
  },
});

const currentAds = (state: RootState) => state.ui.currentAds;
const currentAdsSelector = createSelector(currentAds, state => state);
const accountId = createSelector(currentAds, state => state.currentAccount);
const clientId = createSelector(currentAds, state => state.currentClient);
const campanyId = createSelector(currentAds, state => state.currentCampany);
const adsId = createSelector(currentAds, state => state.currentAds);

export const selectors = {
  currentAdsSelector, accountId, clientId, campanyId, adsId
};
export const actions = currentAdsSlice.actions;
export const reducer = currentAdsSlice.reducer;
