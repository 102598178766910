import { sharitMediaApiV1 } from '..';

type TGetCampaignInfoRequest = {
  account_id: number;
  client_id: number;
  campaign_ids: string;
};

type TGetCampaignInfoResponse = {
  id: number;
  campaign_id: number;
  status: number;
  approved: number;
  create_time: number;
  update_time: number;
  goal_type: number;
  cost_type: number;
  day_limit: string;
  all_limit: string;
  start_time: number;
  stop_time: number;
  category1_id: number;
  category2_id: number;
  age_restriction: number;
  name: string;
}[];

export const campaignInfoApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getCampaignInfo: build.query<TGetCampaignInfoResponse, TGetCampaignInfoRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getAds`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCampaignInfoQuery, useLazyGetCampaignInfoQuery } = campaignInfoApi;
