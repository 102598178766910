import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { CSSProperties } from 'react';

type TInitialValueCurrentElement = {
  id: string;
  options: CSSProperties;
}

const initialState: TInitialValueCurrentElement = {
  id: '',
  options: {}
}

export const currentElementSlice = createSlice({
  name: 'constructor/currentElementSlice',
  initialState,
  reducers: {
    id(state, { payload }: PayloadAction<string>) {
      return { ...state, id: payload };
    },
    options(state, { payload }: PayloadAction<{options: CSSProperties}>) {
      return { ...state, options: {...state.options, ...payload.options}}
    },
    reset: () => initialState,
  },
});

const currentElement = (state: RootState) => state.constructorReport.currentElement;
const currentElementSelector = createSelector(currentElement, state => state);

export const selectors = {
  currentElementSelector,
};
export const actions = currentElementSlice.actions;
export const reducer = currentElementSlice.reducer;
