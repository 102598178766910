export const Paths = {
  main: '/',
  auth: '/auth',
  registration: '/registration',
  reports: '/reports',
  reportCreate: '/reports/:id',
  reportAds: '/reports/:id/ads/:idAds',
  aboutCompany: '/aboutCompany',
  campaigns: '/campaigns',
  campaignsInfo: '/campaigns/:id',
  campaignCode: '/campaigns/:code',
  users: '/users',
  user: '/users/:id',
  // userService: '/user-service/:id',
  // userProfile: '/user-service/:id/profile',
  // userCatalog: '/user-service/:id/catalog',
  myServices: '/user-service/:id/my-services',
  chats: '/chats',
  contracts: '/contracts',
  contract: '/contract/:id',
  services: '/services',
  constrcutor: '/constructor',
  releases: '/releases',
  release: '/releases/:id',
  notFound: '*',
};
