import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TOptions, TRow } from '../../../../features/Constructor/Types';

type TInitialValuesRows = {
  id: string;
  idSection: string;
  order: number;
  type: string;
  options: TOptions;
}[];

const initialState: TInitialValuesRows = [{
  id: 'r-1',
  idSection: 's-1',
  order: 1,
  type: 'rows',
  options: {
    displayFlex: 'flex',
    flexDirection: 'column',
  },
}];

export const rowSlice = createSlice({
  name: 'constructor/rowSlice',
  initialState,
  reducers: {
    sections(state, { payload }: PayloadAction<TRow[]>) {
      return { ...state, sections: payload };
    },
    reset: () => initialState,
  },
});

const rows = (state: RootState) => state.constructorReport.rows;
const rowsSelector = createSelector(rows, state => state);

export const selectors = {
  rowsSelector,
};
export const actions = rowSlice.actions;
export const reducer = rowSlice.reducer;
