import { Button, Form, Input, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { getErrorData } from '../../shared/helpers';
import { useNotifications } from '../../shared/hooks/use-notifications';
import { TRegistrationPayload, useRegistrationMutation } from '../../store/query/sharitMediaApiV1/registration';
import './index.scss';

const { Option } = Select;

export const Registration = () => {
  const [form] = useForm();
  const [selectRole, setSelectRole] = useState<string>('4');
  const [registrationTrigger, result] = useRegistrationMutation({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });

  const { isSuccess, isError, error, reset } = result;

  const onSelectRole = (value: string) => {
    console.log(value);
    setSelectRole(value);
  };

  const onFinish = (values: TRegistrationPayload) => {
    const { password, passwordConfirm } = values;

    if (password === passwordConfirm) {
      registrationTrigger(values);
    } else {
      notification.error({ message: 'Пароли не совпадают!' });
    }
  };

  useNotifications(isSuccess, isError, 'Вы успешно зарегистрировались! Подтвердите свой email', error?.message, reset);

  return (
    <div className="registration">
      <div className="registration__box">
        <Form form={form} name="registration" layout="vertical" onFinish={onFinish} className="form-registration">
          <h2 className="registration__title">Регистрация</h2>
          <Form.Item name="surname" rules={[{ required: true, message: 'Введите Фамилию' }]}>
            <Input placeholder="Введите Фамилию" className="input-dark" />
          </Form.Item>

          <Form.Item name="name" rules={[{ required: true, message: 'Введите имя' }]}>
            <Input placeholder="Введите имя" className="input-dark" />
          </Form.Item>

          <Form.Item name="middlename" rules={[{ required: true, message: 'Введите отчество' }]}>
            <Input placeholder="Введите отчество" className="input-dark" />
          </Form.Item>

          <Form.Item name="email" rules={[{ required: true, message: 'Введите email' }]}>
            <Input type="email" placeholder="Введите email" className="input-dark" />
          </Form.Item>

          <Form.Item name="role" rules={[{ required: true, message: 'Выберите роль' }]}>
            <Select placeholder="Выберите роль" onSelect={onSelectRole}>
              <Option value="2">Администратор</Option>
              <Option value="3">Менеджер</Option>
              <Option value="4">Артист</Option>
            </Select>
          </Form.Item>

          {selectRole !== '4' && (
            <Form.Item name="linkVk" rules={[{ required: true, message: 'Вставьте ссылку на ваш профиль Вконтакте' }]}>
              <Input placeholder="Вставьте ссылку на страницу VK" maxLength={200} className="input-dark" />
            </Form.Item>
          )}

          <Form.Item name="password" rules={[{ required: true, message: 'Введите пароль (минимум 4 символа)' }]}>
            <Input.Password placeholder="Введите пароль" minLength={4} maxLength={30} className="input-dark" />
          </Form.Item>

          <Form.Item name="passwordConfirm" rules={[{ required: true, message: 'Введите пароль повторно' }]}>
            <Input.Password placeholder="Введите пароль повторно" minLength={4} maxLength={30} className="input-dark" />
          </Form.Item>

          <span className="form-registration__confirm">
            Регистрируясь, даю согласие на обработку персональных данных
          </span>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Зарегистрироваться
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
