import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

type TInitialValuesReports = {
  sowingScreen: string;
  budgetSowing: number;
  coverage: number;
  likesSowing: number;
  forwardsSowing: number;
  conclusions: string;
};

const initialState: TInitialValuesReports = {
  sowingScreen: '/image/notImage.jpg',
  budgetSowing: 0,
  coverage: 0,
  likesSowing: 0,
  forwardsSowing: 0,
  conclusions: '',
};

export const pageThreeSlice = createSlice({
  name: 'ui/reports/pageThree',
  initialState,
  reducers: {
    sowingScreen(state, { payload }: PayloadAction<string>) {
      return { ...state, sowingScreen: payload };
    },
    budgetSowing(state, { payload }: PayloadAction<number>) {
      return { ...state, budgetSowing: payload };
    },
    coverage(state, { payload }: PayloadAction<number>) {
      return { ...state, coverage: payload };
    },
    likesSowing(state, { payload }: PayloadAction<number>) {
      return { ...state, likesSowing: payload };
    },
    forwardsSowing(state, { payload }: PayloadAction<number>) {
      return { ...state, forwardsSowing: payload };
    },
    conclusions(state, { payload }: PayloadAction<string>) {
      return { ...state, conclusions: payload };
    },
    pageThree(state, { payload }: PayloadAction<TInitialValuesReports>) {
      return {
        ...state,
        sowingScreen: payload.sowingScreen,
        budgetSowing: payload.budgetSowing,
        coverage: payload.coverage,
        likesSowing: payload.likesSowing,
        forwardsSowing: payload.forwardsSowing,
        conclusions: payload.conclusions,
      };
    },
    reset: () => initialState,
  },
});

const pageThreeData = (state: RootState) => state.ui.reports.pageThree;
const pageThreeSelector = createSelector(pageThreeData, state => state);

export const selectors = {
  pageThreeSelector,
};
export const actions = pageThreeSlice.actions;
export const reducer = pageThreeSlice.reducer;
