import { useEffect } from 'react';
import { Section } from './Section';
import { TConstructorReport, TPage } from './Types';
import { Panel } from './Panel';
import { useSelector } from 'react-redux';
import { pagesSlice } from '../../store/slice/constructorSlice';
import './index.scss';

const mainObject: TConstructorReport = {
  id: '',
  type: 'report',
  order: 1,
  template: {
    id: 'temp-1',
    name: 'vkontakte-1',
    type: 'template',
    description: 'Описание шаблона 1',
    options: {},
  },
  pages: [
    {
      id: 'p-1',
      name: 'page-1',
      idTemplate: 'temp-1',
      type: 'page',
      order: 1,
      options: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    },
  ],

  sections: [
    {
      id: 's-1',
      idPage: 'p-1',
      type: 'section',
      order: 1,
      options: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: 100,
        height: 160,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 20,
        marginLeft: 0,
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
      },
    },
    {
      id: 's-2',
      idPage: 'p-2',
      type: 'section',
      order: 2,
      options: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: 100,
        height: 160,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 20,
        marginLeft: 0,
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
      },
    },
  ],

  rows: [
    {
      id: 'r-1',
      idSection: 's-1',
      type: 'row',
      order: 1,
      options: {
        displayFlex: 'flex',
        flexDirection: 'column',
      },
    },
  ],

  columns: [
    {
      id: 'c-1',
      idRow: 'r-1',
      type: 'column',
      order: 1,
      options: {
        width: 500,
      },
    },
    {
      id: 'c-2',
      idRow: 'r-1',
      type: 'column',
      order: 2,
      options: {},
    },
  ],

  widgets: [
    {
      id: 'w-h1-1',
      idColumn: 'c-1',
      name: 'h1',
      type: 'widget',
      order: 1,
      options: {
        width: 700,
        height: 80,
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 700,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
      },
    },
    {
      id: 'w-images-1',
      idColumn: 'c-1',
      name: 'imageUpload',
      type: 'widget',
      order: 2,
      options: {
        width: 100,
        height: 80,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        image: '/images',
      },
    },
  ],
};

const renderPages = (pages: TPage[]) => {
  if (pages) {
    return pages?.map(el => {
      return (
        <div className="c-page" key={el.id} id={el.id} style={{ ...el.options }}>
          {el?.id && <Section />}
        </div>
      );
    });
  } else {
    return <div className="a4">a4</div>;
  }
};

export const Constructor = () => {
  const pages = useSelector(pagesSlice.selectors.pagesSelector);

  useEffect(() => {
    console.log('constructor', mainObject);
    console.log(renderPages(pages));
  }, []);

  return (
    <div className="constructor">
      <div className="c-panel">
        <Panel />
      </div>
      <div className="c-body">
        {pages ? renderPages(pages) : <div>Pages</div>}
        {/* <div className="c-settings"></div> */}
      </div>
    </div>
  );
};
