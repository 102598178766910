import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Radio, RadioChangeEvent, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReportsStatisticsDate from '../../../features/Reports/ReportCreate/ReportsStatisticsDate';
import { modalTemplatesSlice } from '../../../store/slice/uiSlice';
import { pageConstructorSlice, reportAdsSlice } from '../../../store/slice/uiSlice/reports';
import { Template, TemplatesName } from '../../../store/slice/uiSlice/reports/pageConstructor';
import { FormAdsSelect } from '../../FormAdsSelect';
import { ESocial, socials } from '../../../shared/lib/constants/socials';
import './index.scss';

export const ModalTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { campaign, ads } = useSelector(reportAdsSlice.selectors.reportAdsSelector);
  const { view: viewModalState } = useSelector(modalTemplatesSlice.selectors.modalTemplatesSelector);
  const { templates, currentSocial } = useSelector(pageConstructorSlice.selectors.pageConstructorSelector);

  const [campaingId, setCampaignId] = useState(campaign);
  const [adsId, setAdsId] = useState(ads);

  const [valueRadioId, setValueRadioId] = useState<string>('');
  const [tpls, setTpls] = useState<Template[]>(templates);

  const [viewModal, setViewModal] = useState<boolean>(viewModalState);

  const handleOk = () => {
    dispatch(modalTemplatesSlice.actions.view(false));
    dispatch(pageConstructorSlice.actions.templates([...tpls]));

    if (campaingId && ads) {
      navigate(`/reports/${campaingId}/ads/${adsId}`);
    }
  };

  const handleCancel = () => {
    dispatch(modalTemplatesSlice.actions.view(false));
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadioId(e.target.value);
  };

  const selectTpl = (tpl: TemplatesName) => {
    const objTpl = tpls?.find(el => el.id === valueRadioId);

    if (objTpl) {
      setTpls(prev => {
        const currentObjIndex = prev?.findIndex(el => el.id === valueRadioId);
        if (currentObjIndex !== -1) {
          const updatedObj = {
            ...prev[currentObjIndex],
            name: tpl,
          };
          const newTpls = [...prev];
          newTpls[currentObjIndex] = updatedObj;
          return newTpls;
        }
        return prev;
      });
    }
  };

  const onDeleteRadio = () => {
    const objTpl = tpls?.find(el => el.id === valueRadioId);

    if (objTpl) {
      setTpls(prev => {
        const currentObjIndex = prev?.findIndex(el => el.id === valueRadioId);
        if (currentObjIndex !== -1) {
          const newTpls = prev.filter((el, i) => i !== currentObjIndex);
          return newTpls;
        }
        return prev;
      });
    }
  };

  const addTemplate = () => {
    const newObj = { id: String(Math.random()).slice(-7), name: TemplatesName.vk1, order: tpls.length + 1 };
    dispatch(pageConstructorSlice.actions.templates([...tpls, newObj]));
  };

  const onSelectTemplate = (value: ESocial) => {
    const social = socials.find(el => el.value === value);

    if (social) {
      dispatch(pageConstructorSlice.actions.currentSocial(social));
    }
  };

  useEffect(() => {
    setTpls(templates);
  }, [templates]);

  useEffect(() => {
    setCampaignId(campaign);
  }, [campaign]);

  useEffect(() => {
    setAdsId(ads);
  }, [ads]);

  useEffect(() => {
    setViewModal(viewModalState);
  }, [viewModalState]);

  return (
    <Modal
      title="Настройки шаблона"
      open={viewModal}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Отмена"
      okText="Перейти к редактированию"
      width={1180}
    >
      <div className="tpl-creator">
        <div className="tpl-creator__sider">
          <Form>
            <Form.Item name="template">
              <Select onSelect={onSelectTemplate} placeholder="Выберите шаблон">
                {socials &&
                  socials?.map(el => {
                    return (
                      <Select.Option key={el?.id} value={el?.value}>
                        {el?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>

          <Radio.Group onChange={onChangeRadio} value={valueRadioId} className="tpl-creator-radio">
            <Space direction="vertical">
              {tpls?.map((el, i) => {
                return (
                  <Radio key={`${el?.id}`} value={el?.id}>
                    {i + 1} стр. - {el?.name} <CloseOutlined onClick={onDeleteRadio} rev={undefined} />
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
          <Button type="primary" className="tpl-creator-btn" onClick={() => addTemplate()}>
            Добавить страницу
          </Button>

          <FormAdsSelect />

          <ReportsStatisticsDate />
        </div>

        <ul className="tpl__wrap">
          {currentSocial && currentSocial.name === ESocial.vk && (
            <>
              <li className="tpl__li" id="vk1" key="vk1" onClick={() => selectTpl(TemplatesName.vk1)}>
                <div className="triangle"></div>
                <div className="maket-title-line"></div>
                <div className="maket-title-line maket-title-line_small"></div>
                <div className="maket-chars-wrap">
                  <div className="maket-img"></div>
                  <div className="maket-chars">
                    <div className="maket-subtitle-line"></div>
                    <ul className="maket-chars-list">
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                    </ul>
                  </div>
                </div>

                <div className="maket-title-line"></div>
                <div className="maket-chars-wrap">
                  <div className="maket-chars">
                    <ul className="maket-chars-list maket-chars-list_small">
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                    </ul>
                  </div>
                  <div className="maket-img"></div>
                </div>
                <div className="maket-img-two">
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                </div>
              </li>
              <li className="tpl__li" id="vk2" key="vk2" onClick={() => selectTpl(TemplatesName.vk2)}>
                <div className="triangle"></div>
                <div className="maket-vertical">
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                </div>
              </li>
              <li className="tpl__li" id="vk3" key="vk3" onClick={() => selectTpl(TemplatesName.vk3)}>
                <div className="triangle"></div>
                <div className="maket-vertical">
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                  <ul className="maket-chars-list maket-chars-list_xs">
                    <li className="maket-char"></li>
                    <li className="maket-char"></li>
                    <li className="maket-char"></li>
                    <li className="maket-char"></li>
                  </ul>
                </div>
              </li>
              <li className="tpl__li" id="vk4" key="vk4" onClick={() => selectTpl(TemplatesName.vk4)}>
                <div className="triangle"></div>
                <div className="maket-vertical">
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                  <ul className="maket-chars-list maket-chars-list_xs">
                    <li className="maket-char"></li>
                    <li className="maket-char"></li>
                  </ul>
                  <div className="maket-conclutions"></div>
                </div>
              </li>
            </>
          )}

          {currentSocial && currentSocial.name === ESocial.tikTok && (
            <>
              <li className="tpl__li" id="tikTok" key="tikTok" onClick={() => selectTpl(TemplatesName.tikTok)}>
                <div className="triangle"></div>
                <div className="maket-title-line"></div>
                <div className="maket-title-line maket-title-line_small"></div>
                <div className="maket-chars-wrap">
                  <div className="maket-img"></div>
                  <div className="maket-chars">
                    <div className="maket-subtitle-line"></div>
                    <ul className="maket-chars-list">
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                    </ul>
                  </div>
                </div>

                <div className="maket-img-three">
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                </div>

                <div className="maket-img-box">
                  <div className="maket-title-line"></div>
                  <div className="maket-img_large"></div>
                </div>
              </li>
            </>
          )}

          {currentSocial && currentSocial.name === ESocial.youTube && (
            <>
              <li className="tpl__li" id="youTube" key="youTube" onClick={() => selectTpl(TemplatesName.youTube)}>
                <div className="triangle"></div>
                <div className="maket-title-line"></div>
                <div className="maket-title-line maket-title-line_small"></div>
                <div className="maket-chars-wrap">
                  <div className="maket-img"></div>
                  <div className="maket-chars">
                    <div className="maket-subtitle-line"></div>
                    <ul className="maket-chars-list">
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                    </ul>
                  </div>
                </div>

                <div className="maket-img-three">
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                </div>

                <div className="maket-img-box">
                  <div className="maket-title-line"></div>
                  <div className="maket-img_large"></div>
                </div>
              </li>
            </>
          )}

          {currentSocial && currentSocial.name === ESocial.yandex && (
            <>
              <li className="tpl__li" id="yandex" key="yandex" onClick={() => selectTpl(TemplatesName.yandex1)}>
                <div className="triangle"></div>
                <div className="maket-title-line"></div>
                <div className="maket-title-line maket-title-line_small"></div>
                <div className="maket-chars-wrap">
                  <div className="maket-img"></div>
                  <div className="maket-chars">
                    <div className="maket-subtitle-line"></div>
                    <ul className="maket-chars-list">
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                      <li className="maket-char"></li>
                    </ul>
                  </div>
                </div>

                <div className="maket-title-line"></div>

                <div className="maket-img-three">
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                  <div className="maket-img"></div>
                </div>
              </li>

              <li className="tpl__li" id="yandex2" key="yandex2" onClick={() => selectTpl(TemplatesName.yandex2)}>
                <div className="triangle"></div>
                <div className="maket-vertical">
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                  <div className="maket-img-box">
                    <div className="maket-title-line"></div>
                    <div className="maket-img_large"></div>
                  </div>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </Modal>
  );
};
