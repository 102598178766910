import { lazy } from 'react';
import { ReportCreate } from '../../../features/Reports/ReportCreate';

export const ReportCreatePage = () => <ReportCreate />;

// export const ReportCreatePage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../../features/Reports/ReportCreate'));
//     }),
// );
