import { Input } from 'antd';
import { useState } from 'react';
import { UploadImgWrap } from '../../Reports/UploadImgWrap';
import { ReportCharWrap } from '../../Reports/ReportCreate/ReportCharWrap';

const YandexTwo = () => {
  const [screenshotOneTitle, setScreenshotOneTitle] = useState<string>('Скриншот из рекламного кабинета');
  const [screenshotTwoTitle, setScreenshotTwoTitle] = useState<string>('Скриншот динамики стриминга');

  const [imageOne, setImageOne] = useState<string>('/image/notImage.jpg');
  const [imageRwo, setImageTwo] = useState<string>('/image/notImage.jpg');

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  return (
    <div className="a4">
      <img src="/image/line.png" alt="line" className="a4__figure" />
      <div className="header">
        <p className="header__company">Sharit media</p>
        {/* <Logo /> */}
      </div>

      <section className="advertising">
        <Input
          className="screenshots__title"
          value={screenshotOneTitle}
          onInput={e => setScreenshotOneTitle(e.currentTarget.value)}
        />

        <div className="screenshots__wrap">
          <UploadImgWrap fileImageName="cabinetScreen" file={imageOne} setFile={setImageOne} />
        </div>

        <Input
          className="screenshots__title"
          value={screenshotTwoTitle}
          onInput={e => setScreenshotTwoTitle(e.currentTarget.value)}
        />

        <div className="screenshots__wrap">
          <UploadImgWrap fileImageName="cabinetScreen" file={imageRwo} setFile={setImageTwo} />
        </div>
      </section>
    </div>
  );
};

export default YandexTwo;
