import { Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorData } from '../../../shared/helpers';
import { clientCookies } from '../../../shared/lib/cookies';
import { TAccount, TCampaignInfo, TCampaigns } from '../../../shared/lib/types';
import { useLazyGetAccountsQuery } from '../../../store/query/sharitMediaApiV1/accounts';
import { useLazyGetCampaignInfoQuery } from '../../../store/query/sharitMediaApiV1/campaignInfo';
import { useLazyGetCampaignsQuery } from '../../../store/query/sharitMediaApiV1/campaigns';
import { useLazyGetClientsQuery } from '../../../store/query/sharitMediaApiV1/clients';
import { AccountsSlice, CredentialsSlice } from '../../../store/slice';
import { credentialsActions } from '../../../store/slice/credentialsSlice';
import { currentAdsSlice } from '../../../store/slice/uiSlice';
import { reportAdsSlice } from '../../../store/slice/uiSlice/reports';
import './index.scss';

const { Option } = Select;

export const FormAdsSelect = () => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const accessTokenVk = useSelector(CredentialsSlice.selectors.getMyAccessToken);
  const { campaign, ads: adsId } = useSelector(reportAdsSlice.selectors.reportAdsSelector);
  const userVkId = useSelector(CredentialsSlice.selectors.getMyVkId);
  const myAllAccounts = useSelector(AccountsSlice.selectors.getAccounts);
  const campanyId = useSelector(currentAdsSlice.selectors.campanyId);
  const currentAdsId = useSelector(currentAdsSlice.selectors.adsId);
  const currentAccountId = useSelector(currentAdsSlice.selectors.accountId);
  const currentClientId = useSelector(currentAdsSlice.selectors.clientId);

  const [campaigns, setCampaigns] = useState<TCampaigns[]>();
  const [selectCampaingId, setSelectCampaignId] = useState<number>(campaign);
  const [selectAdsId, setSelectAdsId] = useState<number>(adsId);
  const [ads, setAds] = useState<TCampaignInfo[]>();
  const [accountId, setAccountId] = useState<number | null>(currentAccountId);
  const [clientId, setClientId] = useState<number | null>(currentClientId);
  const [currentAccounts, setCurretAccounts] = useState<TAccount[]>([]);

  const [getCompanyInfoTrigger, campaignIfo] = useLazyGetCampaignInfoQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: adsAllData } = campaignIfo;

  const [getCompanyTrigger, campaignsAll] = useLazyGetCampaignsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: campaignsData } = campaignsAll;

  const [getAccountsTrigger] = useLazyGetAccountsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });

  const [getClientsTrigger, allClients] = useLazyGetClientsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: dataClients } = allClients;

  const onSelectAccount = (value: string) => {
    setAccountId(Number(value));
    dispatch(currentAdsSlice.actions.currentAccount(Number(value)));
    setClientId(null);
  };

  const onSelectClient = (value: string) => {
    setClientId(Number(value));
    dispatch(currentAdsSlice.actions.currentClient(Number(value)));
  };

  const onSelectCampaigns = (id: number) => {
    setSelectCampaignId(id);
    dispatch(currentAdsSlice.actions.currentCampany(id));
    dispatch(reportAdsSlice.actions.ads(selectAdsId));
  };

  const onSelectAds = (id: number) => {
    setSelectAdsId(id);
    dispatch(currentAdsSlice.actions.currentAds(id));
  };

  useEffect(() => {
    const accessTokenVk = clientCookies.getToken('accessTokenVk');
    const userVkId = clientCookies.getUserVkId();

    if (accessTokenVk && userVkId) {
      dispatch(credentialsActions.setUserVk({ access_token: accessTokenVk, user_id: userVkId }));
    }
  }, [accessTokenVk, dispatch]);

  useEffect(() => {
    if (accountId && clientId && selectCampaingId) {
      getCompanyInfoTrigger({
        account_id: Number(accountId),
        client_id: Number(clientId),
        campaign_ids: JSON.stringify([selectCampaingId]),
      });
    }
  }, [accountId, clientId, selectCampaingId, getCompanyInfoTrigger]);

  useEffect(() => {
    if (accountId !== 0 && accessTokenVk && clientId) {
      getCompanyTrigger({
        account_id: String(accountId),
        client_id: String(clientId),
        include_deleted: '1',
        access_token: accessTokenVk,
      });
    }
  }, [accountId, accessTokenVk, clientId, getCompanyTrigger]);

  useEffect(() => {
    if (userVkId && accessTokenVk) {
      getAccountsTrigger({
        account_id: String(userVkId),
        access_token: accessTokenVk,
      });
    }
  }, [accessTokenVk, userVkId, getAccountsTrigger]);

  useEffect(() => {
    if (myAllAccounts && myAllAccounts?.length > 0) {
      const currentAccounts = myAllAccounts.filter(el => el.account_type === 'agency');
      setCurretAccounts(currentAccounts);
    }
  }, [myAllAccounts]);

  useEffect(() => {
    if (accountId) {
      getClientsTrigger({
        account_id: String(accountId),
      });
    }
  }, [accountId, getClientsTrigger]);

  useEffect(() => {
    dispatch(reportAdsSlice.actions.ads(selectAdsId));
  }, [dispatch, selectAdsId]);

  useEffect(() => {
    dispatch(reportAdsSlice.actions.campaign(selectCampaingId));
  }, [dispatch, selectCampaingId]);

  useEffect(() => {
    setCampaigns(campaignsData);
  }, [campaignsData]);

  useEffect(() => {
    setAds(adsAllData);
  }, [adsAllData]);

  useEffect(() => {
    form.setFieldValue('ads', undefined);
  }, [selectCampaingId, form]);

  useEffect(() => {
    form.setFields([
      {
        name: 'cabinet',
        value: currentAccountId,
      },
      {
        name: 'client',
        value: currentClientId,
      },
      {
        name: 'campaigns',
        value: campanyId,
      },
      {
        name: 'ads',
        value: currentAdsId,
      },
    ]);
  }, [currentAccountId, currentClientId, campanyId, currentAdsId, form]);

  return (
    <>
      <div className="form-ads__title">Выберите кампанию и объявление</div>
      <Form form={form} layout="vertical" className="form-ads">
        <Form.Item name="cabinet">
          <Select onSelect={onSelectAccount} placeholder="Выберите кабинет">
            {currentAccounts &&
              currentAccounts?.map(el => {
                return (
                  <Option key={el?.account_id} value={el?.account_id}>
                    {el?.account_name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item name="client">
          <Select onSelect={onSelectClient} placeholder="Выберите клиента">
            {dataClients &&
              dataClients?.map(el => {
                return (
                  <Option key={el?.id} value={el?.id}>
                    {el?.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item name="campaigns">
          <Select onSelect={onSelectCampaigns} placeholder="Выберите кампанию">
            {campaigns &&
              campaigns?.map(el => {
                return (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item name="ads">
          <Select onSelect={onSelectAds} placeholder="Выберите объявление">
            {ads &&
              ads?.map(el => {
                return (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};
