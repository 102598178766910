import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

export type TInitialValuesUserDrawer = {
  visibility: boolean;
};

const initialState: TInitialValuesUserDrawer = {
  visibility: false,
};

export const userDrawerSlice = createSlice({
  name: 'ui/drawers/userDrawer',
  initialState,
  reducers: {
    visibility(state, { payload }: PayloadAction<boolean>) {
      return { ...state, visibility: payload };
    },
    reset: () => initialState,
  },
});

const userDrawerVisible = (state: RootState) => state.ui.drawers;
const userDrawerSelector = createSelector(userDrawerVisible, state => state);

export const selectors = {
  userDrawerSelector,
};
export const actions = userDrawerSlice.actions;
export const reducer = userDrawerSlice.reducer;
