import { lazy } from 'react';
import { Registration } from '../../features/Registration';

export const RegistrationPage = () => <Registration />;

// export const RegistrationPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Registration'));
//     }),
// );
