import { ReactNode } from 'react';
import {
  UserOutlined,
  FundProjectionScreenOutlined,
  BookOutlined,
  CrownOutlined,
  FileTextOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import usePath from '../../hooks/usePath';
import { getMenuKeys } from '../../helpers';
import { MenuItem, MenuKeyLink, MenuKeys, MenuKeysMap, Paths } from '../../lib/types';
import { useSelector } from 'react-redux';
import { MyInfo } from '../../../store/slice';

type TMenuItems = {
  key: string;
  icon: ReactNode;
  label: string;
  path: MenuKeyLink;
  role?: string[];
}[];

const menuItems: TMenuItems = [
  {
    key: MenuKeys.campaigns,
    icon: <FundProjectionScreenOutlined />,
    label: 'Кампании',
    path: Paths.campaigns,
    role: ['1', '2', '3'],
  },
  {
    key: MenuKeys.reports,
    icon: <BookOutlined />,
    label: 'Отчёты',
    path: Paths.reports,
    role: ['1', '2', '3'],
  },
  {
    key: MenuKeys.users,
    icon: <UserOutlined />,
    label: 'Пользователи',
    path: Paths.users,
    role: ['1', '2', '3'],
  },
  {
    key: MenuKeys.aboutCompany,
    icon: <CrownOutlined />,
    label: 'О компании',
    path: Paths.aboutCompany,
    role: ['1', '2', '3', '4'],
  },
  // {
  //   key: MenuKeys.contracts,
  //   icon: <FileTextOutlined />,
  //   label: 'Документы',
  //   path: Paths.contracts,
  //   role: ['1', '2', '3'],
  // },
  // {
  //   key: MenuKeys.chats,
  //   icon: <MessageOutlined />,
  //   label: 'Чаты',
  //   path: Paths.chats,
  //   role: ['1', '2', '3', '4'],
  // },
  {
    key: MenuKeys.services,
    icon: <ShoppingOutlined />,
    label: 'Услуги',
    path: Paths.services,
    role: ['1', '2', '3', '4'],
  },
  {
    key: MenuKeys.releases,
    icon: <ShoppingOutlined />,
    label: 'Релизы',
    path: Paths.releases,
    role: ['1', '2', '3'],
  },
];

export const menuKeysMap: MenuKeysMap = {
  [Paths.main]: { openKeys: [MenuKeys.main] },
  [Paths.login]: { openKeys: [MenuKeys.login] },
  [Paths.reports]: { openKeys: [MenuKeys.reports] },
  [Paths.aboutCompany]: { openKeys: [MenuKeys.aboutCompany] },
  [Paths.campaigns]: { openKeys: [MenuKeys.campaigns] },
  [Paths.users]: { openKeys: [MenuKeys.users] },
  [Paths.user]: { openKeys: [MenuKeys.user] },
  [Paths.contracts]: { openKeys: [MenuKeys.contracts] },
  [Paths.chats]: { openKeys: [MenuKeys.chats] },
  [Paths.services]: { openKeys: [MenuKeys.services] },
  [Paths.releases]: { openKeys: [MenuKeys.releases] },
};

const MenuNew = () => {
  const mainPath = usePath(1);
  const menuKeys = getMenuKeys(menuKeysMap, mainPath as MenuKeyLink);
  const { openKeys } = menuKeys;
  const { Item } = Menu;

  const myRole = useSelector(MyInfo.selectors.getMyRole);

  const renderMenuItems = (data: MenuItem[]) =>
    data.map(({ icon, key, label, path, role }) => {
      const title = path ? <Link to={path}>{label}</Link> : label;
      if (role?.some(element => String(myRole) === element)) {
        return <Item {...{ key, icon, children: title }} />;
      }
      return null;
    });

  return (
    <Menu theme="dark" mode="inline" openKeys={openKeys} selectedKeys={[`/${mainPath}`]}>
      {renderMenuItems(menuItems)}
    </Menu>
  );
};

export default MenuNew;
