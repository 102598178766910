import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { campaignsApi } from '../../query/sharitMediaApiV1/campaigns';

export type TCampaigns = {
  id?: number;
  type?: string;
  name?: string;
  status?: number;
  day_limit?: string;
  all_limit?: string;
  start_time?: number | string;
  stop_time?: number;
  create_time?: number;
  update_time?: number;
  views_limit?: number;
};

const initialState: TCampaigns[] = [{}];

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        campaignsApi.endpoints.getCampaigns.matchFulfilled,
        (state, { payload }: PayloadAction<TCampaigns[]>) => payload,
      )
      .addMatcher(campaignsApi.endpoints.getCampaigns.matchRejected, () => initialState);
  },
});

const campaigns = (state: RootState) => state.campaigns;
const getCampaigns = createSelector(campaigns, state => state);

export const selectors = { getCampaigns };
export const actions = campaignsSlice.actions;
export const campaignsReducer = campaignsSlice.reducer;
