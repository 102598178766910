import { sharitMediaApiV1 } from '..';

type TGetCampaignsRequest = {
  account_id: string;
  client_id?: string;
  include_deleted?: string;
  access_token?: string | undefined;
};

type TGetCampaignsResponse = {
  id?: number;
  type?: string;
  name?: string;
  status?: number;
  day_limit?: string;
  all_limit?: string;
  start_time?: number | string;
  stop_time?: number;
  create_time?: number;
  update_time?: number;
  views_limit?: number;
}[];

export const campaignsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getCampaigns: build.query<TGetCampaignsResponse, TGetCampaignsRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getCampaigns`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCampaignsQuery, useLazyGetCampaignsQuery } = campaignsApi;
