import { Input } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Logo } from '../../../../shared/ui/icons/logo';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import { ReportCharSowing } from '../../ReportCreate/ReportCharSowing';
import { UploadImgWrap } from '../../UploadImgWrap';
import './index.scss';

const ReportPageTwo = () => {
  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);
  const { sowingScreen } = allDataReports;

  const [reportTitle, setReportTitle] = useState<string>('Посев по концептуальным пабликам ВК');
  const [reportTitle2, setReportTitle2] = useState<string>('Основные показатели посева');

  const [fileImageBase64, setFileImageBase64] = useState<string>(sowingScreen);

  return (
    <>
      <div className="a4">
        <img src="/image/line.png" alt="line" className="a4__figure" />
        <div className="header">
          <p className="header__company">Sharit media</p>
          <Logo />
        </div>

        <section className="public">
          <Input
            className="screenshots__title"
            value={reportTitle}
            onInput={e => setReportTitle(e.currentTarget.value)}
          />

          <div className="screenshots__wrap">
            <UploadImgWrap fileImageName="sowingScreen" file={fileImageBase64} setFile={setFileImageBase64} />
          </div>

          {/* <table className="cabinet__table">
            <thead>
              <tr>
                <th>Ссылка на запись</th>
                <th>Дата публикации</th>
                <th>Название сообщества</th>
                <th>Ссылка на сообщество</th>
                <th>Охват записи</th>
                <th>Количество лайков</th>
                <th>Количество репостов</th>
                <th>Скриншот</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="https://vk.com">https://vk.com</a>
                </td>
                <td>yesterday at 2:45 pm</td>
                <td>Не беси</td>
                <td>
                  <a href="https://vk.com/">https://vk.com/</a>
                </td>
                <td>11532</td>
                <td>97</td>
                <td>11</td>
                <td>
                  <a href="https://vk.com">Изображение</a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://vk.com">https://vk.com</a>
                </td>
                <td>yesterday at 2:45 pm</td>
                <td>Не беси</td>
                <td>
                  <a href="https://vk.com/">https://vk.com/</a>
                </td>
                <td>11532</td>
                <td>97</td>
                <td>11</td>
                <td>
                  <a href="https://vk.com">Изображение</a>
                </td>
              </tr>

              <tr>
                <td className="public__total">Итого</td>
                <td className="public__total"></td>
                <td className="public__total"></td>
                <td className="public__total"></td>
                <td className="public__total">387672</td>
                <td className="public__total">5419</td>
                <td className="public__total">2469</td>
                <td className="public__total"></td>
              </tr>
            </tbody>
          </table> */}
        </section>

        <section className="sowing">
          <Input
            className="screenshots__title"
            value={reportTitle2}
            onInput={e => setReportTitle2(e.currentTarget.value)}
          />
          <div className="sowing__wrap">
            <div className="sowing__min-wrap">
              <ReportCharSowing />
            </div>
          </div>
        </section>

        <div className="footer">
          <img src="/image/logo.JPG" alt="logo" className="footer__logo" />
        </div>
      </div>
    </>
  );
};

export default ReportPageTwo;
