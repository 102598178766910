import { Tabs } from 'antd';
import ContractsAll from './ContractsAll';
import ContractsCreate from './ContractsCreate';
import './index.scss';

const tabsItems = [
  { label: 'Все договоры', key: 'contractsAll', children: <ContractsAll /> },
  { label: 'Создать договор', key: 'contractsCreate', children: <ContractsCreate /> },
];

export const Contracts = () => {
  return (
    <>
      <h3>Раздел работы с документами</h3>
      <div className="contracts">
        <Tabs items={tabsItems} />
      </div>
    </>
  );
};
