import { sharitMediaApiV1 } from '..';

export type TRegistrationResponse = {
  id: number;
  email: string;
  activated: boolean;
};

export type TRegistrationPayload = {
  name: string;
  surname: string;
  middlename: string;
  email: string;
  role: string;
  password: string;
  passwordConfirm: string;
};

export const registrationApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    registration: build.mutation<TRegistrationResponse, TRegistrationPayload>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/registration`, method: 'POST', body }),
    }),
  }),
  overrideExisting: false,
});

export const { useRegistrationMutation } = registrationApi;
