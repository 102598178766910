import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorData, sortingDate } from '../../shared/helpers';
import { TCampaignInfo } from '../../shared/lib/types';
import { useLazyGetCampaignInfoQuery } from '../../store/query/sharitMediaApiV1/campaignInfo';
import { currentAdsSlice } from '../../store/slice/uiSlice';

export const CampaignInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountId = useSelector(currentAdsSlice.selectors.accountId);
  const clientId = useSelector(currentAdsSlice.selectors.clientId);

  const [selectAds, setSelectAds] = useState<TCampaignInfo[]>([]);
  const [currentCampaignData, setCurrentCampaignsData] = useState<TCampaignInfo[]>();

  const columns: ColumnsType<TCampaignInfo> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 140,
    },
    {
      title: 'Время создания',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (_, record) => {
        if (record?.create_time) {
          const day = moment.unix(record.create_time);
          return <span>{day.format('YYYY-MM-DD HH:ss')}</span>;
        }
      },
      sorter: (a, b) => sortingDate(String(a.create_time), String(b.create_time)),
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Время начала',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_, record) => {
        if (record.start_time === 0) {
          return <span>Не задано</span>;
        } else {
          return record.start_time;
        }
      },
    },
    {
      title: 'Время остановки',
      dataIndex: 'stop_time',
      key: 'stop_time',
      render: (_, record) => {
        if (record.stop_time === 0) {
          return <span>Не задано</span>;
        } else {
          return record.stop_time;
        }
      },
    },
    {
      title: 'Формат',
      dataIndex: 'ad_format',
      key: 'ad_format',
      render: (_, record) => {
        if (record.ad_format === 1) {
          return <span>Изображение и текст</span>;
        }

        if (record.ad_format === 2) {
          return <span>Большое изображение</span>;
        }

        if (record.ad_format === 3) {
          return <span>Эксклюзивный формат</span>;
        }

        if (record.ad_format === 4) {
          return <span>Продвижение сообществ или приложений, квадратное изображение</span>;
        }

        if (record.ad_format === 5) {
          return <span>Приложение в новостной ленте (устаревший)</span>;
        }

        if (record.ad_format === 6) {
          return <span>Мобильное приложение</span>;
        }

        if (record.ad_format === 9) {
          return <span>Запись в сообществе</span>;
        }

        if (record.ad_format === 11) {
          return <span>Адаптивный формат</span>;
        }

        if (record.ad_format === 12) {
          return <span>Истории</span>;
        }
        return record.ad_format;
      },
    },
    {
      title: 'Платформы',
      dataIndex: 'publisher_platforms',
      key: 'ad_platform',
      render: (_, record) => {
        if (record.ad_platform === 'all') {
          return <span>Все площадки</span>;
        } else if (record.ad_platform === 'desktop') {
          return <span>Полная версия сайта</span>;
        } else if (record.ad_platform === 'mobile') {
          return <span>Мобильный сайт и приложения</span>;
        } else return record.ad_platform;
      },
    },
    {
      title: 'Площадки',
      dataIndex: 'publisher_platforms',
      key: 'publisher_platforms',
      render: (_, record) => {
        if (record.publisher_platforms === 'vk') {
          return <span>ВКонтакте</span>;
        } else if (record.publisher_platforms === 'social') {
          return <span>Все соцсети</span>;
        } else if (record.publisher_platforms === 'all') {
          return <span>Все площадки</span>;
        } else return record.publisher_platforms;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        if (record.status === 0) {
          return <span>Остановлена</span>;
        } else if (record.status === 1) {
          return <span>Запущена</span>;
        } else if (record.status === 2) {
          return <span>Удалена</span>;
        } else return record.status;
      },
    },
    {
      title: 'Общий лимит',
      dataIndex: 'all_limit',
      key: 'all_limit',
      render: (_, record) => {
        if (record.all_limit === '0') {
          return <span>Не задан</span>;
        } else {
          return record.all_limit;
        }
      },
    },
    {
      title: 'Дневной лимит',
      dataIndex: 'day_limit',
      key: 'day_limit',
      render: (_, record) => {
        if (record.day_limit === '0') {
          return <span>Не задан</span>;
        } else {
          return record.day_limit;
        }
      },
    },
  ];

  const [campaignTrigger, campaignData] = useLazyGetCampaignInfoQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: campaignInfoData } = campaignData;

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TCampaignInfo[]) => {
      setSelectAds(selectedRows);
    },
  };

  const toReports = () => {
    if (selectAds[0]?.id) {
      dispatch(currentAdsSlice.actions.currentAds(selectAds[0].id));
      navigate(`/reports/${id}/ads/${selectAds[0].id}`);
    }
  };

  useEffect(() => {
    if (accountId && clientId && id) {
      campaignTrigger({
        account_id: Number(accountId),
        client_id: Number(clientId),
        campaign_ids: JSON.stringify([id]),
      });
    }
  }, [accountId, clientId, id, campaignTrigger]);

  useEffect(() => {
    if (campaignInfoData && campaignInfoData[0]?.id) {
      const newData = campaignInfoData?.map(el => {
        return { ...el, key: el.id };
      });

      setCurrentCampaignsData(newData);
    }
  }, [campaignInfoData, setCurrentCampaignsData]);

  return (
    <>
      <h3 className="">Страница объявлений кампании</h3>
      <div className="btns-campaigns">
        {selectAds?.length > 0 && (
          <>
            <Button className="" type="primary" onClick={toReports}>
              Подготовить отчёт
            </Button>
          </>
        )}
      </div>

      {campaignInfoData && campaignInfoData[0]?.id && (
        <Table
          columns={columns}
          dataSource={currentCampaignData}
          scroll={{ x: true }}
          bordered
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
        />
      )}
    </>
  );
};
