import { Table, Button } from 'antd';
import { useState } from 'react';
import { TContracts } from '../../../shared/lib/types';

const ContractsAll = () => {
  const data = [
    {
      id: '12345678',
      created: '2022-09-15',
      musician: 'Саулов Д.С.',
      contractNumber: '123',
      status: 'Не отправлен',
      actions: 'Отправить музыканту',
      period: '15.09.2022 - 30.09.2023',
    },
  ];

  const [dataSource, setDataSource] = useState<TContracts>(data);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 140,
    },
    {
      title: 'Дата создания',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Артист',
      dataIndex: 'musician',
      key: 'musician',
    },
    {
      title: '№ договора',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
    },
    {
      title: 'Срок действия',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      render: (action: string) => {
        if (action === 'Отправить музыканту') {
          return <Button type="primary">Отправить музыканту</Button>;
        }
      },
    },
  ];

  return <Table columns={columns} dataSource={dataSource} bordered />;
};

export default ContractsAll;
