import { HasId } from "../../features/Constructor/Types";

export function findObjectById<T extends HasId | HasId[]>(object: T, targetId: string): T | null {
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      if (object[i].id === targetId) {
        return object[i] as T;
      }
    }
    for (let i = 0; i < object.length; i++) {
      const foundObject = findObjectById(object[i] as T, targetId);
      if (foundObject) {
        return foundObject;
      }
    }
  } else {
    if (object.id === targetId) {
      return object;
    }
    for (let prop in object) {
      if (typeof object[prop] === 'object' && object[prop] !== null) {
        const foundObject = findObjectById(object[prop] as T, targetId);
        if (foundObject) {
          return foundObject;
        }
      }
    }
  }
  
  return null;
}