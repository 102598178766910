import { DatePicker, DatePickerProps, Form, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetAdsLayoutQuery } from '../../../../store/query/sharitMediaApiV1/adsLayout';
import { useLazyGetStatisticsQuery } from '../../../../store/query/sharitMediaApiV1/statistics';
import { useLazyGetWallByIdQuery } from '../../../../store/query/sharitMediaApiV1/wallGetById';
import { CampaignsSlice } from '../../../../store/slice';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import './index.scss';

type TPickerType = 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';

const initialValues = {
  dateFrom: undefined,
  dateTo: undefined,
};

const { Option } = Select;

const ReportsStatisticsDate = () => {
  const { id, idAds } = useParams();
  const [form] = useForm();

  const dispatch = useDispatch();

  const [dateFormat, setDateFormat] = useState<string>('YYYY-MM');
  const [pickerType, setPickerType] = useState<TPickerType>('month');
  const [typeDate, setTypeDate] = useState<string>('month');
  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [statisticItem, setStatisticItem] = useState<number>();

  const campaigns = useSelector(CampaignsSlice.selectors.getCampaigns);

  const onChangeDateFrom: DatePickerProps['onChange'] = (date, dateString) => {
    setDateFrom(dateString);
  };

  const onChangeDateTo: DatePickerProps['onChange'] = (date, dateString) => {
    setDateTo(dateString);
  };

  const [getStatisticsTrigger, result] = useLazyGetStatisticsQuery();
  const { data: dataStatistics } = result;
  // console.log('dataStatistics', dataStatistics);

  const { data: dataAdsLayout } = useGetAdsLayoutQuery(
    {
      account_id: '1900015353',
      client_id: '1607606889',
      include_deleted: '1',
      campaign_ids: JSON.stringify([`${id}`]),
      ad_ids: JSON.stringify([`${idAds}`]),
    },
    { skip: !idAds },
  );

  const [getStatisticAdTrigger, dataStatisticAd] = useLazyGetStatisticsQuery();

  const [getWallByIdTrigger, dataWall] = useLazyGetWallByIdQuery();

  const onGetStatistics = () => {
    if (id && dateFrom && dateTo) {
      getStatisticsTrigger({
        account_id: 1900015353,
        ids_type: 'campaign',
        period: typeDate,
        date_from: dateFrom,
        date_to: dateTo,
        ids: Number(id),
      });
    }
  };

  useEffect(() => {
    if (dataWall && dataWall?.data) {
      if (dataWall.data[0]?.likes.count !== 0) {
        dispatch(reportsDataSlice.actions.likes(dataWall.data[0]?.likes.count));
      }
      dispatch(reportsDataSlice.actions.forwards(dataWall.data[0]?.reposts.count));
      dispatch(reportsDataSlice.actions.comments(dataWall.data[0]?.comments.count));
      // dispatch(reportsDataSlice.actions.viewsTarget(dataWall.data[0]?.));

      if (dataWall?.data[0]?.attachments[0].photo?.sizes[2]?.url && dataWall?.data[0]?.attachments[0]?.photo?.sizes[2]?.url !== '') {
        dispatch(reportsDataSlice.actions.labelImage(dataWall.data[0].attachments[0].photo.sizes[2].url));
      }
    }
  }, [dataWall, dispatch]);

  useEffect(() => {
    if (dateFrom && dateTo && typeDate && idAds) {
      getStatisticAdTrigger({
        account_id: Number(1900015353),
        date_from: dateFrom,
        date_to: dateTo,
        ids_type: 'ad',
        period: typeDate,
        ids: Number(idAds),
      });
    }
  }, [dateFrom, dateTo, typeDate, idAds, getStatisticAdTrigger]);

  useEffect(() => {
    if (dataAdsLayout) {
      const postId = dataAdsLayout[0].link_url.split('-')[1];

      getWallByIdTrigger({
        posts: `-${postId}`,
      });
    }
  }, [dataAdsLayout, getWallByIdTrigger]);

  useEffect(() => {
    // console.log('typeDate', typeDate);
    if (typeDate === 'day') {
      setDateFormat('YYYY-MM-DD');
      setPickerType('date');
      setStatisticItem(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      form.resetFields();
    }
    if (typeDate === 'week') {
      setDateFormat('YYYY-MM-DD');
      setPickerType('date');
      setStatisticItem(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      form.resetFields();
    }
    if (typeDate === 'month') {
      setDateFormat('YYYY-MM');
      setPickerType('month');
      setStatisticItem(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      form.resetFields();
    }
    if (typeDate === 'year') {
      setDateFormat('YYYY');
      setPickerType('year');
      setStatisticItem(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      form.resetFields();
    }
    if (typeDate === 'overall') {
      setDateFormat('YYYY');
      setPickerType('date');
      setStatisticItem(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      form.resetFields();
    }
  }, [typeDate, form]);

  useEffect(() => {
    if (campaigns?.length > 0) {
      const campaign = campaigns?.find(el => el.id === Number(id));
      if (campaign && campaign?.name) {
        dispatch(reportsDataSlice.actions.title(campaign.name));
      }
    }
  }, [campaigns, id, dispatch]);

  useEffect(() => {
    if (dataStatisticAd && dataStatisticAd?.data && dataStatisticAd?.data[0]?.stats[0]) {
      // console.log('dataStatisticAd', dataStatisticAd);
      dispatch(reportsDataSlice.actions.clicks(dataStatisticAd.data[0].stats[0]?.clicks));
      dispatch(reportsDataSlice.actions.ctr(Number(dataStatisticAd.data[0].stats[0]?.ctr)));
      dispatch(reportsDataSlice.actions.budget(Number(dataStatisticAd.data[0].stats[0]?.spent)));
      dispatch(reportsDataSlice.actions.views(dataStatisticAd.data[0].stats[0]?.uniq_views_count));
    }
  }, [dataStatisticAd, dispatch]);

  return (
    <>
      <div className="dates__title">Выберите даты статистики</div>

      {/* <Space direction="vertical" size={12}> */}
        <Form form={form} initialValues={initialValues} layout="vertical">
          <Form.Item>
            <Select className="dates__select" defaultValue="month" onChange={value => setTypeDate(value)}>
              <Option value="day">Дни</Option>
              <Option value="week">Недели</Option>
              <Option value="month">Месяцы</Option>
              <Option value="year">Годы</Option>
              {/* <Option value="overall">За всё время</Option> */}
            </Select>
          </Form.Item>

          <Form.Item name="dateFrom">
            <DatePicker
              picker={pickerType}
              onChange={onChangeDateFrom}
              placeholder="Дата начала"
              format={dateFormat}
              disabled={typeDate === 'overall'}
              className="ads-date"
            />
          </Form.Item>

          <Form.Item name="dateTo">
            <DatePicker
              picker={pickerType}
              onChange={onChangeDateTo}
              placeholder="Дата окончания"
              format={dateFormat}
              disabled={typeDate === 'overall'}
              className="ads-date"
            />
          </Form.Item>

          {/* <Form.Item>
            <Button type="primary" onClick={onGetStatistics} className="reports-content__btn">
              Получить статистику
            </Button>
          </Form.Item> */}
        </Form>
      {/* </Space> */}
    </>
  );
};

export default ReportsStatisticsDate;
