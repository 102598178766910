import { ReportsPage } from '../../pages';
import { CampaignsPage } from '../../pages/campaigs';
import { ReportCreatePage } from '../../pages/reports/reportCreate';
import { AboutCompanyPage } from '../../pages/aboutCompany';
import { Paths } from '../../shared/lib/constants/paths';
import { CampaignInfoPage } from '../../pages/campaigs/campaignInfoPage';
import { UsersPage } from '../../pages/users';
import { MainLayout } from '../../shared/ui/Layouts/MainLayout';
import { RegistrationPage } from '../../pages/registration';
import { ConstructorPage } from '../../pages/constructor';
import { ReleasesPage } from '../../pages/releases';
import { Auth } from '../../features/Auth/index';
import { ContractsPage } from '../../pages/contracts';
import { ServicesPage } from '../../pages/services';
import AuthLayout from '../../shared/ui/Layouts/AuthLayout';
import { ReleaseInfoPage } from '../../pages/releaseInfoPage';

/* global JSX */

export type RouteItem = {
  id: string;
  path?: string;
  element: () => JSX.Element;
  children?: RouteItem[];
  private?: true;
  roles?: string[];
  index?: boolean;
};

export const RoutingConfig: RouteItem[] = [
  {
    id: '1',
    path: Paths.auth,
    element: AuthLayout,
    children: [
      {
        id: '1.1',
        path: Paths.auth,
        element: Auth,
        // private: true,
      },
      // {
      //   id: '1.2',
      //   path: Paths.registration,
      //   element: RegistrationPage,
      //   // private: true,
      // },
    ],
  },
  {
    id: '2',
    path: Paths.registration,
    element: RegistrationPage,
  },
  {
    id: '3',
    path: Paths.main,
    element: MainLayout,
    private: true,
    children: [
      {
        id: '3.1',
        path: Paths.reports,
        element: ReportsPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.2',
        path: Paths.reportCreate,
        element: ReportCreatePage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.3',
        path: Paths.reportAds,
        element: ReportCreatePage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.4',
        path: Paths.aboutCompany,
        element: AboutCompanyPage,
        private: true,
        roles: ['1', '2', '3', '4'],
      },
      {
        id: '3.5',
        path: Paths.campaigns,
        element: CampaignsPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.6',
        path: Paths.campaignsInfo,
        element: CampaignInfoPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.7',
        path: Paths.users,
        element: UsersPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.8',
        path: Paths.contracts,
        element: ContractsPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      // {
      //   id: '3.9',
      //   path: Paths.chats,
      //   element: ChatsPage,
      //   private: true,
      //   roles: ['1', '2', '3', '4'],
      // },
      {
        id: '3.10',
        path: Paths.services,
        element: ServicesPage,
        private: true,
        roles: ['1', '2', '3', '4'],
      },
      {
        id: '3.11',
        path: Paths.constrcutor,
        element: ConstructorPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.12',
        path: Paths.releases,
        element: ReleasesPage,
        private: true,
        roles: ['1', '2', '3'],
      },
      {
        id: '3.13',
        path: Paths.release,
        element: ReleaseInfoPage,
        private: true,
        roles: ['1', '2', '3'],
      },
    ],
  },
  // {
  //   id: '3',
  //   element: ReportsPage,
  //   path: Paths.notFound,
  // },
];
