import { AppRouter } from './routing/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../store/index';
import 'moment/locale/ru';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import './App.css';

moment.locale('ru');

export const App = () => {
  return (
    // <ConfigProvider
    //   theme={{
    //     token: {
    //       colorPrimary: 'rgba(238, 146, 110, 1)',
    //       fontFamily: 'Montserrat, sans-serif',
    //       colorBgLayout: '#5b5b5b',
    //       borderRadiusLG: 4,
    //       borderRadiusOuter: 4,
    //       borderRadiusSM: 4,
    //       borderRadiusXS: 4,
    //       colorBgContainerDisabled: '#fff',
    //     },
    //   }}
    // >
    // <Provider store={store}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
    // </Provider>
    // </ConfigProvider>
  );
};

export default App;
