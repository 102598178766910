import { InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { currentElementSlice } from '../../../../store/slice/constructorSlice';
import { changeObjectProperties } from '../../../../shared/utils/changeObjectProperties';
import { TConstructorReport } from '../../Types';
// import { ValueType } from 'rc-input-number/lib/utils/MiniDecimal';

export const PanelMargin = ({ report }: { report: TConstructorReport }) => {
  const currentElement = useSelector(currentElementSlice.selectors.currentElementSelector);

  return (
    <div className="sectionPanel-margins">
      <div className="panel-margins">
        MT
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'marginTop', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-margins">
        MR
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'marginRight', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-margins">
        MB
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'marginBottom', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-margins">
        ML
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'marginLeft', 'px', currentElement.id, report)}
        />
        px
      </div>
    </div>
  );
};
