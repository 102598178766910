import { TProfileInfo } from './../../../shared/lib/types';
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { myInfoApi } from '../../query/sharitMediaApiV1/myInfo';
import defaultAvatar from './../../../shared/assets/image/noavatar.jpg';

const initialState = {
  id: 0,
  name: '',
  surname: '',
  middlename: '',
  email: '',
  role: 0,
  activated: '',
  avatar: defaultAvatar,
} as TProfileInfo;

export const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(
      myInfoApi.endpoints.myInfo.matchFulfilled,
      (state, { payload }: PayloadAction<TProfileInfo>) => payload,
    );

    builder.addMatcher(myInfoApi.endpoints.myInfo.matchRejected, () => initialState);
  },
});

const selectMyInfo = (state: RootState) => state.myInfo;
const getMyInfo = createSelector(selectMyInfo, state => state);
const getMyRole = createSelector(selectMyInfo, state => state.role);

export const selectors = { getMyInfo, getMyRole };
export const myInfoActions = myInfoSlice.actions;
export const myInfoReducer = myInfoSlice.reducer;
