import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TOptions, TSection, TSectionOptions } from '../../../../features/Constructor/Types';
import { CSSProperties } from 'react';

type TInitialValuesSections = {
  id: string;
  idPage: string;
  type: string;
  order: number;
  options: CSSProperties;
}[];

const initialState: TInitialValuesSections = [{
  id: 's-1',
  idPage: 'p-1',
  type: 'section',
  order: 1,
  options: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    width: '100%',
    height: '260px',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
}];

export const sectionsSlice = createSlice({
  name: 'constructor/sectionsSlice',
  initialState,
  reducers: {
    sections(state, { payload }: PayloadAction<TSection>) {
      return [...state, payload];
    },
    updateSection(state, { payload }: PayloadAction<{ id: string; options: CSSProperties }>) {
      return state.map(section => {
        if (section.id === payload.id) {
          return { ...section, options: { ...section.options, ...payload.options } };
        }
        return section;
      });
    },
    reset: () => initialState,
  },
});

const sections = (state: RootState) => state.constructorReport.sections;
const sectionsSelector = createSelector(sections, state => state);

export const selectors = {
  sectionsSelector,
};
export const actions = sectionsSlice.actions;
export const reducer = sectionsSlice.reducer;
