import { combineReducers } from '@reduxjs/toolkit';
import { reducer as pagesReducer } from './pagesSlice/index';
import { reducer as sectionsReducer } from './sectionsSlice/index';
import { reducer as rowsReducer } from './rowsSlice/index';
import { reducer as columnsReducer } from './columnsSlice/index';
import { reducer as templatesReducer } from './templatesSlice/index';
import { reducer as currentElementReducer } from './currentElementSlice';
import { reducer as widgetsReducer } from './widgetsSlice';
import { reducer as reportsReducer } from './reportsSlice';

export * as templatesSlice from './templatesSlice/index';
export * as pagesSlice from './pagesSlice/index';
export * as sectionsSlice from './sectionsSlice/index';
export * as rowsSlice from './rowsSlice/index';
export * as columnsSlice from './columnsSlice/index';
export * as currentElementSlice from './currentElementSlice/index';
export * as widgetsSlice from './widgetsSlice/index';
export * as reportsSlice from './reportsSlice/index';

const constructorReportReducer = combineReducers({
    reports: reportsReducer,
    template: templatesReducer,
    pages: pagesReducer,
    sections: sectionsReducer,
    rows: rowsReducer,
    columns: columnsReducer,
    currentElement: currentElementReducer,
    widgets: widgetsReducer,
});

export default constructorReportReducer;
