export enum ESocial {
    "vk" = "ВКонтакте", 
    "tikTok" = "ТикТок",
    "youTube" = "Ютуб",
    "yandex" = "Яндекс Директ",
    "blogersTikTok" = "Блогеры ТикТок",
    "targetTikTok" = "Таргет ТикТок",
    "instagram" = "Инстаграм",
};

export interface ISocial {
    id: string;
    value: string;
    name: ESocial;
}

export const socials: ISocial[] = [
    {
        id: "idVk",
        value: "vk",
        name: ESocial.vk,
    },
    {
        id: "idTikTok",
        value: "tikTok",
        name: ESocial.tikTok,
    },
    {
        id: "idYoutube",
        value: "youtube",
        name: ESocial.youTube,
    },
    {
        id: "idYandex",
        value: "yandex",
        name: ESocial.yandex,
    },
    {
        id: "idBlogersTikTok",
        value: "blogersTikTok",
        name: ESocial.blogersTikTok,
    },
    {
        id: "idTargetTikTok",
        value: "targetTikTok",
        name: ESocial.targetTikTok,
    },
    {
        id: "idInstagram",
        value: "instagram",
        name: ESocial.instagram,
    }
];