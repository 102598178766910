import { sharitMediaApiV1 } from '..';
import { EStatus } from '../../../slice/uiSlice/steps';

type TCreateOrderRequest = {
  order_id: string;
  user_id: string;
  service_name: string;
  price: number;
  order_status: EStatus;
  created_at: number;
  created_to?: number;
};

export type TGetOrderResponse = {
  id: number;
  order_id: string;
  user_id: number;
  service_name: string;
  price: number;
  order_status: EStatus;
  created_at: Date;
  created_to: Date;
};

export type TUpdateOrderStatusRequest = {
  order_id: string;
  order_status: EStatus;
}

type TDelOrderResponse = {
  ok: boolean
}

export const campaignsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    createOrder: build.mutation<TGetOrderResponse, TCreateOrderRequest>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/order`, method: 'POST', body }),
      invalidatesTags: ['orders'],
    }),
    getOrder: build.query<TGetOrderResponse, string>({
      query: orderId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/order/${orderId}`, method: 'GET' }),
    }),
    getOrders: build.query<TGetOrderResponse[], number>({
      query: userId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/orders/${userId}`, method: 'GET' }),
      providesTags: ['orders'],
    }),
    getAllOrders: build.query<TGetOrderResponse[], null>({
      query: () => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/all-orders`, method: 'GET' }),
      providesTags: ['allOrders'],
    }),
    updateOrderStatus: build.mutation<TGetOrderResponse, TUpdateOrderStatusRequest>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/order`, method: 'PUT', body }),
      invalidatesTags: ['allOrders'],
    }),
    deleteOrder: build.mutation<TDelOrderResponse, string>({
      query: orderId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/order/${orderId}`, method: 'DELETE' }),
      invalidatesTags: ['orders'],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateOrderMutation, useGetOrderQuery, useLazyGetOrderQuery, useGetOrdersQuery, useGetAllOrdersQuery, useUpdateOrderStatusMutation, useDeleteOrderMutation } = campaignsApi;
