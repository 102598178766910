import { SettingOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';
import { Chars } from '../../../../shared/lib/types';
import { ReportChar } from '../ReportChar';
import { useSelector } from 'react-redux';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import { LikesIcon } from '../../../../shared/ui/icons/LikesIcon';
import { CommentsIcon } from '../../../../shared/ui/icons/CommentsIcon';
import { ForwardsIcon } from '../../../../shared/ui/icons/ForwardsIcon';
import { ViewIcon } from '../../../../shared/ui/icons/ViewIcon';
import './index.scss';

const options = [
  { label: 'Лайки', value: 'likes' },
  { label: 'Репосты', value: 'forwards' },
  { label: 'Комментарии', value: 'comments' },
  { label: 'Показы', value: 'viewsTarget' },
];

export const ReportCharPosts = () => {
  const { comments, viewsTarget, likes, forwards } = useSelector(reportsDataSlice.selectors.reportData);

  const [charState, setCharState] = useState<Chars[]>([]);
  const [checksState, setChecksState] = useState<(string | number | boolean)[]>([]);
  const [settingsIconVisible, setSettingsIconVisible] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const chars: Chars[] = [
    {
      id: 'likes',
      title: 'Лайки',
      data: likes,
      icon: <LikesIcon />,
      visible: true,
    },
    {
      id: 'forwards',
      title: 'Репосты',
      data: forwards,
      icon: <ForwardsIcon />,
      visible: true,
    },
    {
      id: 'comments',
      title: 'Комментарии',
      data: comments,
      icon: <CommentsIcon />,
      visible: true,
    },
    {
      id: 'viewsTarget',
      title: 'Показы',
      data: viewsTarget,
      icon: <ViewIcon />,
      visible: true,
    },
  ];

  useEffect(() => {
    // console.log('checksState', checksState);
  }, [checksState]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
    setChecksState(checkedValues);

    const charCheck: (Chars | undefined)[] = checkedValues?.map(elem => chars?.find(el => elem === String(el?.id))) ?? [{}];

    if (isChars(charCheck)) {
      setCharState(charCheck);
    }
  };

  const isChars = (arr: (Chars | undefined)[]): arr is Chars[] => {
    return (arr as Chars[])[0].id !== undefined;
  };

  useEffect(() => {
    console.log('charState', charState);
  }, [charState])

  useEffect(() => {
    if (checksState.length === 0) {
      setCharState([]);
    }
  }, [setCharState, checksState])

  return (
    <div
      className="char-posts__wrap"
      onMouseOver={() => setSettingsIconVisible(true)}
      onMouseOut={() => setSettingsIconVisible(false)}
    >
      {(charState && charState?.length > 0) && charState?.map(el => {
        return <ReportChar title={el?.title} data={el?.data} key={el?.id} icon={el?.icon} />;
      })}

      {settingsIconVisible && <SettingOutlined className="char__settings" onClick={() => setSettingsVisible(true)} />}

      {settingsVisible && (
        <div className="char__check">
          <div className="char__close" onClick={() => setSettingsVisible(false)}>
            &times;
          </div>
          <Checkbox.Group options={options} value={checksState} onChange={onChange} className="check__group" />
        </div>
      )}
    </div>
  );
};
