import { lazy } from 'react';
import { Releases } from '../../features/Releases';

export const ReleasesPage = () => <Releases />;

// export const ReleasesPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Releases'));
//     }),
// );
