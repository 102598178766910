import { Input } from 'antd';
import { useState } from 'react';
import { UploadImgWrap } from '../../Reports/UploadImgWrap';
import { ReportCharWrap } from '../../Reports/ReportCreate/ReportCharWrap';

const YouTube = () => {
  const [titleSocial, setTitleSocial] = useState<string>('Отчет по социальной сети YouTube');
  const [reportTitle, setReportTitle] = useState<string>('Заголовок');
  const [screenshotTitle, setScreenshotTitle] = useState<string>('Заголовок');

  const [imageMain, setImageMain] = useState<string>('/image/notImage.jpg');
  const [imageBanner, setImageBanner] = useState<string>('/image/notImage.jpg');
  const [imageOne, setImageOne] = useState<string>('/image/notImage.jpg');
  const [imageTwo, setImageTwo] = useState<string>('/image/notImage.jpg');
  const [imageThree, setImageThree] = useState<string>('/image/notImage.jpg');
  const [scrennshotOne, setScrennshotOne] = useState<string>('/image/notImage.jpg');

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  return (
    <div className="a4">
      <img src="/image/line.png" alt="line" className="a4__figure" />
      <div className="header">
        <p className="header__company">Sharit media</p>
        {/* <Logo /> */}
      </div>

      <section className="characteristics">
        <Input
          className="characteristics__title"
          value={titleSocial}
          onInput={e => setTitleSocial(e.currentTarget.value)}
        />
        <Input
          className="characteristics__subtitle"
          value={reportTitle}
          onInput={e => setReportTitle(e.currentTarget.value)}
        />
        <div className="banner__wrap">
          <div className="banner__wrap">
            <UploadImgWrap fileImageName="labelName" file={imageBanner} setFile={setImageBanner} />
          </div>
        </div>
        <div className="characteristics__wrap">
          <div className="characteristics__img">
            <UploadImgWrap fileImageName="labelName" file={imageMain} setFile={setImageMain} />
          </div>
          <div className="characteristics__wrap-main">
            <p className="characteristics__market">Основные маркетинговые показатели</p>
            <ReportCharWrap />
          </div>
        </div>
      </section>

      <section className="types">
        <Input
          className="types__title"
          value={screenshotTitle}
          onInput={e => setScreenshotTitle(e.currentTarget.value)}
        />
        <div
          className="types__wrap"
          onMouseOver={() => setSettingsVisible(true)}
          onMouseOut={() => setSettingsVisible(false)}
        >
          <div className="types__wrap-main">
            <UploadImgWrap fileImageName="scrennshotOne" file={scrennshotOne} setFile={setScrennshotOne} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default YouTube;
