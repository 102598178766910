import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';
import { ESocial, ISocial } from '../../../../../shared/lib/constants/socials';

export enum TemplatesName {
  'vk1' = 'vk1',
  'vk2' = 'vk2',
  'vk3' = 'vk3',
  'vk4' = 'vk4',
  'tikTok' = 'tikTok',
  'youTube' = 'youTube',
  'yandex1' = 'yandex1',
  'yandex2' = 'yandex2',
};

export interface Template {
  id: string;
  name: TemplatesName;
  order: number;
};

export type TInitialTplsConsructor = {
  templates: Template[];
  currentSocial: ISocial;
};

const initialState: TInitialTplsConsructor = {
  templates: [
    {
      id: '1234567',
      name: TemplatesName.vk1,
      order: 1
    },
  ],
  currentSocial: {
    id: "idVk",
    value: "vk",
    name: ESocial.vk,
  }
};

export const pageConstructorSlice = createSlice({
  name: 'ui/reports/pageConstructor',
  initialState,
  reducers: {
    templates(state, { payload }: PayloadAction<Template[]>) {
      return { ...state, templates: [...payload] };
    },
    currentSocial(state, { payload }: PayloadAction<ISocial>) {
      return { ...state, currentSocial: payload };
    },
    reset: () => initialState,
  },
});

const pageConstructor = (state: RootState) => state.ui.reports.pageConstructor;
const pageConstructorSelector = createSelector(pageConstructor, state => state);

export const selectors = {
  pageConstructorSelector,
};
export const actions = pageConstructorSlice.actions;
export const reducer = pageConstructorSlice.reducer;
