import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import dayjs from 'dayjs';

export enum EStatus {
  success = 'success',
  process = 'process',
  error = 'error'
}

export enum UnloadStatus {
  unloaded = 'unloaded',
  noUnloaded = 'noUnloaded',
}

export type TStepOne = {
  typeRelease: string;
}

export type TStepTwo = {
  genre: string | null;
  labelImage: string;
  musician: string;
  nameRelease: string;
  places: string;
  dateRelease: string;
  dateTikTok?: string;
}

export type TStepThree = {
  number: number;
  track_id?: string;
  track_name: string;
  track_musicians: string[];
  authors_music: string[];
  authors_words: string[];
  audio_link: string;
  track_language?: string;
  time_tiktok?: number;
  copyright?: string;
  upc?: string;
  isrc?: string;
  text_language?: string;
  audio_text: string;
  fucking?: string;
};

export type TStepFive = {
  apple: string;
  youtube: string;
  yaMusic: string;
  vkMusic: string;
  deezer: string;
  spotify: string;
  sberMusic: string;
  cardCheck: boolean;
  aferta: boolean;
}

export type TStepSix = {
  orderId: string;
  orderStatus: EStatus;
  service: string;
  price: number;
}

export type TInitialValues = {
  stepOne: TStepOne;
  stepTwo: TStepTwo;
  stepThree: TStepThree[];
  // stepFour: TStepFour;
  stepFive: TStepFive;
  stepSix: TStepSix;
};

const initialState: TInitialValues = {
  stepOne: {
    typeRelease: 'single'
  },
  stepTwo: {
    genre: null,
    labelImage: "",
    musician: "",
    nameRelease: "",
    places: "all",
    dateRelease: dayjs().add(3, 'day').format('YYYY-MM-DD'),
    dateTikTok: "",
  },
  stepThree: [{
    number: 1,
    track_id: undefined,
    track_name: "",
    track_musicians: [""],
    authors_music: [""],
    authors_words: [""],
    track_language: undefined,
    time_tiktok: undefined,
    audio_link: "",
    text_language: undefined,
    audio_text: "",
    copyright: "GoldenGrooves",
  }],
  // stepFour: {
    // authorMusic: "",
    // authorWords: ""
  // },
  stepFive: {
    apple: "",
    youtube: "",
    yaMusic: "",
    vkMusic: "",
    deezer: "",
    spotify: "",
    sberMusic: "",
    cardCheck: false,
    aferta: true
  },
  stepSix: {
    orderId: "",
    orderStatus: EStatus.process,
    service: "Release",
    price: 1390
  }
};

export const stepsSlice = createSlice({
  name: 'ui/steps',
  initialState,
  reducers: {
    stepOne(state, { payload }: PayloadAction<TStepOne>) {
      return { ...state, stepOne: payload };
    },
    stepTwo(state, { payload }: PayloadAction<TStepTwo>) {
      return { ...state, stepTwo: payload };
    },
    stepThree(state, { payload }: PayloadAction<TStepThree[]>) {
      return { ...state, stepThree: payload };
    },
    // stepFour(state, { payload }: PayloadAction<TStepFour>) {
    //   return { ...state, stepFour: payload };
    // },
    stepFive(state, { payload }: PayloadAction<TStepFive>) {
      return { ...state, stepFive: payload };
    },
    stepSix(state, { payload }: PayloadAction<TStepSix>) {
      return { ...state, stepSix: payload };
    },
    reset: () => initialState,
  },
});

const stepsData = (state: RootState) => state.ui.steps;
const stepsSelector = createSelector(stepsData, state => state);

export const selectors = {
  stepsSelector,
};
export const actions = stepsSlice.actions;
export const reducer = stepsSlice.reducer;
