import { Input } from 'antd';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './index.scss';

export const ReportChar = ({ title, data, icon }: { title: string; data: string | number; icon: JSX.Element }) => {
  const [value, setValue] = useState<string | number>(data);
  const [deleteChar, setDeleteChar] = useState<boolean>(false);

  const onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <div className="char__item" onMouseOver={() => setDeleteChar(true)} onMouseOut={() => setDeleteChar(false)}>
      {icon}
      <div className="char__info">
        <span className="char__title">{title}</span>
        {/* <span className="char__data">{data}</span> */}
        <Input className="char__input" value={value} type="number" bordered={false} onInput={e => onChangeValue(e)} />
      </div>
      {/* {deleteChar && <CloseOutlined className="char__delete" />} */}
    </div>
  );
};
