import { InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { currentElementSlice } from '../../../../store/slice/constructorSlice';
import { changeObjectProperties } from '../../../../shared/utils/changeObjectProperties';
import { TConstructorReport } from '../../Types';
// import { ValueType } from 'rc-input-number/lib/utils/MiniDecimal';

export const PanelPadding = ({ report }: { report: TConstructorReport }) => {
  const currentElement = useSelector(currentElementSlice.selectors.currentElementSelector);

  return (
    <div className="sectionPanel-paddings">
      <div className="panel-paddings">
        PT
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'paddingTop', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-paddings">
        PR
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'paddingRight', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-paddings">
        PB
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'paddingBottom', 'px', currentElement.id, report)}
        />
        px
      </div>
      <div className="panel-paddings">
        PL
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'paddingLeft', 'px', currentElement.id, report)}
        />
        px
      </div>
    </div>
  );
};
