import { lazy } from 'react';
import { Contracts } from '../../features/Contracts';

export const ContractsPage = () => <Contracts />;

// export const ContractsPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Contracts'));
//     }),
// );
