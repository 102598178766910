import { Button, Input, Table } from 'antd';
import { useEffect, useState } from 'react';
import { TUser, useUsersQuery } from '../../store/query/sharitMediaApiV1/users';
import { useGetAllReleasesQuery } from '../../store/query/sharitMediaApiV1/releases';
import { useGetAllOrdersQuery } from '../../store/query/sharitMediaApiV1/orders';
import './index.scss';

export const Users = () => {
  const [dataSource, setDataSource] = useState<TUser[]>([]);

  const { data } = useUsersQuery(null);
  const { data: dataReleases } = useGetAllReleasesQuery(null);
  const { data: dataOrders } = useGetAllOrdersQuery(null);

  useEffect(() => {
    if (data) {
      setDataSource(data);
    }
  }, [data]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 140,
    },
    {
      title: 'ФИО',
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Статус email',
      dataIndex: 'activated',
      key: 'activated',
      render: (activated: boolean) => (activated ? 'Подтверждена' : 'Не подтверждена'),
    },
    {
      title: 'Роль',
      dataIndex: 'role_id',
      key: 'role_id',
      render: (role_id: number) => {
        if (role_id === 1) {
          return 'Суперадмин';
        } else if (role_id === 2) {
          return 'Админ';
        } else if (role_id === 3) {
          return 'Менеджер';
        } else if (role_id === 4) {
          return 'Артист';
        } else {
          return 'Не определена';
        }
      },
    },
    {
      title: 'Релизы',
      key: 'releases',
      render: (record: TUser) => {
        const res = dataReleases?.filter(el => el.user_id === record.id);

        return (
          <div className="releases-table">
            {res?.map(el => (
              <div key={el?.release_id} className="releases-table__item">
                <div className="releases-table__id">ID: {el?.release_id}</div>
                <div className="releases-table__name">Название: {el?.name_release}</div>
                <div className="releases-table__status">Заказ id: {el?.order_id}</div>
                <div className="releases-table__price">Цена: {el?.price}</div>
                <div className="releases-table__status">
                  Статус: {el?.order_status === 'process' ? 'Не оплачен' : 'Оплачен'}
                </div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Заказы',
      key: 'orders',
      render: (record: TUser) => {
        const res = dataOrders?.filter(el => el.user_id === record.id);

        return (
          <div className="releases-table">
            {res?.map(el => (
              <div key={el?.order_id} className="releases-table__item">
                <div className="releases-table__id">Заказ id: {el?.order_id}</div>
                <div className="releases-table__name">Название: {el?.service_name}</div>
                <div className="releases-table__price">Цена: {el?.price}</div>
                <div className="releases-table__status">
                  Статус: {el?.order_status === 'process' ? 'Не оплачен' : 'Оплачен'}
                </div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Ставка, %',
      dataIndex: 'procent',
      key: 'procent',
      render: (procent: string, record: TUser) => {
        if (procent) {
          return (
            <>
              <Input value={procent} />
              <Button type="primary">Сохранить</Button>
            </>
          );
        } else if (record.role_id === 4) {
          return (
            <div className="percent">
              <div className="percent__input">
                <Input value={procent} type="number" />
              </div>
              <Button type="primary">Сохранить</Button>
            </div>
          );
        }
      },
    },
    // {
    //   title: 'Статус договора',
    //   dataIndex: 'dogStatus',
    //   key: 'dogStatus',
    //   render: (dogStatus: string) => {
    //     if (!dogStatus) {
    //       <span>Не подписан</span>
    //     }
    //   }
    // },
  ];

  return <>{dataReleases && dataSource && <Table columns={columns} dataSource={dataSource} bordered />}</>;
};
