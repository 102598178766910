import { InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { currentElementSlice } from '../../../../store/slice/constructorSlice';
import { changeObjectProperties } from '../../../../shared/utils/changeObjectProperties';
import { TConstructorReport } from '../../Types';
import ValueType from 'rc-input-number/lib/utils/numberUtil';
import { findObjectById } from '../../../../shared/utils/findObjectById';
import { useState } from 'react';

export const PanelHeight = ({ report }: { report: TConstructorReport }) => {
  const currentElement = useSelector(currentElementSlice.selectors.currentElementSelector);
  const height = currentElement.options.height?.toString().split('p')[0];
  // const [height, setHeight] = useState(currentElement.options.height?.toString().split('p')[0]);
  console.log('height', height);

  return (
    <div className="sectionPanel-margins">
      <div className="panel-margins">
        Height
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'height', 'px', currentElement.id, report)}
          // defaultValue={height}
        />
        px
      </div>
    </div>
  );
};
