import { Avatar, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React from 'react';
import style from './index.module.scss';
import { Messages } from './Messages';
import { WriteMessage } from './WriteMessage';
import { ChatsContent } from './ChatsContent';

const { Search } = Input;

export const Chats = () => {
  return (
    <>
      <div className={style.chats__wrap}>
        <div className={style.chats__sider}>
          <Search placeholder="Поиск" className={style.chats__search} allowClear />
          <ul className={style.chats__list}>
            <li className={style.chats__link}>
              <Avatar size={46} icon={<UserOutlined />} />
              <div className={style.chats__info}>
                <div className={style.chats__fio}>Иван Иванов</div>
                <div className={style.chats__lastMessage}>Какое-то последнее сообщение</div>
              </div>
            </li>
            <li className={style.chats__link}>
              <Avatar size={42} icon={<UserOutlined />} />
              <div className={style.chats__info}>
                <div className={style.chats__fio}>Пётр Петров</div>
                <div className={style.chats__lastMessage}>Какое-то последнее сообщение</div>
              </div>
            </li>
            <li className={style.chats__link}>
              <Avatar size={42} icon={<UserOutlined />} />
              <div className={style.chats__info}>
                <div className={style.chats__fio}>Сергей Смирнов</div>
                <div className={style.chats__lastMessage}>Какое-то последнее сообщение</div>
              </div>
            </li>
            <li className={style.chats__link}>
              <Avatar size={42} icon={<UserOutlined />} />
              <div className={style.chats__info}>
                <div className={style.chats__fio}>Алексаандр Сидоров</div>
                <div className={style.chats__lastMessage}>Какое-то последнее сообщение</div>
              </div>
            </li>
          </ul>
        </div>
        <ChatsContent />
      </div>
    </>
  );
};
