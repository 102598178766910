import { lazy } from 'react';
import { Constructor } from '../../features/Constructor';

export const ConstructorPage = () => <Constructor />;
// export const ConstructorPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Constructor'));
//     }),
// );
