import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';

export type TRelease = {
  release_id?: string,
}

const initialState: TRelease = {};

export const releaseSlice = createSlice({
  name: 'ui/release',
  initialState,
  reducers: {
    release_id(state, { payload }: PayloadAction<string>) {
      return { ...state, release_id: payload };
    },
    reset: () => initialState,
  },
});

const releaseData = (state: RootState) => state.ui.release;
const releaseSelector = createSelector(releaseData, state => state);

export const selectors = {
  releaseSelector,
};
export const actions = releaseSlice.actions;
export const reducer = releaseSlice.reducer;
