import { sharitMediaApiV1 } from '..';
import { TProfileInfo } from '../../../../shared/lib/types';

export type AuthLoginResponse = {
  accessToken: string;
  refreshToken: string;
  message: string;
  user: TProfileInfo;
};

export type AuthLoginPayload = { email: string; password: string };

export const authApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<AuthLoginResponse, AuthLoginPayload>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/login`, method: 'POST', body }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/logout`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation } = authApi;
