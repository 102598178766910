import { Slider } from 'antd';
import { SetStateAction, useState } from 'react';
import UploadImageInTpl from '../../UploadImageInTpl';
import './index.scss';

type TBannerImgWrap = {
  fileImageName: string;
  setFile: React.Dispatch<SetStateAction<any>>;
  file: any;
};

export const UploadImgWrap = ({ fileImageName, file, setFile }: TBannerImgWrap) => {
  const [width, setWidth] = useState<number>(120);
  const [height, setHeight] = useState<number>(120);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const onChangeWidth = (value: number) => {
    setWidth(value);
  };

  const onChangeHeight = (value: number) => {
    setHeight(value);
  };

  return (
    <div
      className={`upload-img__wrap ${settingsVisible ? 'upload-img__wrap_active' : ''}`}
      onMouseOver={() => setSettingsVisible(true)}
      onMouseOut={() => setSettingsVisible(false)}
    >
      {settingsVisible && (
        <div className="slider-wrap">
          <div className="slider-wrap__item">
            <Slider min={120} max={1000} step={5} defaultValue={width} onChange={onChangeWidth} />
          </div>
          <div className="slider-wrap__item">
            <Slider min={120} max={1000} step={5} defaultValue={height} onChange={onChangeHeight} />
          </div>
        </div>
      )}

      <div className="upload-img__box">
        <UploadImageInTpl
          fileImageName={fileImageName}
          file={file}
          setFile={setFile}
          width={`${width}px`}
          height={`${height}px`}
        />
      </div>
    </div>
  );
};
