import { useState } from 'react';
import { UploadImgWrap } from '../../../Reports/UploadImgWrap';
import './index.scss';

export const ContractsOne = () => {
  const [fileImageBase64, setFileImageBase64] = useState<string>('/image/notImage.jpg');

  return (
    <div className="contracts-create__doc">
      <div className="doc">
        <h4 className="doc__title">Договор об использовании объектов № 03/10/2023-ДИСТР-АГ</h4>
        <h5 className="doc__subtitle">(исключительная лицензия)</h5>
        <div className="doc__head">
          <div className="doc__city">г. Москва</div>
          <div className="doc__date">«__» _____ 202_ г.</div>
        </div>
        <p className="doc__passport">
          <strong>Гражданин Российской Федерации</strong> _______________, паспорт серии _____ номер ______, выданный
          _____________________________________________________________________, дата __.__.20__ (далее – «Лицензиар»),
          разрешает
        </p>
        <p className="doc__text">
          <strong>Индивидуальному предпринимателю Гордееву Александру Олеговичу</strong> (ОГРНИП 318774600455751), далее
          – <strong>«Лицензиат»,</strong>
        </p>
        <p className="doc__text">
          использовать произведения, фонограммы, в т.ч. содержащиеся в фонограммах исполнения произведений,
          аудиовизуальные произведения (а также содержащиеся в них оригинальные и надлежащим образом лицензированные
          произведения, фонограммы произведений, в т.ч. содержащиеся в фонограммах исполнения произведений) и
          изображения (обложки, фотографии, картинки), далее – <strong>«Объекты»</strong>, указанные в приложениях
          (обозначены как «Перечень Объектов»), на следующих условиях:
        </p>
        <ol className="doc__list">
          <li>
            1. Лицензиар предоставляет Лицензиату{' '}
            <strong>
              право использовать Объекты на территории всех стран мира на условиях исключительной лицензии
            </strong>{' '}
            в течение всего периода с даты подписания соответствующих приложений к настоящему Договору, содержащих
            «Перечень Объектов», <strong>до __________ г.</strong> (далее – «Срок») следующим образом (способы
            использования Объектов в соответствии с законодательством РФ указаны в п.4 настоящего Договора):
            <ol className="doc__list doc__list_second">
              <li>
                1.1. <strong>цифровая дистрибуция</strong> (в т.ч. мобильный контент, контент цифровых платформ,
                магазинов, стриминг-сервисов, видео-сервисов и платформ, приложений и программ);
              </li>
              <li>
                1.2. <strong>синхронизация</strong> (сублицензирование Объектов в аудиовизуальные произведения: фильмы,
                сериалы, мультфильмы, рекламные кампании, программы и любые иные визуальные проекты);
              </li>
              <li>
                1.3. <strong>публичные права</strong> (сбор вознаграждения за использование Объектов, в т.ч. с помощью
                организаций по управлению правами (смежными и авторскими) на коллективной основе, в полном объеме
                (издательская доля, авторская доля, доля изготовителя, исполнительская доля) следующим образом:
                публичное исполнение, сообщение в эфир, сообщение по кабелю, доведение до всеобщего сведения,
                воспроизведение в личных целях);
              </li>
            </ol>
            <li>
              2. В соответствии с настоящим Договором Лицензиар также предоставляет{' '}
              <strong>исключительное право управлять YouTube-каналами</strong> (в т.ч. <strong>монетизировать</strong>{' '}
              контент, находящийся на них), указанные в соответствующих приложениях (обозначены «Перечень
              YouTube-каналов»).
            </li>
            <li>
              3. Лицензиар предоставляет Лицензиату{' '}
              <strong>исключительное право на защиту авторских и/или прав на Объекты</strong>, в т.ч. право на
              досудебное и судебное взыскание денежных средств с третьих лиц за неправомерное использование Объектов.
            </li>
          </li>
        </ol>
        <p>
          <span className="doc__note">Примечание:</span> Лицензиар наделяет Лицензиата правом собирать все невыплаченные
          до даты подписания настоящего Договора суммы вознаграждений, причитающихся Лицензиару, а также защищать права
          на Объекты, которые были нарушены до подписания настоящего Договора, в том числе взыскивать компенсацию с
          нарушителей в соответствии с п.3 Договора.
        </p>
        <ol className="doc__list">
          <li>
            4. <strong>«Способы»</strong> использования Объектов, указанных в приложениях к настоящему Договору,
            означает следующие способы (исключительная лицензия, территория – весь мир):
          </li>
        </ol>

        <div className="doc__signature">
          <div className="doc__signature__left">__________________</div>
          <div className="doc__signature__right"> Гордеев А.О.______________</div>
        </div>
      </div>

      <div className="doc">
        <ul className="doc__list doc__list_type">
          <li>
            воспроизводить в любой форме и любым способом, в том числе воспроизводить в форме звуко- и видеозаписи,
            запись Объектов в память ЭВМ, воспроизведение графическим и/или репрографическим способами (в целях издания
            нот или текстов песен), воспроизведение в иных формах, включая воспроизведение в файлах специального
            формата, используемых для хранения в цифровом виде, воплощение Объектов в иную объективную форму
            («рингтоны», «рингбэктоны», «реалтоны», «караоке» и т.п.), создавать Объекты в любых форматах;
          </li>
          <li>
            объединять (синхронизировать) Объекты с визуальным рядом кино-, теле-, видеофильма, слайдфильма или любого
            иного аудиовизуального произведения;
          </li>
          <li>
            распространять экземпляры Объектов любым способом: путем продажи или иной передачи права собственности,
            путем сдачи в прокат, в том числе, посредством сети Интернет, сетей сотовой связи и так далее;
          </li>
          <li>сдавать Объекты в прокат;</li>
          <li>
            импортировать экземпляры Объектов в целях распространения, включая экземпляры, изготовленные с разрешения
            обладателя исключительных авторских прав;
          </li>
          <li>
            публично исполнять Объекты непосредственно либо с помощью технических средств в месте, открытом для
            свободного посещения, или в месте, где присутствует значительное число лиц, не принадлежащих к обычному
            кругу семьи (на концертах, по телевидению, по сетям кабельного вещания, по радио и иначе);
          </li>
          <li>сообщать Объекты для всеобщего сведения путем передачи в эфир и (или) последующей передачи в эфир;</li>
          <li>сообщать Объекты для всеобщего сведения по кабелю, проводам или с помощью иных аналогичных средств;</li>
          <li>
            сообщать Объекты таким образом, при котором любое лицо может иметь доступ к ним в интерактивном режиме из
            любого места и в любое время по своему выбору, в том числе посредством сети Интернет (доведение до всеобщего
            сведения);
          </li>
          <li>
            переводить, переделывать, аранжировать или другим образом перерабатывать Объекты в любых целях, в том числе
            в целях создания производных Объектов (обработки, аранжировки, вариации, переводы текста), создания новых
            музыкальных версий Объектов в форме звукозаписи («ремиксы» и «римейки»), переработки (преобразования)
            Объектов в иные форматы («рингтоны», «рингбэктоны», «реалтоны», «караоке» и т.п.), объединения
            (синхронизации) Объектов в форме звукозаписи с визуальным рядом кино-, теле-, видеофильма, слайдфильма или
            любого иного аудиовизуального произведения, объединения (синхронизация) Объектов (его частей) с другими
            музыкальными и иными произведениями, а также право использовать созданные в результате такой переработки
            Объекты.
          </li>
        </ul>
        <p>
          <span className="doc__note">Примечание:</span> Стороны договорились в обязательном порядке, заранее, в
          письменной форме (посредством электронной почты) согласовывать сроки и формы обнародования новых Объектов, а
          также – синхронизацию и переработку Объектов. Несогласованное с Лицензиаром создание нового / производного
          Объекта путем перевода, аранжировки и прочих способов переработки не допускается.
        </p>

        <ol className="doc__list">
          <li>5. Лицензиат выплачивает Лицензиару вознаграждение в размере:</li>
          <ol className="doc__list doc__list_second">
            <li>
              5.1. <strong>70% (семьдесят процентов)</strong> от дохода, полученного в результате реализации прав на
              Объекты способами, указанными в п.1.1. и п. 2 настоящего Договора (
              <strong>цифровая дистрибуция, в т.ч. управление YouTube-каналами)</strong>;
            </li>
            <li>
              5.2. <strong>70% (семьдесят процентов)</strong> от дохода, полученного в результате реализации прав на
              Объекты способами, указанными в п.1.2. настоящего Договора (<strong>синхронизация</strong>);
            </li>
            <li>
              5.3. <strong>70% (семьдесят процентов)</strong> от дохода, полученного от использования Объектов путем их
              публичного исполнения, сообщения в эфир, сообщения для всеобщего сведения по кабелю и с помощью
              аналогичных средств, свободного воспроизведения в личных целях; указанная процентная ставка также
              применяется ко всем доходам, полученным Лицензиатом от обществ, осуществляющих управление авторскими и
              смежными правами на коллективной основе (<strong>публичные права</strong>);
            </li>
            <li>
              5.4. <strong>70% (семьдесят процентов)</strong> от дохода, полученного в результате защиты прав на Объекты
              способами, указанными в п. 3 настоящего Договора (<strong>защита прав на Объекты</strong>).
            </li>
          </ol>
        </ol>
        <div className="doc__signature">
          <div className="doc__signature__left">__________________</div>
          <div className="doc__signature__right">Гордеев А.О.______________</div>
        </div>
      </div>

      <div className="doc">
        <ol className="doc__list">
          <li>
            6. Указанное в п.5 настоящего Договора вознаграждение Лицензиара рассчитывается на основании отчетов
            партнеров Лицензиату по цифровой дистрибуции и на основании фактически полученных средств по договорам о
            синхронизации, за вычетом вознаграждения Лицензиату в соответствии с п.5 Договора и обязательных вычетов
            (налоги, комиссии).
          </li>
          <li>
            7. Отчеты о цифровой дистрибуции (в т.ч. управление YouTube-каналами) и публичных правах предоставляются в
            срок до 45 (сорока пяти) дней по окончании отчетного периода. Отчетный период равен одному кварталу.
          </li>
          <li>
            8. Отчеты по синхронизации и защите прав на Объекты предоставляются в свободной форме в срок до 5 (пяти)
            дней по факту получения вознаграждения Лицензиатом от третьих лиц по соответствующим сделкам и производствам
            (урегулированию нарушений).
          </li>
          <li>
            9. Отчеты предоставляются Лицензиару посредством электронной почты (по указанному в Договоре адресу) в том
            виде, в котором они были получены Лицензиатом от партнеров, осуществляющих дистрибуцию, с добавлением
            информации о комиссии Лицензиату и обязательных вычетов (налоги, комиссии), либо, по согласованию Сторон, в
            иной, наиболее удобной форме. Отчеты имеют краткую форму (количество использованных / реализованных/
            прослушанных / просмотренных копий Объектов и сумма вознаграждения), а также детализацию. В случае, если
            партнер Лицензиату не предоставляет детализацию отчетов по умолчанию, Лицензиат по просьбе Лицензиара может
            запросить такую детализацию с учетом установленных правил партнеров о сроках и форме предоставления
            детализированных отчетов.
          </li>
          <li>
            10. Вознаграждение Лицензиара выплачивается на основании выставленного Лицензиаром счета или принятого
            Лицензиаром Отчета, при достижении суммы, подлежащей к выплате Лицензиару, не менее 3000 (трех тысяч) рублей
            00 копеек. Лицензиар уполномочивает Лицензиата выступать налоговым агентом в части налогов на доходы
            физического лица. Для надлежащего ведения учета и документооборота в соответствии с законодательством РФ,
            Стороны оформляют необходимые документы, фиксирующие отношения Сторон, на бумажном носителе, с подписью и
            печатью (где применимо), в т.ч. приложения, дополнительные соглашения, акты, отчеты, счета, выписки и
            запросы, по запросу одной из Сторон, в разумные сроки.
          </li>
          <li>
            11. Лицензиар несет ответственность за достоверность предоставленных сведений и отвечает перед третьими
            лицами за любые нарушения интеллектуальных прав таких третьих лиц, возникших в связи с использованием
            Объектов и YouTube-каналов, указанных в соответствующих приложениях к настоящему Договору, способами,
            предусмотренными в настоящем Договоре.
          </li>
          <li>
            12. При использовании Прав на Объекты Лицензиат, руководствуясь интересами Лицензиара, общепринятой
            практикой, этикой и здравым смыслом, вправе самостоятельно решать указывать или нет имя Лицензиара
            (обозначение принадлежности прав) и/ или авторов (их творческие псевдонимы), а также вправе разрешать
            третьим лицам использование Объектов без указания имени Лицензиара и/или автора и/или исполнителя. Лицензиат
            заинтересован в популяризации Объектов, в т.ч. через указание имени (творческого псевдонима) Лицензиара
            и/или автора и/или исполнителя при использовании Объектов, и по возможности указывает имена Лицензиара,
            творческие псевдонимы авторов и/или исполнителей.
          </li>
          <li>
            13. Лицензиат обязуется:
            <ol className="doc__list doc__list_second">
              <li>
                13.1. реализовывать права, передаваемые настоящим Договором, с максимальной выгодой для Лицензиара;
              </li>
              <li>13.2. обеспечить прозрачность и доступность информации о монетизации Объектов;</li>
              <li>
                13.3. заблаговременно, в письменной форме (посредством указанной в Договоре электронной почты)
                согласовывать синхронизацию Объектов;
              </li>
              <li>
                13.4. прекратить цифровую дистрибуцию Объектов и отозвать лицензию на Объекты из обществ, осуществляющих
                управление авторскими и смежными правами на коллективной основе (публичные права), в срок до 5 (пяти)
                рабочих дней, а предлагать Объекты для синхронизации – в течение 3 (трех) рабочих дней с момента
                получения запроса от Лицензиара. Запрос о прекращении использования Объектов, а также о прекращении
                настоящего Договора может быть направлен Лицензиаром в свободной форме посредством указанной в Договоре
                электронной почты. Причина подобного решения Лицензиара значения не имеет.
              </li>
            </ol>
            <li>
              14. Настоящий Договор по истечении Срока действия, указанного в п.1. Договора, автоматически
              пролонгируется сроком на один год, в случае если хотя бы одна из Сторон не выразила желание не
              пролонгировать Договор в срок за 30 (тридцать) календарных дней до окончания Срока. Количество
              автоматических пролонгаций не ограниченно.
            </li>
          </li>
        </ol>
        <div className="doc__signature">
          <div className="doc__signature__left">__________________</div>
          <div className="doc__signature__right">Гордеев А.О.______________</div>
        </div>
      </div>

      <div className="doc">
        <ol className="doc__list">
          <li>
            15. Все споры Стороны урегулируют путем переговоров. Если возможности переговоров исчерпаны, Стороны вправе
            обратиться в суд г. Москвы.
          </li>
        </ol>
        <div className="doc__sides">
          <span>ЛИЦЕНЗИАР</span>
          <span>ЛИЦЕНЗИАТ</span>
        </div>
        <table className="requisites">
          <tr>
            <td className="requisites__td">
              <div className="requisites__side">Гражданин Российской Федерации</div>
              <div className="requisites__address">Адрес: ______</div>
              <div className="">Паспорт серии ____ номер ______</div>
              <div className="">Выдан</div>
              <div className="requisites__date">Дата выдачи: __________</div>
              <div className="">ИНН: _________________</div>
              <div className="">СНИЛС: ___________________</div>
              <div className="requisites__bank">
                <div className="">Банковские реквизиты:</div>
                <div className="">Полное название банка – __________________</div>
                <div className="">Р/с: ______________________</div>
                <div className="">К/c: ______________________</div>
                <div className="">БИК: _______________</div>
              </div>
              <div className="">Телефон: ________________</div>
              <div className="requisites__email">e-mail: __________________</div>
              <div className="">_______________ /</div>
            </td>
            <td className="requisites__td">
              <div className="requisites__side">Индивидуальный предприниматель Гордеев Александр Олегович</div>
              <div className="requisites__address">
                Адрес: г.Москва, Московский п, Московский г, Лаптева ул, 8, 2, 113
              </div>
              <div className="">ИНН: 230217131388</div>
              <div className="">СНИЛС: 318774600455751</div>
              <div className="requisites__bank">
                <div className="">Банковские реквизиты:</div>
                <div className="">Полное название банка – АО "Альфа-Банк"</div>
                <div className="">Р/с: 40802810601990001141</div>
                <div className="">К/c: 30101810200000000593</div>
                <div className="">БИК: 044525593</div>
              </div>
              <div className="">Телефон: +79671648925</div>
              <div className="requisites__email">e-mail: yoursmmru@gmail.com</div>
              <div className="">_______________ / Гордеев А.О.</div>
            </td>
          </tr>
        </table>
        <div className="doc__app">
          Приложение №1
          <br />к Договору № 05/10/2022-ДИСТР-АГ от «__»_______202_г.
        </div>
        <div className="doc__signature">
          <div className="doc__signature__left">__________________</div>
          <div className="doc__signature__right">Гордеев А.О.______________</div>
        </div>
      </div>

      <div className="doc">
        <div className="doc__appendix">
          Приложение №1 подписано <strong>«__» ________ 202_г.</strong>
        </div>
        <h5 className="doc__title_small">
          Перечень Объектов <br />
          (аудио и обложки)
        </h5>
        <div className="">
          1. <strong>Релиз:</strong> Сингл «НАЗВАНИЕ СИНГЛА» Год опубликования: 2022 г.
        </div>
        <table className="doc__table">
          <thead>
            <tr>
              <td>№</td>
              <td>Название произведения / фонограммы</td>
              <td>Исполнитель</td>
              <td>Автор музыки</td>
              <td>Автор слов</td>
              <td>Доля авторских прав</td>
              <td>Доля смежных прав</td>
            </tr>
          </thead>
          <tr>
            <td className="doc__text-center">1</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="doc__text-center">100%</td>
            <td className="doc__text-center">100%</td>
          </tr>
          <tr>
            <td className="doc__text-center">2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="doc__text-center">100%</td>
            <td className="doc__text-center">100%</td>
          </tr>
          <tr>
            <td className="doc__text-center">3</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="doc__text-center">100%</td>
            <td className="doc__text-center">100%</td>
          </tr>
          <tr>
            <td className="doc__text-center">4</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="doc__text-center">100%</td>
            <td className="doc__text-center">100%</td>
          </tr>
          <tr>
            <td className="doc__text-center">5</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="doc__text-center">100%</td>
            <td className="doc__text-center">100%</td>
          </tr>
        </table>
        <p>Обложка релиза: Альбом ___________________- автор: ____________________ (100% авторских прав):</p>

        <div className="doc__image">
          <UploadImgWrap fileImageName="relise" file={fileImageBase64} key="relise" setFile={setFileImageBase64} />
        </div>
        <div className="doc__footer">
          <div className="doc__left">
            <div className="">Лицензиар</div>
            <div>Гражданин Российской Федерации</div>
            <div>_______________ / ________________</div>
          </div>
          <div className="doc__right">
            <div className="">Лицензиат</div>
            <div>Индивидуальный предприниматель</div>
            <div>_______________ / Гордеев А. О.</div>
          </div>
        </div>
        <div className="doc__signature">
          <div className="doc__signature__left">__________________</div>
          <div className="doc__signature__right">Гордеев А.О.______________</div>
        </div>
      </div>
    </div>
  );
};
