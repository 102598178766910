import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export type TNetworkErrorData = {
  message: string;
  statusCode: number;
  error: string;
  zupRequest?: { id: number; status: string; zupId: string };
};

// export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
//   typeof error === 'object' && !!(error as FetchBaseQueryError).status;

export const isFetchBaseQueryErrorData = (error: unknown): error is TNetworkErrorData =>
  typeof error === 'object' && !!(error as TNetworkErrorData).message;

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError & { data: TNetworkErrorData } {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    'data' in error &&
    (error as FetchBaseQueryError & { data: TNetworkErrorData }).data.message != null
  );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { data: { message: string } } {
  return (
    typeof error === 'object' && error != null && 'data' in error && typeof (error as any).data.message === 'string'
  );
}
