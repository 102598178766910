import { Button, notification } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getErrorData } from '../../shared/helpers';
import { clientCookies } from '../../shared/lib/cookies';
import { Paths } from '../../shared/lib/types';
import { useLogoutMutation } from '../../store/query/sharitMediaApiV1/auth';
import { useDispatch } from 'react-redux';
import { credentialsSlice } from '../../store/slice/credentialsSlice';

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutTrigger, { isSuccess }] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logoutTrigger().unwrap();
    } catch (e) {
      const responseErr = getErrorData(e);
      if (responseErr) notification.error({ message: responseErr.message });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      clientCookies.removeCredentials();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('id');
      localStorage.removeItem('role');

      dispatch(credentialsSlice.actions.reset());

      navigate(Paths.auth);
    }
  }, [isSuccess, navigate, dispatch]);

  return (
    <Button type="primary" onClick={onLogout}>
      Выйти
    </Button>
  );
};
