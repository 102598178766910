import { campaignsReducer } from './slice/campaignsSlice/index';
import { accountsReducer } from './slice/accountsSlice/index';
import { clientsReducer } from './slice/clientsSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { sharitMediaApiV1 } from './query';
import uiReducer from './slice/uiSlice';
import { credentialsReducer } from './slice/credentialsSlice';
import { myInfoReducer } from './slice/myInfoSlice';
import constructorReducer from './slice/constructorSlice';

const rootReducer = combineReducers({
  [sharitMediaApiV1.reducerPath]: sharitMediaApiV1.reducer,
  myInfo: myInfoReducer,
  accounts: accountsReducer,
  clients: clientsReducer,
  campaigns: campaignsReducer,
  credentials: credentialsReducer,
  ui: uiReducer,
  constructorReport: constructorReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sharitMediaApiV1.middleware),
  // devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
