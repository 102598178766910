import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { clientCookies } from '../../../../shared/lib/cookies';
import { RootState } from '../../../configureStore';
import { credentialsActions } from '../../../slice/credentialsSlice';
import { AuthLoginResponse } from '../auth';
import { Paths } from '../../../../shared/lib/constants/paths';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL_API,
  prepareHeaders: (headers, { getState }) => {
    // const accessToken = (getState() as RootState).credentials.accessToken;
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) headers.set('authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

const baseRefreshQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL_API,
  prepareHeaders: (headers, { getState }) => {
    // const { refreshToken } = (getState() as RootState).credentials;
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) headers.set('authorization', `Bearer ${refreshToken}`);
    return headers;
  },
});

export const refreshTokenQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const { dispatch } = api;
  let result = await baseQuery(args, api, extraOptions);
  const { error } = result;
  if (error && error.status === 401) {
    const { data } = await baseRefreshQuery({ method: 'POST', url: '/api/refresh' }, api, extraOptions);
    if (data) {
      const { refreshToken, accessToken, user } = data as AuthLoginResponse;
      clientCookies.setCredentials({ accessToken, refreshToken });
      dispatch(credentialsActions.setCredentials({ accessToken, refreshToken, user }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      // логика выхода из приложения
      clientCookies.removeCredentials();
      localStorage.clear();
      dispatch(credentialsActions.reset());
      window.location.href = Paths.auth;
    }
  }
  return result;
};
