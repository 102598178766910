import { TProfileInfo } from './../../../shared/lib/types';
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { authApi } from '../../query/sharitMediaApiV1/auth';

type TCredentials = { isAuth: boolean; accessToken: string | null; refreshToken: string | null; user: TProfileInfo; accessTokenVk: string | undefined; userVkId: number | null };

const initialState = { isAuth: false, accessToken: null, refreshToken: null, user: {}, accessTokenVk: undefined, userVkId: null } as TCredentials;

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setIsAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload;
    },
    setCredentials: (
      state,
      {
        payload: { accessToken, refreshToken, user },
      }: PayloadAction<{ accessToken: string; refreshToken: string; user: TProfileInfo}>,
    ) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    setUserVk : (state, {
      payload: {access_token, user_id}}: PayloadAction<{access_token: string; user_id: number}>) => {
        state.accessTokenVk = access_token;
        state.userVkId = user_id;
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    // builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
    //   state.isAuth = true;
    //   state.accessToken = payload.accessToken;
    //   state.refreshToken = payload.refreshToken;
    //   state.user = payload.user;
    // });

    builder.addMatcher(authApi.endpoints.logout.matchRejected, () => initialState);
  },
});

const selectCredentials = (state: RootState) => state.credentials;
const getIsAuth = createSelector(selectCredentials, state => state);
const getMyAccessToken = createSelector(selectCredentials, state => state.accessTokenVk);
const getMyVkId = createSelector(selectCredentials, state => state.userVkId);
const getUserInfo = createSelector(selectCredentials, state => state.user);

export const selectors = { getIsAuth, getUserInfo, getMyAccessToken, getMyVkId };
export const credentialsActions = credentialsSlice.actions;
export const credentialsReducer = credentialsSlice.reducer;
