import { SettingOutlined } from '@ant-design/icons';
import { BudgetIcon, CtrIcon, ImpressionIcon, ClicksIcon } from '../../../../shared/ui/icons';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';
import { Chars } from '../../../../shared/lib/types';
import { ReportChar } from '../ReportChar';
import { useSelector } from 'react-redux';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import './index.scss';

const options = [
  { label: 'Потраченный бюджет', value: 'budget' },
  { label: 'Клики', value: 'clicks' },
  { label: 'CTR', value: 'ctr' },
  { label: 'Показы', value: 'views' },
  { label: 'CPF', value: 'cpf' },
  { label: 'Вступления', value: 'occurrences' },
  { label: 'Добавления', value: 'Additions' },
  { label: 'Стоимость добавления', value: 'priceAdditions' },
];

export const ReportCharWrap = () => {
  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);
  const { budget, clicks, ctr, views } = allDataReports;

  const [charState, setCharState] = useState<Chars[]>([]);
  const [checksState, setChecksState] = useState<(string | number | boolean)[]>([]);
  const [settingsIconVisible, setSettingsIconVisible] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const chars: Chars[] = [
    {
      id: 'budget',
      title: 'Потраченный бюджет, ₽',
      data: budget,
      icon: <BudgetIcon />,
      visible: true,
    },
    {
      id: 'clicks',
      title: 'Клики',
      data: clicks,
      icon: <ClicksIcon />,
      visible: true,
    },
    {
      id: 'ctr',
      title: 'CTR, %',
      data: ctr,
      icon: <CtrIcon />,
      visible: true,
    },
    {
      id: 'views',
      title: 'Показы',
      data: views,
      icon: <ImpressionIcon />,
      visible: true,
    },
    {
      id: 'cpf',
      title: 'CPF',
      // data: cpf,
      data: 0,
      icon: <ClicksIcon />,
      visible: true,
    },
    {
      id: 'occurrences',
      title: 'Вступления',
      // data: occurrences,
      data: 0,
      icon: <BudgetIcon />,
      visible: true,
    },
    {
      id: 'Additions',
      title: 'Добавления',
      // data: Additions,
      data: 0,
      icon: <BudgetIcon />,
      visible: true,
    },
    {
      id: 'priceAdditions',
      title: 'Цена добавления',
      // data: priceAdditions,
      data: 0,
      icon: <BudgetIcon />,
      visible: true,
    },
  ];

  useEffect(() => {
    // console.log('checksState', checksState);
  }, [checksState]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    // console.log('checked = ', checkedValues);
    setChecksState(checkedValues);

    const charCheck: (Chars | undefined)[] = checkedValues?.map(elem => chars?.find(el => elem === el?.id));

    if (isChars(charCheck)) {
      setCharState(charCheck);
    }
  };

  const isChars = (arr: (Chars | undefined)[]): arr is Chars[] => {
    return (arr as Chars[])[0].id !== undefined;
  };

  return (
    <div
      className={`char__wrap ${settingsIconVisible ? 'char__wrap_active' : ''}`}
      onMouseOver={() => setSettingsIconVisible(true)}
      onMouseOut={() => setSettingsIconVisible(false)}
    >
      {charState?.length > 0 && charState?.map(el => {
        return <ReportChar title={el?.title} data={el?.data} key={el?.id} icon={el?.icon} />;
      })}

      {settingsIconVisible && <SettingOutlined className="char__settings" onClick={() => setSettingsVisible(true)} />}

      {settingsVisible && (
        <div className="char__check">
          <div className="char__close" onClick={() => setSettingsVisible(false)}>
            &times;
          </div>
          <Checkbox.Group options={options} value={checksState} onChange={onChange} className="check__group" />
        </div>
      )}
    </div>
  );
};
