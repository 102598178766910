import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

export type TInitialState = {
  campaign: number;
  ads: number
};

const initialState: TInitialState = {
  campaign: 0,
  ads: 0,
};

export const reportAdsSlice = createSlice({
  name: 'ui/reports/reportAds',
  initialState,
  reducers: {
    campaign(state, { payload }: PayloadAction<number>) {
      return { ...state, campaign: payload };
    },
    ads(state, { payload }: PayloadAction<number>) {
      return { ...state, ads: payload };
    },
    reset: () => initialState,
  },
});

const reportAds = (state: RootState) => state.ui.reports.reportAds;
const reportAdsSelector = createSelector(reportAds, state => state);

export const selectors = {
  reportAdsSelector,
};
export const actions = reportAdsSlice.actions;
export const reducer = reportAdsSlice.reducer;
