import { useLocation } from 'react-router-dom';

const usePath = (index: number) => {
  const location = useLocation();
  const pathArray: string[] = location.pathname.split('/');
  const path: string = pathArray[index];
  return path;
};

export default usePath;
