import { sharitMediaApiV1 } from '..';

type TGetAdsLayoutRequest = {
  account_id: string;
  client_id: string;
  include_deleted: string;
  campaign_ids: string;
  ad_ids: string;
};

export type TGetAdsLayoutResponse = {
  campaign_id: number;
  id: number;
  goal_type: number;
  cost_type: number;
  age_restriction: number;
  title: string;
  link_type: number;
  link_url: string;
  link_domain: string;
  ad_format: number;
  preview_link: string;
  image_src: string;
  okved: string;
  social: boolean;
  hide_likes: boolean;
  new_publisher_platforms: boolean;
}[];

export const adsLayoutApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getAdsLayout: build.query<TGetAdsLayoutResponse, TGetAdsLayoutRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getAdsLayout`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAdsLayoutQuery } = adsLayoutApi;
