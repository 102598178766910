import { notification } from 'antd';
import { useEffect } from 'react';

export const useNotifications = (
  successResult: boolean,
  errorResult: boolean,
  successResultMessage: string,
  errorResultMessage: string | undefined | null,
  reset: () => void,
) => {
  useEffect(() => {
    if (successResult) {
      notification.success({
        message: successResultMessage,
      });
    }
  }, [successResult, successResultMessage]);

  useEffect(() => {
    if (errorResult) {
      notification.error({
        message: errorResultMessage || 'Что-то пошло не так',
        // top: 160,
      });
    }
  }, [errorResult, errorResultMessage]);

  useEffect(() => () => reset(), [reset]);
};
