import { TConstructorReport } from "../../features/Constructor/Types";
import { currentElementSlice, sectionsSlice } from "../../store/slice/constructorSlice";
import ValueType from 'rc-input-number/lib/utils/numberUtil';
import { findObjectById } from "./findObjectById";
import { store } from "../../store";
import { CSSProperties } from "react";

export const changeObjectProperties = (value: number | null, optionKey: keyof CSSProperties, units: string | undefined, currentElement: string, constructorReport: TConstructorReport, ) => {
  const currentObject = findObjectById(constructorReport, currentElement);
  console.log('currentObject', currentObject)

  if (currentObject && currentObject.type === 'section') {
    const optionValue = value !== null ? value as number : undefined;

    store.dispatch(sectionsSlice.actions.updateSection({
      id: currentElement,
      options: { [optionKey]: `${optionValue}${units}` },
    }));

    store.dispatch(currentElementSlice.actions.options({options: { [optionKey]: `${optionValue}${units}` }}));
  }
}