import { Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  TGetOrderResponse,
  useGetAllOrdersQuery,
  useUpdateOrderStatusMutation,
} from '../../store/query/sharitMediaApiV1/orders';
import { EStatus } from '../../store/slice/uiSlice/steps';
import { getErrorData } from '../../shared/helpers';
import { useNotifications } from '../../shared/hooks/use-notifications';
import { useUsersQuery } from '../../store/query/sharitMediaApiV1/users';

export const Services = () => {
  const [dataSource, setDataSource] = useState<TGetOrderResponse[]>([]);

  const { data: dataUsers } = useUsersQuery(null);
  const { data: dataOrders } = useGetAllOrdersQuery(null);

  const [updateTrigger, result] = useUpdateOrderStatusMutation({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { isSuccess, isError, error, data, reset } = result;
  useNotifications(isSuccess, isError, 'Статус успешно обновлен', error?.message, reset);

  const onChangeStatusPayment = (value: EStatus, order_id: string) => {
    updateTrigger({ order_id, order_status: value });
  };

  const selectStatus = (status: EStatus, order_id: string) => {
    return (
      <Select
        defaultValue={status}
        style={{ width: 130 }}
        onSelect={value => onChangeStatusPayment(value, order_id)}
        className={status === EStatus.process ? 'select-process' : 'select-success'}
      >
        <Select.Option value={EStatus.process}>Не оплачен</Select.Option>
        <Select.Option value={EStatus.success}>Оплачен</Select.Option>
      </Select>
    );
  };

  useEffect(() => {
    if (dataOrders) {
      setDataSource(dataOrders);
    }
  }, [dataOrders]);

  const columns = [
    {
      title: 'ID услуги',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: 'Название',
      dataIndex: 'service_name',
      key: 'service_name',
    },
    {
      title: 'Артист',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id: number) => {
        if (dataUsers) {
          const user = dataUsers.find(el => el.id === user_id);
          return `${user?.name}`;
        }
      },
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'order_status',
      key: 'order_status',
      render: (order_status: EStatus, record: TGetOrderResponse) => {
        if (order_status === EStatus.process) {
          return <div>{selectStatus(EStatus.process, record.order_id)}</div>;
        } else {
          return <div>{selectStatus(EStatus.success, record.order_id)}</div>;
        }
      },
    },
  ];

  return <>{dataSource && <Table columns={columns} dataSource={dataSource} bordered />}</>;
};
