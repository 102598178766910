import { useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import ReportPageOne from '../ReportPages/ReportPageOne';
import { Button } from 'antd';
import ReportPageTwo from '../ReportPages/ReportPageTwo';
import ReportPageThree from '../ReportPages/ReportPageThree';
import ReportPageFour from '../ReportPages/ReportPageFour';
import { useDispatch, useSelector } from 'react-redux';
import { pageConstructorSlice } from '../../../store/slice/uiSlice/reports';
import { Template, TemplatesName } from '../../../store/slice/uiSlice/reports/pageConstructor';
import { modalTemplatesSlice } from '../../../store/slice/uiSlice';
import './index.scss';
import { ModalTemplate } from '../../../widgets';
import TikTok from '../../Templates/TikTok';
import YouTube from '../../Templates/Youtube';
import Yandex from '../../Templates/Yandex';
import YandexTwo from '../../Templates/YandexTwo';

const template = {
  vk1: <ReportPageOne />,
  vk2: <ReportPageTwo />,
  vk3: <ReportPageThree />,
  vk4: <ReportPageFour />,
  tikTok: <TikTok />,
  youTube: <YouTube />,
  yandex1: <Yandex />,
  yandex2: <YandexTwo />,
};

type TemplateConfig = {
  component: JSX.Element;
  name: pageConstructorSlice.TemplatesName;
  id: string;
  order: number;
};

const templateConfig = (temp: Template[]): TemplateConfig[] => {
  const newDataTemplate: TemplateConfig[] = [];

  temp?.forEach(el => {
    if (el.name === TemplatesName[el.name]) {
      newDataTemplate.push({ ...el, component: template[el.name] });
    }
  });

  return newDataTemplate;
};

const ReportFrame = () => {
  const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement | any>(null);
  const { templates } = useSelector(pageConstructorSlice.selectors.pageConstructorSelector);

  const openSettings = () => {
    dispatch(modalTemplatesSlice.actions.view(true));
  };

  useEffect(() => {
    console.log('templates', templates);
  }, [templates]);

  return (
    <>
      <div className="pdfFrame">
        <div className="pdfFrame__body" ref={iframeRef}>
          {templateConfig(templates).map(el => {
            return <div key={el.id}>{el.component}</div>;
          })}
        </div>
      </div>
      <div className="pdfFrame__btns">
        <Button type="primary" onClick={openSettings}>
          Настройка шаблона
        </Button>
        <ReactToPrint trigger={() => <Button type="primary">Скачать отчёт</Button>} content={() => iframeRef.current} />
        <Button type="primary">Сохранить отчёт</Button>
      </div>

      <ModalTemplate />
    </>
  );
};

export default ReportFrame;
