import { Row } from '../Row';
import { useSelector } from 'react-redux';
import { rowsSlice, sectionsSlice } from '../../../store/slice/constructorSlice';
import { setCurrentElement } from '../../../shared/utils/setCurrentElement';
import './index.scss';
import { genId } from '../../../shared/utils';
import { TSection } from '../Types';

export const Section = () => {
  const sections = useSelector(sectionsSlice.selectors.sectionsSelector);
  const rows = useSelector(rowsSlice.selectors.rowsSelector);

  const createSection = () => {
    const initialState: TSection = {
      id: `s-${genId()}`,
      idPage: 'p-1',
      type: 'section',
      order: 1,
      options: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '100%',
        height: '260px',
        marginTop: 0,
        marginRight: 0,
        marginBottom: 20,
        marginLeft: 0,
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
      },
    };

    sectionsSlice.actions.sections(initialState);
  };

  const renderSection = () => {};

  return (
    <>
      {sections &&
        sections?.map(el => (
          <div
            className="c-section"
            key={el.id}
            id={el.id}
            datatype="section"
            style={el.options}
            onClick={() => setCurrentElement(el?.id, el?.options)}
          >
            {rows ? (
              rows?.map(el => {
                return <Row id={el.id} key={el.id} />;
              })
            ) : (
              <></>
            )}
            <div className="c-section-add" onClick={() => createSection()}>
              +
            </div>
          </div>
        ))}
    </>
  );
};
