import { sharitMediaApiV1 } from '..';
import { EStatus, TStepThree, UnloadStatus } from '../../../slice/uiSlice/steps';
// import { EStatus, TStepThree, UnloadStatus } from '../../../slice/uiSlice/steps';

type TReleaseLinks = {
  apple: string;
  youtube: string;
  yamusic: string;
  vkmusic: string;
  deezer: string;
  spotify: string;
  sbermusic: string;
}

export type TCreateReleaseRequest = {
  release_id: string;
  type: string;
  genre: string | null;
  label_image: string;
  musician: string;
  name_release: string;
  places: string[];
  date_release: string;
  date_tiktok: string | undefined;
  tracks: TStepThree[];
  card_check: boolean;
  aferta: boolean;
  price: number;
  order_status: string;
  order_id: string;
  unload_status: UnloadStatus;
};

export type TUserInfo = {
  activated: boolean;
  avatar: string;
  email: string;
  id: number;
  middlename: string;
  name: string;
  phone: string;
  role: number;
  surname: string;
}

export type TGetReleaseResponse = {
  release_id: string;
  user_id: number;
  type: string;
  genre: string | null;
  label_image: string;
  musician: string;
  tracks: TStepThree[];
  name_release: string;
  places: string[];
  date_release: string;
  date_tiktok: string | undefined;
  card_check: boolean;
  aferta: boolean;
  price: number;
  order_status: EStatus;
  order_id: string;
  service_name: string;
  releaseLinks: TReleaseLinks;
  unload_status: UnloadStatus;
  user: TUserInfo;
  created_at: Date;
  updated_at: Date;
};

type TDelReleaseResponse = {
  ok: boolean
}

export const campaignsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    createRelease: build.mutation<TGetReleaseResponse, TCreateReleaseRequest>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/release`, method: 'POST', body }),
      invalidatesTags: ['orders'],
    }),
    getRelease: build.query<TGetReleaseResponse, string>({
      query: releaseId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/release/${releaseId}`, method: 'GET' }),
    }),
    getReleases: build.query<TGetReleaseResponse[], number>({
      query: userId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/releases/${userId}`, method: 'GET' }),
      providesTags: ['releases'],
    }),
    getAllReleases: build.query<TGetReleaseResponse[], null>({
      query: () => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/all-releases`, method: 'GET' }),
    }),
    updateRelease: build.mutation<TGetReleaseResponse, TCreateReleaseRequest>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/release`, method: 'PUT', body }),
    }),
    deleteRelease: build.mutation<TDelReleaseResponse, string>({
      query: releaseId => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/release/${releaseId}`, method: 'DELETE' }),
      invalidatesTags: ['releases'],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateReleaseMutation, useGetReleaseQuery, useLazyGetReleaseQuery, useGetReleasesQuery, useGetAllReleasesQuery, useUpdateReleaseMutation, useDeleteReleaseMutation } = campaignsApi;
