import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TPage } from '../../../../features/Constructor/Types';

type TInitialValuesPages = {
  id: string;
  idTemplate: string;
  name: string;
  type: string;
  order: number;
  options: {
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingLeft?: number;
  }
}[];

const initialState: TInitialValuesPages = [{
    id: 'p-1',
    idTemplate: 't-1',
    name: 'page-1',
    type: 'page',
    order: 1,
    options: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
  }
]

export const pagesSlice = createSlice({
  name: 'constructor/pagesSlice',
  initialState,
  reducers: {
    pages(state, { payload }: PayloadAction<TPage[]>) {
      return { ...state, pages: payload };
    },
    reset: () => initialState,
  },
});

const pages = (state: RootState) => state.constructorReport.pages;
const pagesSelector = createSelector(pages, state => state);

export const selectors = {
  pagesSelector,
};
export const actions = pagesSlice.actions;
export const reducer = pagesSlice.reducer;
