import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import { ReportCharPosts } from '../../ReportCreate/ReportCharPosts';
import { ReportCharWrap } from '../../ReportCreate/ReportCharWrap';
import { UploadImgWrap } from '../../UploadImgWrap';
import './index.scss';

const ReportPageOne = () => {
  const dispatch = useDispatch();
  const [titleSocial, setTitleSocial] = useState<string>('Отчет по социальной сети Вконтакте');

  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);

  const { labelImage, screenshotLabel, nativeOne, nativeTwo, title, screenshotTwoLabel } = allDataReports;

  const [reportTitle, setReportTitle] = useState<string>(title);
  const [nativeTitle, setNativeTitle] = useState<string>('Типы используемых нативных постов');
  const [fileImageBase64, setFileImageBase64] = useState<string>(labelImage);
  const [fileScreenshotBase64, setScreenshotImageBase64] = useState<string>(screenshotLabel);
  const [fileScreenshotTwoBase64, setScreenshotTwoImageBase64] = useState<string>(screenshotTwoLabel);
  const [fileScreenshotThreeBase64, setScreenshotThreeImageBase64] = useState<string>('/image/notImage.jpg');
  const [fileNativeOneBase64, setFileNativeOneBase64] = useState<string>(nativeOne);
  const [fileNativeTwoBase64, setFileNativeTwoBase64] = useState<string>(nativeTwo);

  const [addPostVisible, setAddPostVisible] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  // const []

  const addImages = () => {
    setAddPostVisible(prev => !prev);
  };

  useEffect(() => {
    dispatch(reportsDataSlice.actions.labelImage(fileImageBase64));
  }, [fileImageBase64, dispatch]);

  useEffect(() => {
    dispatch(reportsDataSlice.actions.screenshotLabel(fileScreenshotBase64));
  }, [fileScreenshotBase64, dispatch]);

  useEffect(() => {
    dispatch(reportsDataSlice.actions.screenshotLabel(fileScreenshotTwoBase64));
  }, [fileScreenshotTwoBase64, dispatch]);

  useEffect(() => {
    dispatch(reportsDataSlice.actions.nativeOne(fileNativeOneBase64));
  }, [fileNativeOneBase64, dispatch]);

  useEffect(() => {
    dispatch(reportsDataSlice.actions.nativeTwo(fileNativeTwoBase64));
  }, [fileNativeTwoBase64, dispatch]);

  useEffect(() => {
    setReportTitle(title);
  }, [title]);

  // useEffect(() => {
  //   setFileImageBase64(labelImage);
  // }, [labelImage]);

  // useEffect(() => {
  //   setScreenshotImageBase64(screenshotLabel);
  // }, [screenshotLabel]);

  // useEffect(() => {
  //   setFileNativeOneBase64(nativeOne);
  // }, [nativeOne]);

  // useEffect(() => {
  //   setFileNativeTwoBase64(nativeTwo);
  // }, [nativeTwo]);

  return (
    <div className="a4">
      <img src="/image/line.png" alt="line" className="a4__figure" />
      <div className="header">
        <p className="header__company">Sharit media</p>
        {/* <Logo /> */}
      </div>

      <section className="characteristics">
        <Input
          className="characteristics__title"
          value={titleSocial}
          onInput={e => setTitleSocial(e.currentTarget.value)}
        />
        <Input
          className="characteristics__subtitle"
          value={reportTitle}
          onInput={e => setReportTitle(e.currentTarget.value)}
        />
        <div className="characteristics__wrap">
          <div className="characteristics__img">
            <UploadImgWrap fileImageName="labelName" file={fileImageBase64} setFile={setFileImageBase64} />
          </div>
          <div className="characteristics__wrap-main">
            <p className="characteristics__market">Основные маркетинговые показатели</p>
            <ReportCharWrap />
          </div>
        </div>
      </section>

      <section className="types">
        <Input className="types__title" value={nativeTitle} onInput={e => setNativeTitle(e.currentTarget.value)} />
        <div
          className="types__wrap"
          onMouseOver={() => setSettingsVisible(true)}
          onMouseOut={() => setSettingsVisible(false)}
        >
          <div className="types__wrap-main">
            <UploadImgWrap
              fileImageName="screenshotTwoLabel"
              file={fileScreenshotTwoBase64}
              setFile={setScreenshotTwoImageBase64}
            />
            <ReportCharPosts />
          </div>

          <div className="native__images">
            <UploadImgWrap
              fileImageName="screenshotLabel"
              file={fileScreenshotBase64}
              setFile={setScreenshotImageBase64}
            />

            <UploadImgWrap
              fileImageName="fileScreenshotThreeBase64"
              file={fileScreenshotThreeBase64}
              setFile={setScreenshotThreeImageBase64}
            />
          </div>

          {settingsVisible && (
            <div className="types-add" onClick={addImages}>
              {!addPostVisible ? <PlusOutlined /> : <MinusOutlined />}
            </div>
          )}
        </div>

        {addPostVisible && (
          <div className="native-posts">
            <UploadImgWrap fileImageName="nativePostOne" file={fileNativeOneBase64} setFile={setFileNativeOneBase64} />
            <UploadImgWrap fileImageName="nativePostTwo" file={fileNativeTwoBase64} setFile={setFileNativeTwoBase64} />
          </div>
        )}
      </section>

      <div className="footer">
        <img src="/image/logo.JPG" alt="logo" className="footer__logo" />
      </div>
    </div>
  );
};

export default ReportPageOne;
