import { lazy } from 'react';
import { Services } from '../../features/Services';

export const ServicesPage = () => <Services />;

// export const ServicesPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Services'));
//     }),
// );
