import { useEffect, useState } from 'react';
import './index.scss';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  columnsSlice,
  currentElementSlice,
  pagesSlice,
  reportsSlice,
  rowsSlice,
  sectionsSlice,
  templatesSlice,
  widgetsSlice,
} from '../../../store/slice/constructorSlice';
import { PanelWidth } from './PanelWidth';
import { PanelHeight } from './PanelHeight';
import { PanelMargin } from './PanelMargin';
import { PanelPadding } from './PanelPadding';

export const Panel = () => {
  const currentElement = useSelector(currentElementSlice.selectors.currentElementSelector);

  const [viewSection, setViewSection] = useState(true);
  const [viewWidth, setViewWidth] = useState(false);
  const [viewHeight, setViewHeight] = useState(false);
  const [viewMargin, setViewMargin] = useState(false);
  const [viewPadding, setViewPadding] = useState(false);

  const reports = useSelector(reportsSlice.selector.reportsSelector);
  const template = useSelector(templatesSlice.selectors.templatesSelector);
  const pages = useSelector(pagesSlice.selectors.pagesSelector);
  const sections = useSelector(sectionsSlice.selectors.sectionsSelector);
  const rows = useSelector(rowsSlice.selectors.rowsSelector);
  const columns = useSelector(columnsSlice.selectors.columnsSelector);
  const widgets = useSelector(widgetsSlice.selectors.widgetsSelector);

  const report = {
    id: reports.id,
    type: reports.type,
    order: reports.order,
    template,
    pages,
    sections,
    rows,
    columns,
    widgets,
  };

  const changeView = () => {
    if (viewMargin) {
      setViewPadding(false);
    }

    if (viewPadding) {
      setViewMargin(false);
    }
  };

  useEffect(() => {
    changeView();
  }, [viewMargin, viewPadding]);

  return (
    <div className="sectionPanel">
      <div className="sectionPanel__main">
        <div className="sectionPanel__instrument" onClick={() => setViewWidth(!viewWidth)}>
          W
        </div>
        <div className="sectionPanel__instrument" onClick={() => setViewHeight(!viewHeight)}>
          H
        </div>
        <div className="sectionPanel__instrument" onClick={() => setViewMargin(!viewMargin)}>
          M
        </div>
        <div className="sectionPanel__instrument" onClick={() => setViewPadding(!viewPadding)}>
          P
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <AlignLeftOutlined />
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <AlignCenterOutlined />
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <AlignRightOutlined />
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <ArrowUpOutlined />
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <ArrowDownOutlined />
        </div>
        <div className="sectionPanel__instrument" onClick={() => setViewSection(!viewSection)}>
          {viewSection ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
        <div className="sectionPanel__instrument" onClick={() => {}}>
          <DeleteOutlined />
        </div>
      </div>

      {viewWidth && <PanelWidth report={report} />}

      {viewHeight && <PanelHeight report={report} />}

      {viewMargin && <PanelMargin report={report} />}

      {viewPadding && <PanelPadding report={report} />}

      {/* <div className="panel-paddings">
          Columns
          <InputNumber className="panel-input" />
        </div> */}
    </div>
  );
};
