import { Tabs } from 'antd';
import { ReportsAll } from './ReportsAll';
import { ReportsSettingsCreate } from './ReportsSettingsCreate';
import './index.scss';

const tabsItems = [
  { label: 'Все отчёты', key: 'reportsAll', children: <ReportsAll /> },
  { label: 'Создать отчёт', key: 'reportsSettingsCreate', children: <ReportsSettingsCreate /> },
];

export const Reports = () => {
  return (
    <>
      <h3 className="reports-title">Отчёты</h3>
      <Tabs items={tabsItems} />
    </>
  );
};
