import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivatePageHOC } from './PrivatePageHOC';
import { RouteItem, RoutingConfig } from './RoutingConfig';

const getRoutes = (el: RouteItem) => {
  if (el.children?.length) {
    return (
      <Route key={el.id} path={el.path} element={<el.element />}>
        {el.children.map((child: RouteItem) => getRoutes(child))}
      </Route>
    );
  }

  if (el.private) {
    return (
      <Route
        key={el.id}
        path={el.path}
        index={el.index}
        element={
          <PrivatePageHOC roles={el.roles}>
            <el.element />
          </PrivatePageHOC>
        }
      />
    );
  }

  return <Route index={el.index} key={el.id} path={el.path} element={<el.element />} />;
};

export const AppRouter = () => <Routes>{RoutingConfig.map(el => getRoutes(el))}</Routes>;
