import { isFetchBaseQueryError, isFetchBaseQueryErrorData } from './lib/helpers/guards';
import { MenuKeyLink, MenuKeyOption, MenuKeysMap } from './lib/types';

export const getErrorData = (error: unknown) => {
  const errorResult = isFetchBaseQueryError(error) && error.data;
  const errorData = isFetchBaseQueryErrorData(errorResult) && errorResult;
  return errorData || null;
};

export const getMenuKeys = (menuKeysMap: MenuKeysMap, pathname: MenuKeyLink): MenuKeyOption =>
  menuKeysMap[pathname] || {};

export const renderImage = (file: any, setter: any) => {
  const reader = new FileReader();

  reader.onload = function () {
    setter(reader.result);
  };

  reader.readAsDataURL(file);
};

export const sortingDate = (a: string | undefined, b: string | undefined) => {
  let total = 0;
  if (a && b) {
    total = Number(a) - Number(b);
  }
  return total;
};

export const getRole = (role: number) => {
  if (role === 1) {
    return 'Суперадмин';
  } else if (role === 2) {
    return 'Админ';
  } else if (role === 3) {
    return 'Менеджер';
  } else if (role === 4) {
    return 'Артист';
  }
};
