import { combineReducers } from '@reduxjs/toolkit';
import reportsReducer from './reports/index';
import { reducer as drawerReducer } from './uiDrawers/profileDrawer/index';
import { reducer as aboutCompanyReducer } from './aboutCompany/index';
import { reducer as modalTemplatesReducer } from './modalTemplates/index';
import { reducer as currentAdsReducer } from './currentAds/index';
import { reducer as stepsReducer } from './steps/index';
import { reducer as releaseReducer } from './release/index';

export * as uiReportsSlice from './reports/index';
export * as uiDrawerSlice from './uiDrawers/profileDrawer/index';
export * as aboutCompanySlice from './aboutCompany/index';
export * as modalTemplatesSlice from './modalTemplates';
export * as currentAdsSlice from './currentAds';
export * as stepsSlice from './steps';
export * as releaseSlice from './release';

const uiReducer = combineReducers({
  reports: reportsReducer,
  drawers: drawerReducer,
  aboutCompany: aboutCompanyReducer,
  modalTemplates: modalTemplatesReducer,
  currentAds: currentAdsReducer,
  steps: stepsReducer,
  release: releaseReducer,
});

export default uiReducer;
