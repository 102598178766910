import React, { SetStateAction, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { renderImage } from '../../shared/helpers';
import './index.scss';
import { FormInstance } from 'antd/es/form/Form';

type TBannerUpload = {
  fileImageName: string;
  setFile: React.Dispatch<SetStateAction<any>>;
  file: any;
  width: string;
  height: string;
};

const UploadImageInTpl = ({ fileImageName, file, setFile, width, height }: TBannerUpload) => {
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const uploadFileHandler = (e: any) => {
    const getBase64 = renderImage(e.target.files[0], setFile);

    setFile(getBase64);
    // setImageFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const clearImage = () => {
    setFile(null);
    setFileName(undefined);
    // setImageFile([]);
  };

  return (
    <Form>
      <Form.Item name="bannerFile" className='upload-image__no-margin'>
        <label 
          htmlFor={fileImageName} 
          className={`upload-file__label ${file ? 'upload-file__label-file' : ''}`}
          style={{width, height}}
        >
          <Input
            accept=".png"
            type="file"
            id={fileImageName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => uploadFileHandler(e)}
            className="input-upload"
            value=''
          />

          {file ? (
            <img src={file} 
              className="upload-file__image" 
              alt={fileName}
            />
          ) : (
            <>
              <PlusOutlined />
              <p className="upload-file__label-title">Загрузить изображение</p>
            </>
          )}
        </label>
      </Form.Item>

      {/* <div className="banner__buttons">
        <Button type="primary" onClick={clearImage} className="upload__btn">
          Очистить
        </Button>
      </div> */}
    </Form>
  );
};

export default UploadImageInTpl;
