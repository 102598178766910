import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

type TInitialValuesReports = {
  customTitleOne: string;
  cabinetScreen: string;
  customTitleTwo: string;
  dynamicScreen: string;
  customTitleThree: string;
  demographyScreen: string;
};

const initialState: TInitialValuesReports = {
  customTitleOne: 'Название первого скриншота',
  cabinetScreen: '/image/notImage.jpg',
  customTitleTwo: 'Название второго скриншота',
  dynamicScreen: '/image/notImage.jpg',
  customTitleThree: 'Название третьего скриншота',
  demographyScreen: '/image/notImage.jpg',
};

export const pageTwoSlice = createSlice({
  name: 'ui/reports/pageTwo',
  initialState,
  reducers: {
    customTitleOne(state, { payload }: PayloadAction<string>) {
      return { ...state, customTitleOne: payload };
    },
    cabinetScreen(state, { payload }: PayloadAction<string>) {
      return { ...state, cabinetScreen: payload };
    },
    customTitleTwo(state, { payload }: PayloadAction<string>) {
      return { ...state, customTitleTwo: payload };
    },
    dynamicScreen(state, { payload }: PayloadAction<string>) {
      return { ...state, dynamicScreen: payload };
    },
    customTitleThree(state, { payload }: PayloadAction<string>) {
      return { ...state, customTitleThree: payload };
    },
    demographyScreen(state, { payload }: PayloadAction<string>) {
      return { ...state, demographyScreen: payload };
    },
    reset: () => initialState,
  },
});

const reportPageTwo = (state: RootState) => state.ui.reports.pageTwo;

export const selectors = {
  reportPageTwo,
};
export const actions = pageTwoSlice.actions;
export const reducer = pageTwoSlice.reducer;
