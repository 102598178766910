import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { modalTemplatesSlice } from '../../../store/slice/uiSlice';
import { ModalTemplate } from '../../../widgets';
import { FormAdsSelect } from '../../../widgets/FormAdsSelect';

export const ReportsSettingsCreate = () => { 
  const dispatch = useDispatch();
  
  const onSelectTemplate = () => {
    dispatch(modalTemplatesSlice.actions.view(true))
  };

  return (
    <>
      {/* <FormAdsSelect /> */}
      <ModalTemplate />

      <Button type="primary" onClick={onSelectTemplate}>Настроить шаблон</Button>
    </>
  );
};
