// import { sharitMediaApiV1 } from './../../../services/mainInfo/sharitMediaApiV1/index';
// type GetSigInRequest = {
//   user_id?: string;
//   expires_in?: string;
//   access_token?: string;
//   v?: string;
//   apiID: string;
// };

import { sharitMediaApiV1 } from '..';

// const user_id = '14619700';
// const expires_in = '86400';
// const v = '5.131';
// const access_token =
//   'vk1.a.lHu5lfbSjhHQh-SB1hJKicCj5t18vGc8AwrExBqK5oNzBQ7T5sXsqCIMapKaPvb1OMW4elh9P2V8T_NklNoymRBqt867CnMxMo802hVonxDj_z4TnDHRNqJlZuROyJi5H-o8N2_qBlKV0dPcOTgzp6vzetlUjMtxnT3QSJo06Mq8CShEi8Z4bJHYCYocQlvN';

// const useAuth = (response: any) => {
//   console.log('response', response);
// };

type VkTokenRequest = {
  code: string;
}

type VkTokenResponse = {
  access_token: string;
  expires_in: number;
  user_id: number;
}

const extendedApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getTokenVk: build.mutation<VkTokenResponse, VkTokenRequest>({
      query: body => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/getTokenVk`, method: 'POST', body }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTokenVkMutation } = extendedApi;
