import { lazy } from 'react';
import { AboutCompany } from '../../features/AboutCompany';

export const AboutCompanyPage = () => <AboutCompany />;

// export const AboutCompanyPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/AboutCompany'));
//     }),
// );
