import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetAccountsQuery } from '../../store/query/sharitMediaApiV1/accounts';
import { useGetClientsQuery } from '../../store/query/sharitMediaApiV1/clients';
import { aboutCompanySlice } from '../../store/slice/uiSlice';
import './index.scss';

export const AboutCompany = () => {
  const [form] = useForm();

  const { data } = useGetAccountsQuery({ account_id: '1900015353' });
  const { data: clientsData } = useGetClientsQuery({ account_id: '1900015353' });

  const { name, inn, ogrnip, bik, rs, corr, bank, urAddress, address } = useSelector(
    aboutCompanySlice.selectors.aboutCompanySelector,
  );

  useEffect(() => {
    form.setFields([
      {
        name: 'nameCompany',
        value: name,
      },
      {
        name: 'ogrnip',
        value: ogrnip,
      },
      {
        name: 'rsCompany',
        value: rs,
      },
      {
        name: 'nameBank',
        value: bank,
      },
      {
        name: 'innCompany',
        value: inn,
      },
      {
        name: 'bicCompany',
        value: bik,
      },
      {
        name: 'corCompany',
        value: corr,
      },
      {
        name: 'urAdressCompany',
        value: urAddress,
      },
      {
        name: 'mailAddressCompany',
        value: address,
      },
    ]);
  }, [name, inn, ogrnip, corr, bank, urAddress, address, bik, form, rs]);

  // useEffect(() => {
  //   console.log('accounts', data);
  //   console.log('clientsData', clientsData);
  // }, [data, clientsData]);

  return (
    <>
      <h3>О компании</h3>
      {data && (
        <>
          {/* <div className="">Аккаунт:</div>
          <div className="">Роль: {data[1]?.access_role}</div>
          <div className="">Аккаунт ID: {data[1]?.account_id}</div>
          <div className="">Аккаунт статус: {data[1]?.account_status}</div>
          <div className="">Тип: {data[1]?.account_type}</div>
          <div className="">Название: {data[1]?.account_name}</div> */}
        </>
      )}

      <Form form={form} name="contractsCreate" layout="vertical" className="company-profile__form">
        <div className="company-profile__left">
          <Form.Item name="nameCompany" label="Компания" required>
            <Input placeholder="Введите название компании" />
          </Form.Item>

          <Form.Item name="ogrnip" label="КПП" required>
            <Input placeholder="Введите ОГРНИП" type="number" />
          </Form.Item>

          <Form.Item name="rsCompany" label="Рассчётный счёт" required>
            <Input placeholder="Введите р/с компании" type="number" />
          </Form.Item>

          <Form.Item name="nameBank" label="Банк" required>
            <TextArea placeholder="Введите название банка" cols={1} rows={2} />
          </Form.Item>

          <Form.Item name="mailAddressCompany" label="Почтовый адрес" required>
            <TextArea placeholder="Введите почтовый адрес" cols={1} rows={2} />
          </Form.Item>
        </div>

        <div className="company-profile__right">
          <Form.Item name="innCompany" label="ИНН" required>
            <Input placeholder="Введите ИНН компании" type="number" />
          </Form.Item>

          <Form.Item name="bicCompany" label="БИК" required>
            <Input placeholder="Введите БИК паспорта" type="number" />
          </Form.Item>

          <Form.Item name="corCompany" label="Кор/счёт" required>
            <Input placeholder="Введите кор/счёт компании" type="number" />
          </Form.Item>

          <Form.Item name="urAdressCompany" label="Юр. адрес" required>
            <TextArea placeholder="Введите юридический адрес" cols={1} rows={2} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
