import { combineReducers } from '@reduxjs/toolkit';
import { reducer as pageOneReducer } from './pageOne';
import { reducer as pageTwoReducer } from './pageTwo';
import { reducer as pageThreeReducer } from './pageThree';
import { reducer as pageFourReducer } from './pageFour';
import { reducer as pageConstructorReducer } from './pageConstructor';
import {reducer as reportsDataReducer} from './reportsData';
import { reducer as reportAdsReducer} from './reportAds';

export * as pageOneSlice from './pageOne';
export * as pageTwoSlice from './pageTwo';
export * as pageThreeSlice from './pageThree';
export * as pageFourSlice from './pageFour';
export * as reportsDataSlice from './reportsData';
export * as pageConstructorSlice from './pageConstructor';
export * as reportAdsSlice from './reportAds';

const reportsReducer = combineReducers({
  pageOne: pageOneReducer,
  pageTwo: pageTwoReducer,
  pageThree: pageThreeReducer,
  pageFour: pageFourReducer,
  reportsData: reportsDataReducer,
  pageConstructor: pageConstructorReducer,
  reportAds: reportAdsReducer,
});

export default reportsReducer;
