import { useSelector } from 'react-redux';
import { Column } from '../Column';
import './index.scss';
import { columnsSlice } from '../../../store/slice/constructorSlice';

export const Row = ({ id }: { id: string }) => {
  const columns = useSelector(columnsSlice.selectors.columnsSelector);

  return (
    <div className="c-row" id={id}>
      {columns ? (
        columns?.map(el => {
          return <Column id={el?.id} key={el?.id} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};
