import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';

type TInitialValues = {
  name: string;
  inn: number;
  ogrnip: number;
  bik: string;
  rs: number;
  corr: number;
  bank: string;
  urAddress: string;
  address: string;
};

const initialState: TInitialValues = {
  name: 'ИП Гордеев А.О.',
  inn: 230217131388,
  bik: '044525593',
  ogrnip: 318774600455751,
  rs: 40802810601990001141,
  corr: 30101810200000000593,
  bank: 'АО "Альфа-Банк"',
  urAddress: 'г. Москва, Московский п, Московский г, Лаптева ул, 8, 2, 113',
  address: 'г. Москва, Филимонковское п., п. Марьино, дом 39, кв. 12',
};

export const aboutCompanySlice = createSlice({
  name: 'ui/aboutCompany',
  initialState,
  reducers: {
    name(state, { payload }: PayloadAction<string>) {
      return { ...state, name: payload };
    },
    inn(state, { payload }: PayloadAction<number>) {
      return { ...state, inn: payload };
    },
    ogrnip(state, { payload }: PayloadAction<number>) {
      return { ...state, kpp: payload };
    },
    bik(state, { payload }: PayloadAction<string>) {
      return { ...state, bik: payload };
    },
    corr(state, { payload }: PayloadAction<number>) {
      return { ...state, corr: payload };
    },
    bank(state, { payload }: PayloadAction<string>) {
      return { ...state, bank: payload };
    },
    urAddress(state, { payload }: PayloadAction<string>) {
      return { ...state, urAddress: payload };
    },
    address(state, { payload }: PayloadAction<string>) {
      return { ...state, address: payload };
    },
    reset: () => initialState,
  },
});

const aboutCompanyData = (state: RootState) => state.ui.aboutCompany;
const aboutCompanySelector = createSelector(aboutCompanyData, state => state);

export const selectors = {
  aboutCompanySelector,
};
export const actions = aboutCompanySlice.actions;
export const reducer = aboutCompanySlice.reducer;
