import { useEffect, useState } from 'react';
import { TGetReleaseResponse, useGetReleaseQuery } from '../../store/query/sharitMediaApiV1/releases';
import usePath from '../../shared/hooks/usePath';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import './index.scss';
import { useLazyDownloadByReleseIdQuery } from '../../store/query/sharitMediaApiV1/downloadRelease';
import { ColumnsType } from 'antd/es/table';
import { TStepThree } from '../../store/slice/uiSlice/steps';

export const ReleaseInfo = () => {
  const releaseId = usePath(2);
  const [dataSource, setDataSource] = useState<TGetReleaseResponse[]>([]);

  const { data } = useGetReleaseQuery(releaseId, {
    skip: !releaseId,
  });

  //   const [downloadTrigger, { data: downloadData }] = useLazyDownloadByReleseIdQuery();

  const onDownloadExcel = () => {
    const downloadUrl = `${process.env.REACT_APP_BASE_URL_API}/api/downloadExcelByReleaseId/${releaseId}`;
    const accessToken = localStorage.getItem('accessToken');

    fetch(downloadUrl, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Download failed');
        }
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `release-${releaseId}.xlsx`);
      })
      .catch(error => console.error('Download failed:', error));
  };

  const columns = [
    {
      title: 'Релиз ID',
      dataIndex: 'release_id',
      key: 'release_id',
      width: 140,
    },
    {
      title: 'ID заказа',
      dataIndex: 'order_id',
      key: 'order_id',
      width: 140,
    },
    {
      title: 'Название',
      dataIndex: 'name_release',
      key: 'name_release',
    },
    {
      title: 'Артист',
      dataIndex: 'musician',
      key: 'musician',
    },
    {
      title: 'Дата релиза',
      dataIndex: 'date_release',
      key: 'date_release',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_: any, record: TGetReleaseResponse) => {
        return new Date(record?.created_at).toLocaleDateString();
      },
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_: any, record: TGetReleaseResponse) => {
        return new Date(record?.updated_at).toLocaleDateString();
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Жанр',
      dataIndex: 'genre',
      key: 'genre',
    },
    {
      title: 'Треки',
      dataIndex: 'tracks',
      key: 'tracks',
      render: (_: any, record: TGetReleaseResponse) => {
        return record?.tracks?.map((track, i) => {
          return (
            <div key={track.track_id}>
              {track.track_name} -
              <Link to={track.audio_link} target="_blank" rel="noreferrer">
                {track.audio_link}
              </Link>
            </div>
          );
        });
      },
    },
    {
      title: 'Ссылка на обложку релиза',
      dataIndex: 'label_image',
      key: 'label_image',
      width: 200,
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          <Link to={record?.label_image} target="_blank" rel="noreferrer">
            {record?.label_image}
          </Link>
        );
      },
    },
    {
      title: 'Ссылка на трек',
      dataIndex: 'audio_link',
      key: 'audio_link',
      width: 200,
      render: (_: any, record: TGetReleaseResponse) => {
        return record?.tracks?.map(track => {
          return (
            <div key={track.track_name}>
              {track.track_name} -
              <a href={track.audio_link} target="_blank" rel="noreferrer">
                {track.audio_link}
              </a>
            </div>
          );
        });
      },
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Apple',
      dataIndex: 'apple',
      key: 'apple',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.apple} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.apple}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'Spotify',
      dataIndex: 'spotify',
      key: 'spotify',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.spotify} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.spotify}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'Youtube',
      dataIndex: 'youtube',
      key: 'youtube',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.youtube} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.youtube}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'Яндекс.Музыка',
      dataIndex: 'yamusic',
      key: 'yamusic',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.yamusic} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.yamusic}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'Deezer',
      dataIndex: 'deezer',
      key: 'deezer',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.deezer} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.deezer}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'ВК.Музыка',
      dataIndex: 'vkmusic',
      key: 'vkmusic',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.vkmusic} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.vkmusic}
            </Link>
          ) || '-'
        );
      },
    },
    {
      title: 'Сбер.Музыка',
      dataIndex: 'sbermusic',
      key: 'sbermusic',
      render: (_: any, record: TGetReleaseResponse) => {
        return (
          (
            <Link to={record?.releaseLinks?.sbermusic} target="_blank" rel="noreferrer">
              {record?.releaseLinks?.sbermusic}
            </Link>
          ) || '-'
        );
      },
    },
  ];

  const columnsTrack: ColumnsType<TStepThree> = [
    {
      title: 'Трек ID',
      dataIndex: 'track_id',
      key: 'track_id',
    },
    {
      title: 'Название трека',
      dataIndex: 'track_name',
      key: 'track_name',
    },
    {
      title: 'Артист',
      dataIndex: 'musicians',
      key: 'musicians',
    },
    {
      title: 'Copyright',
      dataIndex: 'copyright',
      key: 'copyright',
    },
    {
      title: 'Музыканты',
      dataIndex: 'musicians',
      key: 'musicians',
      render: (_: any, record: TStepThree) => {
        return record.track_musicians?.join(', ');
      },
    },
    {
      title: 'Авторы музыки',
      dataIndex: 'authors_music',
      key: 'authors_music',
      render: (_: any, record: TStepThree) => {
        return record.authors_music?.join(', ');
      },
    },
    {
      title: 'Авторы текста',
      dataIndex: 'authors_words',
      key: 'authors_words',
      render: (_: any, record: TStepThree) => {
        return record.authors_words?.join(', ');
      },
    },
    {
      title: 'Язык текста',
      dataIndex: 'text_language',
      key: 'text_language',
    },
    {
      title: 'Время на TikTok',
      dataIndex: 'time_tiktok',
      key: 'time_tiktok',
    },
    {
      title: 'Isrc',
      dataIndex: 'isrc',
      key: 'isrc',
    },
    {
      title: 'Upc',
      dataIndex: 'upc',
      key: 'upc',
    },
    {
      title: 'Наличие мата',
      dataIndex: 'fucking',
      key: 'fucking',
    },
    {
      title: 'Ссылка на трек',
      dataIndex: 'audio_link',
      key: 'audio_link',
      render: (_: any, record: TStepThree) => {
        return (
          <Link to={record.audio_link} target="_blank" rel="noreferrer">
            {record.audio_link}
          </Link>
        );
      },
    },
    {
      title: 'Текст трека',
      dataIndex: 'audio_text',
      key: 'audio_text',
    },
  ];

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  useEffect(() => {
    if (data) {
      setDataSource([data]);
    }
  }, [data]);

  return (
    <>
      {data && (
        <>
          <Button onClick={onDownloadExcel} type="primary" className="release-btn">
            Скачать релиз
          </Button>

          <Table
            columns={columns}
            key={dataSource[0]?.release_id}
            dataSource={dataSource}
            bordered
            scroll={{ x: 'max-content' }}
          />

          <Table
            columns={columnsTrack}
            key={Math.random().toString(36).substring(2, 10)}
            dataSource={dataSource[0]?.tracks}
            bordered
            scroll={{ x: 'max-content' }}
          />
        </>
      )}
    </>
  );
};
