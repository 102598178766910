import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { clientsApi } from '../../query/sharitMediaApiV1/clients';

export type TClient = {
  all_limit: string;
  day_limit: string;
  id: number;
  name: string;
};

const initialState = [{}];

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        clientsApi.endpoints.getClients.matchFulfilled,
        (state, { payload }: PayloadAction<TClient[]>) => payload,
      )
      .addMatcher(clientsApi.endpoints.getClients.matchRejected, () => initialState);
  },
});

const clients = (state: RootState) => state.clients;
const getClients = createSelector(clients, state => state);

export const selectors = { getClients };
export const actions = clientsSlice.actions;
export const clientsReducer = clientsSlice.reducer;
