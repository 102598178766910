import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

type TInitialValuesReports = {
    title: string;
    labelImage: string;
    budget: number;
    ctr: number;
    views: number;
    clicks: number;
    likes: number;
    comments: number;
    forwards: number;
    viewsTarget: number;
    screenshotLabel: string;
    screenshotTwoLabel: string;
    nativeOne: string;
    nativeTwo: string;

    customTitleOne: string;
    cabinetScreen: string;
    customTitleTwo: string;
    dynamicScreen: string;
    customTitleThree: string;
    demographyScreen: string;

    sowingScreen: string;
    budgetSowing: number;
    coverage: number;
    likesSowing: number;
    forwardsSowing: number;

    playlistScreen: string;
    budgetPlaylist: number;
    auditions: number;
    conclusions: string;
};

const initialState: TInitialValuesReports = {
    title: 'Название отчёта',
    budget: 0,
    ctr: 0,
    views: 0,
    clicks: 0,
    likes: 0,
    comments: 0,
    forwards: 0,
    viewsTarget: 0,
    labelImage: '/image/notImage.jpg',
    screenshotLabel: '/image/notImage.jpg',
    screenshotTwoLabel: '/image/notImage.jpg',
    nativeOne:'/image/notImage.jpg',
    nativeTwo:'/image/notImage.jpg',

    customTitleOne: 'Название первого скриншота',
    cabinetScreen: '/image/notImage.jpg',
    customTitleTwo: 'Название второго скриншота',
    dynamicScreen: '/image/notImage.jpg',
    customTitleThree: 'Название третьего скриншота',
    demographyScreen: '/image/notImage.jpg',

    sowingScreen: '/image/notImage.jpg',
    budgetSowing: 0,
    coverage: 0,
    likesSowing: 0,
    forwardsSowing: 0,

    playlistScreen: '/image/notImage.jpg',
    budgetPlaylist: 0,
    auditions: 0,
    conclusions: '',
};

export const reportsDataSlice = createSlice({
  name: 'ui/reportsData',
  initialState,
  reducers: {
    title(state, { payload }: PayloadAction<string>) {
        return { ...state, title: payload };
    },
    labelImage(state, { payload }: PayloadAction<string>) {
        return { ...state, labelImage: payload };
    },
    budget(state, { payload }: PayloadAction<number>) {
        return { ...state, budget: payload };
    },
    ctr(state, { payload }: PayloadAction<number>) {
        return { ...state, ctr: payload };
    },
    views(state, { payload }: PayloadAction<number>) {
        return { ...state, views: payload };
    },
    clicks(state, { payload }: PayloadAction<number>) {
        return { ...state, clicks: payload };
    },
    likes(state, { payload }: PayloadAction<number>) {
        return { ...state, likes: payload };
    },
    forwards(state, { payload }: PayloadAction<number>) {
        return { ...state, forwards: payload };
    },
    viewsTarget(state, { payload }: PayloadAction<number>) {
        return { ...state, viewsTarget: payload };
    },
    comments(state, { payload }: PayloadAction<number>) {
        return { ...state, comments: payload };
    },
    screenshotLabel(state, { payload }: PayloadAction<string>) {
        return { ...state, screenshotLabel: payload };
    },
    screenshotTwoLabel(state, { payload }: PayloadAction<string>) {
        return { ...state, screenshotTwoLabel: payload };
    },
    nativeOne(state, { payload }: PayloadAction<string>) {
        return { ...state, nativeOne: payload };
    },
    nativeTwo(state, { payload }: PayloadAction<string>) {
        return { ...state, nativeTwo: payload };
    },
    
    customTitleOne(state, { payload }: PayloadAction<string>) {
        return { ...state, customTitleOne: payload };
    },
    cabinetScreen(state, { payload }: PayloadAction<string>) {
        return { ...state, cabinetScreen: payload };
    },
    customTitleTwo(state, { payload }: PayloadAction<string>) {
        return { ...state, customTitleTwo: payload };
    },
    dynamicScreen(state, { payload }: PayloadAction<string>) {
        return { ...state, dynamicScreen: payload };
    },
    customTitleThree(state, { payload }: PayloadAction<string>) {
        return { ...state, customTitleThree: payload };
    },
    demographyScreen(state, { payload }: PayloadAction<string>) {
        return { ...state, demographyScreen: payload };
    },
    
    sowingScreen(state, { payload }: PayloadAction<string>) {
        return { ...state, sowingScreen: payload };
    },
    budgetSowing(state, { payload }: PayloadAction<number>) {
        return { ...state, budgetSowing: payload };
    },
    coverage(state, { payload }: PayloadAction<number>) {
        return { ...state, coverage: payload };
    },
    likesSowing(state, { payload }: PayloadAction<number>) {
        return { ...state, likesSowing: payload };
    },
    forwardsSowing(state, { payload }: PayloadAction<number>) {
        return { ...state, forwardsSowing: payload };
    },
    
    playlistScreen(state, { payload }: PayloadAction<string>) {
        return { ...state, playlistScreen: payload };
    },
    budgetPlaylist(state, { payload }: PayloadAction<number>) {
        return { ...state, budgetPlaylist: payload };
    },
    auditions(state, { payload }: PayloadAction<number>) {
        return { ...state, auditions: payload };
    },
    conclusions(state, { payload }: PayloadAction<string>) {
        return { ...state, conclusions: payload };
    },
    reset: () => initialState,
  },
});

const reportData = (state: RootState) => state.ui.reports.reportsData;

export const selectors = {
    reportData,
};
export const actions = reportsDataSlice.actions;
export const reducer = reportsDataSlice.reducer;
