export type TAccount = {
  access_role: string;
  account_id: number;
  account_status: number;
  account_type: string;
  account_name: string;
  can_view_budget: boolean;
  ad_network_allowed_potentially: boolean;
};

export type TClient = {
  all_limit: string;
  day_limit: string;
  id: number;
  name: string;
};

export type MenuItem = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  path?: MenuKeyLink;
  role?: string[];
  permissions?: Permissions[];
};

export type MenuKeyOption = { selectedKeys?: MenuKeys[]; openKeys?: MenuKeys[] };
export type MenuKeyLink =
  | Paths.login
  | Paths.main
  | Paths.reports
  | Paths.aboutCompany
  | Paths.campaigns
  | Paths.users
  | Paths.user
  | Paths.chats
  | Paths.contracts
  | Paths.services
  | Paths.releases;

export type MenuKeysMap = Record<MenuKeyLink, MenuKeyOption>;

export enum Paths {
  /** auth */
  login = '/login',
  auth = '/auth',
  main = '/main',
  reports = '/reports',
  aboutCompany = '/aboutCompany',
  campaigns = '/campaigns',
  users = '/users',
  user = '/users/:id',
  chats = '/chats',
  contracts = '/contracts',
  contract = '/contract/:id',
  services = '/services',
  releases = '/releases',
}

export enum MenuKeys {
  home = '/main',
  login = '/login',
  main = '/main',
  reports = '/reports',
  aboutCompany = '/aboutCompany',
  campaigns = '/campaigns',
  users = '/users',
  user = '/users/:id',
  chats = '/chats',
  contracts = '/contracts',
  contract = '/contract/:id',
  services = '/services',
  releases = '/releases',
}

export type TCampaigns = {
  id?: number;
  type?: string;
  name?: string;
  status?: number;
  day_limit?: string;
  all_limit?: string;
  start_time?: number | string;
  stop_time?: number;
  create_time?: number;
  update_time?: number;
  views_limit?: number;
};

export type TCampaignInfo = {
  id: number;
  campaign_id: number;
  status: number;
  approved: number;
  create_time: number;
  update_time: number;
  goal_type: number;
  cost_type: number;
  day_limit: string;
  all_limit: string;
  start_time: number;
  stop_time: number;
  category1_id: number;
  category2_id: number;
  age_restriction: number;
  name: string;
  ad_platform?: string;
  publisher_platforms?: string;
  ad_format?: number;
};

export type TMessage = {
  id: string;
  fio: string;
  text: string;
};

export type TMessages = TMessage[];

export type TContracts = {
  id: string;
  created: string;
  musician: string;
  contractNumber: string;
  status: string;
  actions: string;
  period: string;
}[];

export type TProfileInfo = {
  id: number;
  name: string;
  surname: string;
  middlename: string;
  email: string;
  activated: string;
  role: number;
  avatar?: string;
};

export enum Roles {
  'superAdmin' = 'superAdmin',
  'admin' = 'admin',
  'manager' = 'manager',
  'artist' = 'artist',
}

export type Chars = {
  id: string;
  title: string;
  data: number;
  icon: JSX.Element;
  visible: boolean;
};
