import { sharitMediaApiV1 } from '..';

type TWallGetByIdRequest = {
  posts: string;
};

type TWallGetByIdResponse = {
  attachments: [
    {
      type: string;
      photo: {
        access_key: string;
        album_id: number;
        date: number;
        has_tags: false;
        id: number;
        owner_id: number;
        sizes: { height: number; type: string; width: number; url: string }[];
        text: string;
        user_id: number;
      };
    },
  ];
  carousel_offset: number;
  comments: { count: number };
  date: number;
  donut: { is_donut: false };
  from_id: number;
  hash: string;
  id: number;
  is_favorite: false;
  likes: { can_like: number; count: number; user_likes: number };
  marked_as_ads: number;
  owner_id: number;
  post_type: string;
  reposts: { count: number };
  short_text_rate: number;
  text: string;
}[];

export const wallByIdApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getWallById: build.query<TWallGetByIdResponse, TWallGetByIdRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/wall/getById`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetWallByIdQuery } = wallByIdApi;
