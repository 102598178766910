import { lazy } from 'react';
import { Reports } from '../../features/Reports';

export const ReportsPage = () => <Reports />;

// export const ReportsPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Reports'));
//     }),
// );
