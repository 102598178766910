import { sharitMediaApiV1 } from '..';
import { TProfileInfo } from '../../../../shared/lib/types';

export const myInfoApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    myInfo: build.query<TProfileInfo, string>({
      query: id => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/user/myInfo/${id}`, method: 'GET' }),
      providesTags: ['myInfo'],
    }),
  }),
  overrideExisting: false,
});

export const { useMyInfoQuery, useLazyMyInfoQuery } = myInfoApi;
