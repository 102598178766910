import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type FormData = {
  email: string;
  password: string;
};

const urlRedirect = `${process.env.REACT_APP_BASE_URL}/campaigns`;
const clientId = '51496320';
const scope = 'ads,offline';

export const AuthVk = () => {
  const navigate = useNavigate();
  const path = useLocation();

  const onFinish = (values: FormData) => {
    const urlAuth = `https://oauth.vk.com/authorize?client_id=${clientId}&redirect_uri=${urlRedirect}&scope=${scope}&display=page&response_type=code&v=5.131`;
  };

  useEffect(() => {
    const paths = path.search.split('=');
    const codeAuth = paths[1];
    console.log(codeAuth);
  }, [path]);

  return (
    <div className="auth-vk">
      <a
        href={`https://oauth.vk.com/authorize?client_id=${clientId}&redirect_uri=${urlRedirect}&scope=${scope}&display=page&response_type=code&v=5.131`}
      >
        Авторизоваться ВКонтакте
      </a>
      {/* <Form name="basic" layout="vertical" onFinish={onFinish} className="formAuth">
            <h2 className="auth__title">Вход во ВКонтакте</h2>
    
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Введите email' }]}>
                <Input placeholder="Введите email" />
            </Form.Item>
    
            <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
                <Input.Password placeholder="Введите пароль" />
            </Form.Item>
    
            <div className="signIn">
                <Button type="primary" htmlType="submit">
                    Войти
                </Button>
            </div>
            </Form> */}
    </div>
  );
};
