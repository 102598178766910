import { sharitMediaApiV1 } from '..';

type TGetStatisticsRequest = {
  account_id: number;
  ids_type: string;
  period: string;
  date_from: string;
  date_to: string;
  ids: number;
};

export type TGetStatisticsResponse = {
  id: number;
  type: string;
  stats: [
    {
      clicks: number;
      ctr: string;
      effective_cost_per_click: string;
      effective_cost_per_mille: string;
      effective_cpf: string;
      impressions: number;
      join_rate: number;
      month?: string;
      day?: string;
      year?: string;
      week?: string;
      reach: number;
      spent: string;
      uniq_views_count: number;
    },
  ];
}[];

export const statisticsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getStatistics: build.query<TGetStatisticsResponse, TGetStatisticsRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getStatistics`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetStatisticsQuery, useLazyGetStatisticsQuery } = statisticsApi;
