import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TOptions, TWidget } from '../../../../features/Constructor/Types';

type TInitialValuesWidgets = {
  id: string;
  idColumn: string;
  name: string;
  type: string;
  order: number;
  options: TOptions;
}[];

const initialState: TInitialValuesWidgets = [{
  id: 'w-1',
  idColumn: 'c-1',
  name: 'widget',
  type: 'widget',
  order: 1,
  options: {
    displayFlex: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    width: 100,
    height: 160,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
}];

export const widgetsSlice = createSlice({
  name: 'constructor/widgetsSlice',
  initialState,
  reducers: {
    widgets(state, { payload }: PayloadAction<TWidget[]>) {
      return { ...state, widgets: payload };
    },
    reset: () => initialState,
  },
});

const widgets = (state: RootState) => state.constructorReport.widgets;
const widgetsSelector = createSelector(widgets, state => state);

export const selectors = {
  widgetsSelector,
};
export const actions = widgetsSlice.actions;
export const reducer = widgetsSlice.reducer;
