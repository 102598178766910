import { lazy } from 'react';
import { Users } from '../../features/Users';

export const UsersPage = () => <Users />;

// export const UsersPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Users'));
//     }),
// );
