import React from 'react';

export const ClicksIcon = () => {
  return (
    <svg
      className="characteristics__svg"
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      width="28"
      viewBox="0 0 48 48"
      fill="#8f57c4"
    >
      <path d="M15 30.65 20.45 23h10.9L15 10.15ZM28 43.8l-7.4-15.85L12 40V4l28 22H25.25l7.25 15.7ZM20.45 23Z" />
    </svg>
  );
};
