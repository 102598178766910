import { lazy } from 'react';
import { CampaignInfo } from '../../../features/CampaignInfo';

export const CampaignInfoPage = () => <CampaignInfo />;

// export const CampaignInfoPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../../features/CampaignInfo'));
//     }),
// );
