import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

type TInitialValuesReports = {
  playlistScreen: string;
  budgetPlaylist: number;
  auditions: number;
  conclusions: string;
};

const initialState: TInitialValuesReports = {
  playlistScreen: '/image/notImage.jpg',
  budgetPlaylist: 0,
  auditions: 0,
  conclusions: '',
};

export const pageFourSlice = createSlice({
  name: 'ui/reports/pageFour',
  initialState,
  reducers: {
    playlistScreen(state, { payload }: PayloadAction<string>) {
      return { ...state, playlistScreen: payload };
    },
    budgetPlaylist(state, { payload }: PayloadAction<number>) {
      return { ...state, budgetPlaylist: payload };
    },
    auditions(state, { payload }: PayloadAction<number>) {
      return { ...state, auditions: payload };
    },
    pageThree(state, { payload }: PayloadAction<TInitialValuesReports>) {
      return {
        ...state,
        playlistScreen: payload.playlistScreen,
        budgetPlaylist: payload.budgetPlaylist,
        auditions: payload.auditions,
      };
    },
    conclusions(state, { payload }: PayloadAction<string>) {
      return { ...state, conclusions: payload };
    },
    reset: () => initialState,
  },
});

const pageFourData = (state: RootState) => state.ui.reports.pageFour;
const pageFourSelector = createSelector(pageFourData, state => state);

export const selectors = {
  pageFourSelector,
};
export const actions = pageFourSlice.actions;
export const reducer = pageFourSlice.reducer;
