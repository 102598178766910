import { Input } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Logo } from '../../../../shared/ui/icons/logo';
import { reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import { UploadImgWrap } from '../../UploadImgWrap';
import './index.scss';

const ReportPageTwo = () => {
  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);
  const { cabinetScreen, demographyScreen, dynamicScreen, customTitleOne, customTitleTwo, customTitleThree } =
    allDataReports;

  const [reportTitle, setReportTitle] = useState<string>(customTitleOne);
  const [reportTitle2, setReportTitle2] = useState<string>(customTitleTwo);
  const [reportTitle3, setReportTitle3] = useState<string>(customTitleThree);

  const [fileImageBase64, setFileImageBase64] = useState<string>(cabinetScreen);
  const [fileImage2Base64, setFileImage2Base64] = useState<string>(dynamicScreen);
  const [fileImage3Base64, setFileImage3Base64] = useState<string>(demographyScreen);

  return (
    <>
      <div className="a4">
        <img src="/image/line.png" alt="line" className="a4__figure" />
        <div className="header">
          <p className="header__company">Sharit media</p>
          <Logo />
        </div>

        <section className="advertising">
          <Input
            className="screenshots__title"
            value={reportTitle}
            onInput={e => setReportTitle(e.currentTarget.value)}
          />

          <div className="screenshots__wrap">
            <UploadImgWrap fileImageName="cabinetScreen" file={fileImageBase64} setFile={setFileImageBase64} />
          </div>
        </section>

        <section className="stream">
          <Input
            className="screenshots__title"
            value={reportTitle2}
            onInput={e => setReportTitle2(e.currentTarget.value)}
          />

          <div className="screenshots__wrap">
            <UploadImgWrap fileImageName="demographyScreen" file={fileImage2Base64} setFile={setFileImage2Base64} />
          </div>
        </section>

        <section className="demography">
          <Input
            className="screenshots__title"
            value={reportTitle3}
            onInput={e => setReportTitle3(e.currentTarget.value)}
          />

          <div className="screenshots__wrap">
            <UploadImgWrap fileImageName="dynamicScreen" file={fileImage3Base64} setFile={setFileImage3Base64} />
          </div>
        </section>

        <div className="footer">
          <img src="/image/logo.JPG" alt="logo" className="footer__logo" />
        </div>
      </div>
    </>
  );
};

export default ReportPageTwo;
