import { lazy } from 'react';
import { Auth } from '../../features/Auth';

export const AuthPage = () => <Auth />;

// export const AuthPageAsync = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../../features/Auth/index'));
//     }),
// );
