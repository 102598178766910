import { Button, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getErrorData } from '../../shared/helpers';
import { Paths } from '../../shared/lib/constants/paths';
import { AuthLoginPayload, useLoginMutation } from '../../store/query/sharitMediaApiV1/auth';
import { useDispatch, useSelector } from 'react-redux';
import { CredentialsSlice } from '../../store/slice';
import { credentialsSlice } from '../../store/slice/credentialsSlice';
import { useLazyMyInfoQuery } from '../../store/query/sharitMediaApiV1/myInfo';
import './index.scss';

export const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activated } = useParams();

  // const { accessToken, refreshToken } = useSelector(CredentialsSlice.selectors.getIsAuth);
  const { isAuth } = useSelector(CredentialsSlice.selectors.getIsAuth);

  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const userId = localStorage.getItem('id');

  const [getMyInfoTrigger, myData] = useLazyMyInfoQuery();
  const [loginTrigger] = useLoginMutation();

  useEffect(() => {
    if (userId) {
      getMyInfoTrigger(userId);
    }
  }, [userId, getMyInfoTrigger]);

  const onFinish = async (payload: AuthLoginPayload) => {
    try {
      const response = await loginTrigger(payload).unwrap();

      if (response?.accessToken) {
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('role', response.user.role.toString());
        localStorage.setItem('id', response.user.id.toString());

        dispatch(credentialsSlice.actions.setCredentials(response));
        dispatch(credentialsSlice.actions.setIsAuth(true));
      } else {
        navigate(Paths.auth);
      }
    } catch (e) {
      const responseErr = getErrorData(e);
      if (responseErr) notification.error({ message: responseErr.message });
    }
  };

  useEffect(() => {
    if (accessToken && refreshToken && myData?.data) {
      dispatch(credentialsSlice.actions.setCredentials({ accessToken, refreshToken, user: myData.data }));
      dispatch(credentialsSlice.actions.setIsAuth(true));
    }
  }, [accessToken, refreshToken, myData, dispatch]);

  useEffect(() => {
    if (!accessToken) {
      navigate(Paths.auth);
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    if (accessToken) {
      const role = localStorage.getItem('role');
      const id = localStorage.getItem('id');

      if (role && Number(role) === 4 && id) {
        navigate(`/user-service/${id}/profile`);
      } else {
        navigate(Paths.campaigns);
      }
    } else {
      navigate(Paths.auth);
    }
  }, [navigate, accessToken]);

  useEffect(() => {
    if (activated && activated === 'true') {
      notification.success({
        message: 'Email подтверждён успешно',
      });
    }
  }, [activated]);

  // useEffect(() => () => reset(), [reset]);

  return (
    <div className="auth">
      <Form name="basic" layout="vertical" onFinish={onFinish} className="formAuth">
        <h2 className="auth__title">Вход в личный кабинет</h2>

        <Form.Item name="email" rules={[{ required: true, message: 'Введите email' }]}>
          <Input className="input-dark" placeholder="Введите email" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
          <Input.Password className="input-dark" placeholder="Введите пароль" />
        </Form.Item>

        <div className="authIn">
          <Button type="primary" htmlType="submit">
            Войти
          </Button>

          <Link to={Paths.registration} className="signIn">
            Регистрация
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default Auth;
