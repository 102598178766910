import UniversalCookie from 'universal-cookie';

class Cookies extends UniversalCookie {
  setCredentials = ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
    this.set('accessToken', accessToken, { sameSite: true });
    this.set('refreshToken', refreshToken, { sameSite: true });
  };

  setRefreshToken = (refreshToken: string) => {
    this.set('refreshToken', refreshToken, { sameSite: true });
  }

  getToken = (tokenName: string) => this.get(tokenName);

  removeCredentials = () => {
    this.remove('accessToken');
    this.remove('refreshToken');
    this.remove('id');
  };

  setTokenVkAndId = (access_token: string, user_id: string) => {
    this.set('accessTokenVk', access_token, {sameSite: true})
    this.set('userVkId', user_id, {sameSite: true})
  }

  getUserVkId = () => this.get("userVkId");

  removeTokenVkAndId = () => {
    this.remove('accessTokenVk');
    this.remove('userVkId');
  };

  setMyId = (id: number) => {
    this.set('id', id);
  };

  getMyId = (id: string) => this.get(id);
}

export const clientCookies = new Cookies();
