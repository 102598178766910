import { Button, Form, Select } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getErrorData, sortingDate } from '../../shared/helpers';
import { useNotifications } from '../../shared/hooks/use-notifications';
import { clientCookies } from '../../shared/lib/cookies';
import { TAccount, TCampaigns } from '../../shared/lib/types';
import { useLazyGetAccountsQuery } from '../../store/query/sharitMediaApiV1/accounts';
import { useLazyGetCampaignsQuery } from '../../store/query/sharitMediaApiV1/campaigns';
import { useLazyGetClientsQuery } from '../../store/query/sharitMediaApiV1/clients';
import { useGetTokenVkMutation } from '../../store/query/sharitMediaApiV1/getTokenVk';
import { AccountsSlice, CredentialsSlice } from '../../store/slice';
import { credentialsActions } from '../../store/slice/credentialsSlice';
import { currentAdsSlice } from '../../store/slice/uiSlice';
import { AuthVk } from '../AuthVk';
import './index.scss';

const { Option } = Select;

export const Campaigns = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const dispatch = useDispatch();

  const columns: ColumnsType<TCampaigns> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 140,
    },
    {
      title: 'Время создания',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (_, record) => {
        if (record?.create_time) {
          const day = moment.unix(record.create_time);
          return <span>{day.format('YYYY-MM-DD HH:ss')}</span>;
        }
      },
      sorter: (a, b) => sortingDate(String(a.create_time), String(b.create_time)),
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Время начала',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_, record) => {
        if (record.start_time === 0) {
          return <span>Не задано</span>;
        } else {
          return record.start_time;
        }
      },
    },
    {
      title: 'Время остановки',
      dataIndex: 'stop_time',
      key: 'stop_time',
      render: (_, record) => {
        if (record.stop_time === 0) {
          return <span>Не задано</span>;
        } else {
          return record.stop_time;
        }
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        if (record.status === 0) {
          return <span>Остановлена</span>;
        } else if (record.status === 1) {
          return <span>Запущена</span>;
        } else if (record.status === 2) {
          return <span>Удалена</span>;
        } else return record.status;
      },
    },
    {
      title: 'Общий лимит',
      dataIndex: 'all_limit',
      key: 'all_limit',
      render: (_, record) => {
        if (record.all_limit === '0') {
          return <span>Не задан</span>;
        } else {
          return record.all_limit;
        }
      },
    },
    {
      title: 'Дневной лимит',
      dataIndex: 'day_limit',
      key: 'day_limit',
      render: (_, record) => {
        if (record.day_limit === '0') {
          return <span>Не задан</span>;
        } else {
          return record.day_limit;
        }
      },
    },
    // {
    //   title: 'Лимит показов',
    //   dataIndex: 'views_limit',
    //   key: 'views_limit',
    //   render: (_, record) => {
    //     if (record.views_limit === 0) {
    //       return <span>Не задан</span>;
    //     } else if (record.views_limit === 1)  {
    //       return <span>Показы идут</span>;
    //     } else {
    //       return record.views_limit;
    //     }
    //   },
    // },
  ];
  const userVkId = useSelector(CredentialsSlice.selectors.getMyVkId);
  const myAllAccounts = useSelector(AccountsSlice.selectors.getAccounts);

  const [currentCampaignData, setCurrentCampaignsData] = useState<TCampaigns[]>();

  const [accountId, setAccountId] = useState<string>('0');
  const [currentAccounts, setCurretAccounts] = useState<TAccount[]>([]);
  const [clientId, setClientId] = useState<string>();
  const [myCabinetId, setMyCabinetId] = useState<number | null>(userVkId);
  const [selectCampaign, setSelectCampaign] = useState<TCampaigns[]>([]);

  const accessTokenVk = useSelector(CredentialsSlice.selectors.getMyAccessToken);

  const [getCompanyTrigger, campaigns] = useLazyGetCampaignsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: campaignsData } = campaigns;

  const [getTokenVkTrigger, result] = useGetTokenVkMutation({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { isSuccess, isError, error, data, reset } = result;

  const [getAccountsTrigger] = useLazyGetAccountsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });

  const [getClientsTrigger, allClients] = useLazyGetClientsQuery({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { data: dataClients } = allClients;

  const onSelectAccount = (value: string) => {
    setAccountId(value);
    dispatch(currentAdsSlice.actions.currentAccount(Number(value)));
    setClientId(undefined);
  };

  const onSelectClient = (value: string) => {
    setClientId(value);
    dispatch(currentAdsSlice.actions.currentClient(Number(value)));
  };

  const toMoreInfo = () => {
    if (selectCampaign[0]?.id) {
      dispatch(currentAdsSlice.actions.currentCampany(selectCampaign[0]?.id));
      navigate(`/campaigns/${selectCampaign[0]?.id}`);
    }
  };

  useEffect(() => {
    if (accountId !== '0') {
      getClientsTrigger({
        account_id: accountId,
      });
    }
  }, [accountId, getClientsTrigger]);

  useNotifications(isSuccess, isError, 'Вы успешно авторизовались!', error?.message, reset);

  useEffect(() => {
    if (myAllAccounts && myAllAccounts?.length > 0) {
      const currentAccounts = myAllAccounts.filter(el => el.account_type === 'agency');
      setCurretAccounts(currentAccounts);
    }
  }, [myAllAccounts]);

  useEffect(() => {
    if (accountId !== '0' && accessTokenVk && clientId) {
      getCompanyTrigger({
        account_id: String(accountId),
        client_id: clientId,
        include_deleted: '1',
        access_token: accessTokenVk,
      });
    }
  }, [accountId, accessTokenVk, clientId, getCompanyTrigger]);

  useEffect(() => {
    if (data?.access_token && data?.user_id) {
      clientCookies.setTokenVkAndId(data.access_token, String(data.user_id));
      dispatch(credentialsActions.setUserVk({ access_token: data.access_token, user_id: data.user_id }));
      setMyCabinetId(data.user_id);
    }
  }, [data, dispatch]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TCampaigns[]) => {
      setSelectCampaign(selectedRows);
    },
  };

  useEffect(() => {
    if (campaignsData && campaignsData[0]?.id) {
      const newData = campaignsData?.map(el => {
        return { ...el, key: el.id };
      });

      setCurrentCampaignsData(newData);
    }
  }, [campaignsData, setCurrentCampaignsData]);

  useEffect(() => {
    const codeAuth = path.search.split('=')[1];

    if (codeAuth) {
      getTokenVkTrigger({ code: codeAuth });
    }
  }, [path, getTokenVkTrigger]);

  useEffect(() => {
    const accessTokenVk = clientCookies.getToken('accessTokenVk');
    const userVkId = clientCookies.getUserVkId();

    if (accessTokenVk && userVkId) {
      dispatch(credentialsActions.setUserVk({ access_token: accessTokenVk, user_id: userVkId }));
    }
  }, [accessTokenVk, dispatch]);

  useEffect(() => {
    if (userVkId) {
      getAccountsTrigger({
        account_id: String(userVkId),
        access_token: accessTokenVk,
      });
    }
  }, [accessTokenVk, userVkId, getAccountsTrigger]);

  useEffect(() => {
    setMyCabinetId(userVkId);
  }, [userVkId, setMyCabinetId, myCabinetId]);

  return (
    <>
      <h3 className="">Страница кампаний</h3>

      {myCabinetId ? (
        <>
          {currentAccounts.length > 0 && (
            <Form.Item label="Выберите рекламный кабинет" className="select-account__wrap">
              <Select onSelect={onSelectAccount} placeholder="Выберите кабинет">
                {currentAccounts?.map(el => {
                  return (
                    <Option key={el?.account_id} value={el?.account_id}>
                      {el?.account_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {dataClients && dataClients.length > 0 && (
            <Form.Item label="Выберите клиента" className="select-account__wrap">
              <Select onSelect={onSelectClient} placeholder="Выберите клиента" value={clientId}>
                {dataClients?.map(el => {
                  return (
                    <Option key={el?.id} value={el?.id}>
                      {el?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <div className="btns-campaigns">
            {selectCampaign?.length > 0 && (
              <Button className="" type="primary" onClick={toMoreInfo}>
                Подробнее
              </Button>
            )}
          </div>

          <Table
            columns={columns}
            dataSource={currentCampaignData}
            scroll={{ x: true }}
            bordered
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
          />
        </>
      ) : (
        <AuthVk />
      )}
    </>
  );
};
