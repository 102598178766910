import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TConstructorTemplate, TOptions } from '../../../../features/Constructor/Types';

type TInitialValuesTemplate = {
  id: string;
  name: string;
  type: string;
  description: string;
  options: TOptions;
};

const initialState: TInitialValuesTemplate = {
  id: 'template-1',
  name: 'template-1',
  type: 'template',
  description: 'Описание шаблона 1',
  options: {
    displayFlex: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    width: 100,
    height: 160,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
};

export const templatesSlice = createSlice({
  name: 'constructor/templateSlice',
  initialState,
  reducers: {
    template(state, { payload }: PayloadAction<TConstructorTemplate>) {
      return { ...state, template: payload };
    },
    reset: () => initialState,
  },
});

const templates = (state: RootState) => state.constructorReport.template;
const templatesSelector = createSelector(templates, state => state);

export const selectors = {
  templatesSelector,
};
export const actions = templatesSlice.actions;
export const reducer = templatesSlice.reducer;
