import { DatePicker, Form, Input, Radio, RadioChangeEvent } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import './index.scss';
import { ContractsOne } from '../ContractsTemplate/ContractsOne';

const ContractsCreate = () => {
  const [typeContract, setTypeContract] = useState<string>('individual');

  const onChange = (e: RadioChangeEvent) => {
    setTypeContract(e.target.value);
  };

  return (
    <div className="contracts-create__wrap">
      <div className="contracts-create__control">
        <Radio.Group onChange={onChange} value={typeContract} className="contracts-create__type">
          <Radio value="individual">Физ. лицо</Radio>
          <Radio value="company">Компания</Radio>
        </Radio.Group>

        <Form name="contractsCreate" layout="vertical" className="contracts-create__form">
          <Form.Item name="contractNumber" label="Номер договора" required>
            <Input placeholder="Введите номер договора" />
          </Form.Item>

          <Form.Item name="startedAt" label="Дата начала действия договора" required>
            <DatePicker placeholder="Выберите дату" className="contracts-create__date" />
          </Form.Item>

          <Form.Item name="endedAt" label="Дата окончания действия договора">
            <DatePicker placeholder="Выберите дату" className="contracts-create__date" />
          </Form.Item>

          <Form.Item name="city" label="Город" required>
            <Input placeholder="Введите город" />
          </Form.Item>

          <Form.Item name="prise" label="Сумма, руб." required>
            <Input placeholder="Введите сумму за услуги" type="number" />
          </Form.Item>

          {typeContract === 'individual' ? (
            <>
              <div className="contracts-create__individual">Пасспортные данные</div>

              <Form.Item name="name" label="ФИО" required>
                <Input placeholder="Введите ФИО" />
              </Form.Item>

              <Form.Item name="passportSeries" label="Серия" required>
                <Input placeholder="Введите серию паспорта" type="number" />
              </Form.Item>

              <Form.Item name="passportNumber" label="Номер" required>
                <Input placeholder="Введите номер паспорта" type="number" />
              </Form.Item>

              <Form.Item name="passportIssuedDate" label="Выдан (дата)" required>
                <DatePicker placeholder="Выберите дату выдачи" className="contracts-create__date" />
              </Form.Item>

              <Form.Item name="passportIssued" label="Выдан (кем)" required>
                <TextArea placeholder="Введите кем выдан" cols={1} rows={4} />
              </Form.Item>
            </>
          ) : (
            <>
              <div className="contracts-create__individual">Данные компании</div>
              <Form.Item name="nameCompany" label="Компания" required>
                <Input placeholder="Введите название компании" />
              </Form.Item>

              <Form.Item name="urAdressCompany" label="Юр. адрес" required>
                <TextArea placeholder="Введите юридический адрес" cols={1} rows={2} />
              </Form.Item>

              <Form.Item name="mailAddressCompany" label="Почтовый адрес" required>
                <TextArea placeholder="Введите почтовый адрес" cols={1} rows={2} />
              </Form.Item>

              <Form.Item name="nameBank" label="Банк" required>
                <TextArea placeholder="Введите название банка" cols={1} rows={2} />
              </Form.Item>

              <Form.Item name="innCompany" label="ИНН" required>
                <Input placeholder="Введите ИНН компании" type="number" />
              </Form.Item>

              <Form.Item name="kppCompany" label="КПП" required>
                <Input placeholder="Введите КПП паспорта" type="number" />
              </Form.Item>

              <Form.Item name="rsCompany" label="Рассчётный счёт" required>
                <Input placeholder="Введите р/с компании" type="number" />
              </Form.Item>

              <Form.Item name="corCompany" label="Кор/счёт" required>
                <Input placeholder="Введите кор/счёт компании" type="number" />
              </Form.Item>

              <Form.Item name="bicCompany" label="БИК" required>
                <Input placeholder="Введите БИК паспорта" type="number" />
              </Form.Item>
            </>
          )}
        </Form>
      </div>

      <ContractsOne />
    </div>
  );
};

export default ContractsCreate;
