import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Logo } from '../../../../shared/ui/icons/logo';
import { pageFourSlice, reportsDataSlice } from '../../../../store/slice/uiSlice/reports';
import UploadImageInTpl from '../../../UploadImageInTpl';
import { ReportCharPlaylists } from '../../ReportCreate/ReportCharPlaylists';
import { UploadImgWrap } from '../../UploadImgWrap';
import './index.scss';

const ReportPageTwo = () => {
  const allDataReports = useSelector(reportsDataSlice.selectors.reportData);
  const { budgetPlaylist, auditions, conclusions } = allDataReports;

  const [reportTitle, setReportTitle] = useState<string>('Посев по плейлистам Вконтакте');

  const [fileImageBase64, setFileImageBase64] = useState<string>('/image/notImage.jpg');

  return (
    <>
      <div className="a4">
        <img src="/image/line.png" alt="line" className="a4__figure" />
        <div className="header">
          <p className="header__company">SHARIT MEDIA</p>
          <Logo />
        </div>

        <section className="playlist">
          {/* <h2 className="playlist__title title">Посев по плейлистам Вконтакте</h2> */}
          <Input
            className="screenshots__title"
            value={reportTitle}
            onInput={e => setReportTitle(e.currentTarget.value)}
          />

          <div className="screenshots__wrap">
            <UploadImgWrap fileImageName="playlistScreen" file={fileImageBase64} setFile={setFileImageBase64} />
          </div>

          {/* {playlistScreen === '/image/notImage.jpg' || playlistScreen === null ? (
            <div className="public__image">
              <img className="public__notImage" src="/image/notImage.jpg" alt="sowingVk" />
            </div>
          ) : (
            <div className="public__screenshot">
              <img className="public__pic" src={playlistScreen} alt="sowingVk" />
            </div>
          )} */}

          {/* <table className="playlist__table">
            <thead>
              <tr>
                <th>Ссылка на запись</th>
                <th>Дата публикации</th>
                <th>Название сообщества</th>
                <th>Ссылка на сообщество</th>
                <th>Охват записи</th>
                <th>Количество лайков</th>
                <th>Количество репостов</th>
                <th>Скриншот</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="https://vk.com">https://vk.com</a>
                </td>
                <td>yesterday at 2:45 pm</td>
                <td>Не беси</td>
                <td>
                  <a href="https://vk.com/">https://vk.com/</a>
                </td>
                <td>11532</td>
                <td>97</td>
                <td>11</td>
                <td>
                  <a href="https://vk.com">Изображение</a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://vk.com">https://vk.com</a>
                </td>
                <td>yesterday at 2:45 pm</td>
                <td>Не беси</td>
                <td>
                  <a href="https://vk.com/">https://vk.com/</a>
                </td>
                <td>11532</td>
                <td>97</td>
                <td>11</td>
                <td>
                  <a href="https://vk.com">Изображение</a>
                </td>
              </tr>

              <tr>
                <td className="playlist__total">Итого</td>
                <td className="playlist__total"></td>
                <td className="playlist__total"></td>
                <td className="playlist__total"></td>
                <td className="playlist__total">387672</td>
                <td className="playlist__total">5419</td>
                <td className="playlist__total">2469</td>
                <td className="playlist__total"></td>
              </tr>
            </tbody>
          </table> */}

          <div className="playlist__wrap">
            <ReportCharPlaylists />
          </div>
          <div className="sowing__conclusions">
            <div className="sowing__conclusions_info">
              <span>Общие выводы</span>
            </div>
            <TextArea className="sowing__desc" rows={8} cols={12}>{conclusions}</TextArea>
          </div>
        </section>

        <div className="footer-last">
          <img src="/image/logo.JPG" alt="logo" className="footer-last__img" />
        </div>
      </div>
    </>
  );
};

export default ReportPageTwo;
