import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../shared/lib/constants/paths';

export const PrivatePageHOC: FC<{ children: React.ReactElement; roles?: string[] }> = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return <Navigate to={Paths.auth} />;
  }

  return children;
};
