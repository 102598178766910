import { lazy } from 'react';
import { Campaigns } from '../../features/Campaigns';

export const CampaignsPage = () => <Campaigns />;

// export const CampaignsPage = lazy(
//   () =>
//     new Promise(resolve => {
//       // @ts-ignore
//       resolve(import('../../features/Campaigns'));
//     }),
// );
