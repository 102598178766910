import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TColumn, TConstructorReport, TConstructorTemplate, TOptions, TPage, TRow, TSection, TWidget } from '../../../../features/Constructor/Types';

type TInitialValuesReports = {
  id: string;
  type: string;
  order: number;
  template: TConstructorTemplate;
  pages: TPage[];
  sections: TSection[];
  rows: TRow[];
  columns: TColumn[];
  widgets: TWidget[];
};

const initialState: TInitialValuesReports = {
  id: 'report-1',
  type: 'reports',
  order: 1,
  template: {
    id: '',
    name: '',
    type: '',
    description: '',
    options: {}
  },
  pages: [],
  sections: [],
  rows: [],
  columns: [],
  widgets: [],
};

export const reportsSlice = createSlice({
  name: 'constructor/reportsSlice',
  initialState,
  reducers: {
    reports(state, { payload }: PayloadAction<TConstructorReport>) {
      return { ...state, reports: payload };
    },
    reset: () => initialState,
  },
});

const reports = (state: RootState) => state.constructorReport.reports;
const reportsSelector = createSelector(reports, state => state);

export const selector = {
  reportsSelector,
};
export const actions = reportsSlice.actions;
export const reducer = reportsSlice.reducer;
