import { Button, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import {
  TCreateReleaseRequest,
  TGetReleaseResponse,
  useGetAllReleasesQuery,
  useUpdateReleaseMutation,
} from '../../store/query/sharitMediaApiV1/releases';
import { useUpdateOrderStatusMutation } from '../../store/query/sharitMediaApiV1/orders';
import { getErrorData, sortingDate } from '../../shared/helpers';
import { useNotifications } from '../../shared/hooks/use-notifications';
import { saveAs } from 'file-saver';
import './index.scss';
import { Link } from 'react-router-dom';
import { Paths } from '../../shared/lib/types';
import { EStatus, UnloadStatus } from '../../store/slice/uiSlice/steps';

export const Releases = () => {
  const [dataSource, setDataSource] = useState<TGetReleaseResponse[]>([]);

  const { data: dataReleases } = useGetAllReleasesQuery(null);

  const [updateTrigger, result] = useUpdateOrderStatusMutation({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const { isSuccess, isError, error, data, reset } = result;
  useNotifications(isSuccess, isError, 'Статус успешно обновлен', error?.message, reset);

  const [updateUnloadTrigger, resultUnload] = useUpdateReleaseMutation({
    selectFromResult: result => ({ ...result, error: getErrorData(result.error) }),
  });
  const {
    isSuccess: isSuccessUnload,
    isError: isErrorUnload,
    error: errorUnload,
    data: dataUnload,
    reset: resetUnload,
  } = result;
  useNotifications(isSuccessUnload, isErrorUnload, 'Статус успешно обновлен', errorUnload?.message, reset);

  const onChangeStatusPayment = (value: EStatus, order_id: string) => {
    updateTrigger({ order_id, order_status: value });
  };

  const onChangeStatusUnload = (value: UnloadStatus, record: TCreateReleaseRequest) => {
    updateUnloadTrigger({ ...record, release_id: record.release_id, unload_status: value });
  };

  const selectStatus = (status: EStatus, order_id: string) => {
    return (
      <Select
        defaultValue={status}
        style={{ width: 130 }}
        onSelect={value => onChangeStatusPayment(value, order_id)}
        className={status === EStatus.process ? 'select-process' : 'select-success'}
      >
        <Select.Option value={EStatus.process}>Не оплачен</Select.Option>
        <Select.Option value={EStatus.success}>Оплачен</Select.Option>
      </Select>
    );
  };

  const selectUnloadStatus = (status: UnloadStatus, record: TCreateReleaseRequest) => {
    return (
      <Select
        defaultValue={status}
        onSelect={value => onChangeStatusUnload(value, record)}
        className={status === UnloadStatus.noUnloaded ? 'select-process' : 'select-success'}
      >
        <Select.Option value={UnloadStatus.noUnloaded}>Не выгружен</Select.Option>
        <Select.Option value={UnloadStatus.unloaded}>Выгружен</Select.Option>
      </Select>
    );
  };

  const onDownloadExcel = (release_id: string) => {
    const downloadUrl = `${process.env.REACT_APP_BASE_URL_API}/api/downloadByReleaseId/${release_id}`;
    const accessToken = localStorage.getItem('accessToken');

    fetch(downloadUrl, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Download failed');
        }
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'releases.xlsx');
      })
      .catch(error => console.error('Download failed:', error));
  };

  const onDownloadAllReleases = () => {
    const downloadUrl = `${process.env.REACT_APP_BASE_URL_API}/api/downloadAllReleases`;
    const accessToken = localStorage.getItem('accessToken');

    fetch(downloadUrl, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Download failed');
        }
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'releases-all.xlsx');
      })
      .catch(error => console.error('Download failed:', error));
  };

  const onDownloadReleasesByStatus = (unload_status: UnloadStatus) => {
    const downloadUrl = `${process.env.REACT_APP_BASE_URL_API}/api/downloadReleasesByStatus/${unload_status}`;
    const accessToken = localStorage.getItem('accessToken');

    fetch(downloadUrl, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Download failed');
        }
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'releases-by-status.xlsx');
      })
      .catch(error => console.error('Download failed:', error));
  };

  useEffect(() => {
    if (dataReleases) {
      const dataWithKey = dataReleases.map(item => ({ ...item, key: item.release_id }));
      setDataSource(dataWithKey);
      console.log('dataWithKey', dataWithKey);
    }
  }, [dataReleases]);

  const columns = [
    {
      title: 'Релиз ID',
      dataIndex: 'release_id',
      key: 'release_id',
      width: 140,
    },
    {
      title: 'Название',
      dataIndex: 'name_release',
      key: 'name_release',
      render: (_: any, record: TGetReleaseResponse) => {
        return <Link to={`${Paths.releases}/${record?.release_id}`}>{record?.name_release}</Link>;
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_: any, record: TGetReleaseResponse) => {
        return new Date(record?.created_at).toLocaleDateString();
      },
      sorter: (a: TGetReleaseResponse, b: TGetReleaseResponse) =>
        sortingDate(String(new Date(a.created_at).getTime()), String(new Date(b.created_at).getTime())),
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_: any, record: TGetReleaseResponse) => {
        return new Date(record?.updated_at).toLocaleDateString();
      },
    },
    {
      title: 'Артист',
      dataIndex: 'musician',
      key: 'musician',
    },
    {
      title: 'Клиент',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: TGetReleaseResponse) => {
        return record?.user?.name;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_: any, record: TGetReleaseResponse) => {
        return record?.user?.email;
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (_: any, record: TGetReleaseResponse) => {
        if (record?.user?.phone) {
          return record.user.phone;
        } else {
          return 'Не указан';
        }
      },
    },
    {
      title: 'Ссылка на обложку релиза',
      dataIndex: 'label_image',
      key: 'label_image',
    },
    {
      title: 'Ссылка на трек',
      dataIndex: 'audio_link',
      key: 'audio_link',
      width: 300,
      render: (_: any, record: TGetReleaseResponse) => {
        return record?.tracks?.map(track => {
          return (
            <div key={track.track_name}>
              {track.track_name} -
              <a href={track.audio_link} target="_blank" rel="noreferrer">
                {track.audio_link}
              </a>
            </div>
          );
        });
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Заказ ID',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: 'Статус оплаты заказа',
      dataIndex: 'order_status',
      key: 'order_status',
      render: (order_status: EStatus, record: TGetReleaseResponse) => {
        if (order_status === EStatus.process) {
          return <div>{selectStatus(EStatus.process, record.order_id)}</div>;
        } else {
          return <div>{selectStatus(EStatus.success, record.order_id)}</div>;
        }
      },
    },
    // {
    //   title: 'Скачать релиз в .xlsx',
    //   dataIndex: 'excel',
    //   key: 'excel',
    //   render: (order_status: EStatus, record: TGetReleaseResponse) => {
    //     return (
    //       <Button onClick={() => onDownloadExcel(record?.release_id)} type="primary">
    //         Скачать
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Статус выгрузки релиза',
      dataIndex: 'unload_status',
      key: 'unload_status',
      render: (unload_status: UnloadStatus, record: TGetReleaseResponse) => {
        if (unload_status === UnloadStatus.noUnloaded) {
          return <div>{selectUnloadStatus(UnloadStatus.noUnloaded, record)}</div>;
        } else {
          return <div>{selectUnloadStatus(UnloadStatus.unloaded, record)}</div>;
        }
      },
    },
  ];

  return (
    <div>
      {/* <div className="btn-download-group">
        <Button onClick={() => onDownloadAllReleases()} type="primary">
          Скачать все релизы в .xlsx
        </Button>

        <Button onClick={() => onDownloadReleasesByStatus(UnloadStatus.noUnloaded)} type="primary">
          Скачать невыгруженные релизы в .xlsx
        </Button>

        <Button onClick={() => onDownloadReleasesByStatus(UnloadStatus.unloaded)} type="primary">
          Скачать выгруженные релизы в .xlsx
        </Button>
      </div> */}

      {dataSource && <Table columns={columns} dataSource={dataSource} bordered scroll={{ x: 'max-content' }} />}
    </div>
  );
};
