import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TProfileInfo } from '../../shared/lib/types';
import { MyInfo } from '../../store/slice';
import { uiDrawerSlice } from '../../store/slice/uiSlice';
import { UserProfile } from '../../widgets/UserProfile';
import './index.scss';

const initialsFio = (info: TProfileInfo) => {
  const name = info.name.slice(0, 1);
  const surName = info.surname.slice(0, 1);

  return `${name}.${surName}`;
};

export const UserHeader = () => {
  const dispatch = useDispatch();

  // const {visibility} = useSelector(uiDrawerSlice.selectors.userDrawerSelector);
  const info = useSelector(MyInfo.selectors.getMyInfo);

  const onDrawerShow = () => {
    dispatch(uiDrawerSlice.actions.visibility(true));
  };

  return (
    <div className="user-header" onClick={onDrawerShow}>
      <div className="user-header__avatar">{initialsFio(info)}</div>
      <div className="user-header__info">
        {info.name} {info.surname}
      </div>

      <UserProfile />
    </div>
  );
};
