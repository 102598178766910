import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { TColumn, TOptions } from '../../../../features/Constructor/Types';

type TInitialValuesColumns = {
  id: string;
  idRow: string;
  order: number;
  type: string;
  options: TOptions;
}[];

const initialState: TInitialValuesColumns = [{
  id: '1',
  idRow: 'p-1',
  order: 1,
  type: 'columns',
  options: {
    displayFlex: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    width: 100,
    height: 160,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
}];

export const columnsSlice = createSlice({
  name: 'constructor/columnsSlice',
  initialState,
  reducers: {
    columns(state, { payload }: PayloadAction<TColumn[]>) {
      return { ...state, columns: payload };
    },
    reset: () => initialState,
  },
});

const columns = (state: RootState) => state.constructorReport.columns;
const columnsSelector = createSelector(columns, state => state);

export const selectors = {
  columnsSelector,
};
export const actions = columnsSlice.actions;
export const reducer = columnsSlice.reducer;
