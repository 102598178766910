import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';

type ModalTemplatesView = {
  view: boolean;
}

const initialState: ModalTemplatesView = {
  view: false
}

export const modalTemplatesSlice = createSlice({
  name: 'ui/modalTemplates',
  initialState,
  reducers: {
    view(state, { payload }: PayloadAction<boolean>) {
      return { ...state, view: payload };
    },
    reset: () => initialState,
  },
});

const modalTemplates = (state: RootState) => state.ui.modalTemplates;
const modalTemplatesSelector = createSelector(modalTemplates, state => state);

export const selectors = {
  modalTemplatesSelector,
};
export const actions = modalTemplatesSlice.actions;
export const reducer = modalTemplatesSlice.reducer;
