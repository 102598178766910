import { AuthPage } from '../../../../pages';
import './index.scss';
import { ConfigProvider } from 'antd';

const AuthLayout = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'rgba(238, 146, 110, 1)',
          fontFamily: 'Montserrat, sans-serif',
          // colorBgLayout: '#141414',
          borderRadiusLG: 4,
          borderRadiusOuter: 4,
          borderRadiusSM: 4,
          borderRadiusXS: 4,
          colorBorderBg: '#141414',
          colorTextBase: '#fff',
          colorFillContent: '#141414',
          // colorBgContainer: '#141414',
          colorText: '#fff',
          colorBgBase: '#141414',
          colorTextPlaceholder: '#fff',
          controlItemBgActive: 'rgba(238, 146, 110, 1)',
          // controlItemBgActiveDisabled: 'green',
          controlOutline: 'transparent',
        },
      }}
    >
      <div className="auth__container">
        <div className="wrap">
          <AuthPage />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default AuthLayout;
