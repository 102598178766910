import { sharitMediaApiV1 } from '..';

type TGetAccountsRequest = {
  account_id: string;
  access_token?: string | undefined;
};

type TGetAccountsResponse = {
  access_role: string;
  account_id: number;
  account_name: string;
  account_status: number;
  account_type: string;
  ad_network_allowed_potentially: boolean;
  can_view_budget: boolean;
};

export const accountsApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<TGetAccountsResponse[], TGetAccountsRequest>({
      query: params => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/ads/getAccounts`, method: 'GET', params }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAccountsQuery, useLazyGetAccountsQuery } = accountsApi;
