import { InputNumber } from 'antd';
import { useSelector } from 'react-redux';
import { currentElementSlice } from '../../../../store/slice/constructorSlice';
import { changeObjectProperties } from '../../../../shared/utils/changeObjectProperties';
import { TConstructorReport } from '../../Types';
// import { ValueType } from 'rc-input-number/lib/utils/MiniDecimal';

export const PanelWidth = ({ report }: { report: TConstructorReport }) => {
  const currentElement = useSelector(currentElementSlice.selectors.currentElementSelector);

  return (
    <div className="sectionPanel-margins">
      <div className="panel-margins">
        Width
        <InputNumber
          className="panel-input"
          onChange={(e: number | null) => changeObjectProperties(e, 'width', '%', currentElement.id, report)}
        />
        %
      </div>
    </div>
  );
};
