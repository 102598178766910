import { sharitMediaApiV1 } from '..';

export type TUser = {
  id: number;
  name: string;
  surname: string;
  middlename: string;
  email: string;
  activated: boolean;
  linkVk: string | null;
  password?: string;
  role_id: number;
  avatar?: string;
};

export type TUserUpdate = {
  id: number;
  name: string;
  surname: string;
  middlename: string;
  email: string;
  password?: string;
  avatar?: string;
};

export const usersApi = sharitMediaApiV1.injectEndpoints({
  endpoints: build => ({
    users: build.query<TUser[], null>({
      query: () => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/users`, method: 'GET' }),
    }),
    updateUser: build.mutation<TUser, TUserUpdate>({
      query: ({id, ...body}) => ({ url: `${process.env.REACT_APP_BASE_URL_API}/api/user/${id}`, method: 'PUT', body }),
      invalidatesTags: ['myInfo'],
    }),
  }),
  overrideExisting: false,
});

export const { useUsersQuery, useLazyUsersQuery, useUpdateUserMutation } = usersApi;
