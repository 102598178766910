import { Table } from 'antd';
import { useState } from 'react';
import './index.scss';

export const ReportsAll = () => {
  const [dataSource, setDataSource] = useState<[]>([]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 140,
    },
    {
      title: 'Артист',
      dataIndex: 'musician',
      key: 'musician',
    },
    {
      title: 'Название отчёта',
      dataIndex: 'nameAds',
      key: 'nameAds',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <div className="reports-all">
        <Table columns={columns} dataSource={dataSource} bordered />
      </div>
    </>
  );
};
