import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { accountsApi } from '../../query/sharitMediaApiV1/accounts';

type TAccount = {
  access_role: string;
  account_id: number;
  account_status: number;
  account_type: string;
  account_name: string;
  can_view_budget: boolean;
  ad_network_allowed_potentially: boolean;
};

const initialState: TAccount[] = [];

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        accountsApi.endpoints.getAccounts.matchFulfilled,
        (state, { payload }: PayloadAction<TAccount[]>) => payload,
      )
      .addMatcher(accountsApi.endpoints.getAccounts.matchRejected, () => initialState);
  },
});

const accounts = (state: RootState) => state.accounts;
const getAccounts = createSelector(accounts, state => state);

export const selectors = { getAccounts };
export const actions = accountsSlice.actions;
export const accountsReducer = accountsSlice.reducer;
