import { Tabs } from 'antd';
import ReportFrame from '../ReportFrame';
import RepostsSettings from './ReportsSettings';
import ReportsStatisticsDate from './ReportsStatisticsDate';
import './index.scss';

const tabsItems = [
  { label: 'Выбор дат', key: 'statisticsDate', children: <ReportsStatisticsDate /> },
  // { label: 'Настройка шаблона', key: 'settingsTpl', children: <RepostsSettings /> },
];

export const ReportCreate = () => {
  return (
    <>
      <div className="reports-content">
        {/* <div className="reports-content__sider">
          <Tabs items={tabsItems} />
        </div> */}

        <ReportFrame />
      </div>
    </>
  );
};
