import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configureStore';

export type TInitialValuesReports = {
  title: string;
  labelImage: string;
  budget: number;
  ctr: number;
  views: number;
  clicks: number;
  likes: number;
  comments: number;
  forwards: number;
  viewsTarget: number;
  screenshotLabel: string;
};

const initialState: TInitialValuesReports = {
  title: 'Название отчёта',
  budget: 0,
  ctr: 0,
  views: 0,
  clicks: 0,
  likes: 0,
  comments: 0,
  forwards: 0,
  viewsTarget: 0,
  labelImage: '',
  screenshotLabel: '',
};

export const pageOnSlice = createSlice({
  name: 'ui/reports/pageOne',
  initialState,
  reducers: {
    title(state, { payload }: PayloadAction<string>) {
      return { ...state, title: payload };
    },
    labelImage(state, { payload }: PayloadAction<string>) {
      return { ...state, labelImage: payload };
    },
    budget(state, { payload }: PayloadAction<number>) {
      return { ...state, budget: payload };
    },
    ctr(state, { payload }: PayloadAction<number>) {
      return { ...state, ctr: payload };
    },
    views(state, { payload }: PayloadAction<number>) {
      return { ...state, views: payload };
    },
    clicks(state, { payload }: PayloadAction<number>) {
      return { ...state, clicks: payload };
    },
    likes(state, { payload }: PayloadAction<number>) {
      return { ...state, likes: payload };
    },
    forwards(state, { payload }: PayloadAction<number>) {
      return { ...state, forwards: payload };
    },
    viewsTarget(state, { payload }: PayloadAction<number>) {
      return { ...state, viewsTarget: payload };
    },
    comments(state, { payload }: PayloadAction<number>) {
      return { ...state, comments: payload };
    },
    screenshotLabel(state, { payload }: PayloadAction<string>) {
      return { ...state, screenshotLabel: payload };
    },
    pageOne(state, { payload }: PayloadAction<TInitialValuesReports>) {
      return {
        ...state,
        title: payload.title,
        labelImage: payload.labelImage,
        budget: payload.budget,
        ctr: payload.ctr,
        views: payload.views,
        clicks: payload.clicks,
        screenshotLabel: payload.screenshotLabel,
      };
    },
    reset: () => initialState,
  },
  // extraReducers: builder => {
  //   builder.addMatcher((actions), (state, { payload }) => {
  //     state.title = payload.title;
  //   });
  // },
});

const pageOneData = (state: RootState) => state.ui.reports.pageOne;
const pageOneSelector = createSelector(pageOneData, state => state);

export const selectors = {
  pageOneSelector,
};
export const actions = pageOnSlice.actions;
export const reducer = pageOnSlice.reducer;
